'use client';
import React, { createContext, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

const ContainerContext = createContext(null);

export const useContainer = () => useContext(ContainerContext);

export const ContainerProvider = ({ children }) => {
  const container = useRef(new Map()).current;

  return (
    <ContainerContext.Provider value={container}>
      {children}
    </ContainerContext.Provider>
  );
};

ContainerProvider.propTypes = {
  children: PropTypes.node,
};

export const useSingleton = (key, builder) => {
  const container = useContainer();

  if (!container.has(key)) {
    container.set(key, builder());
  }

  return container.get(key);
};
