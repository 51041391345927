import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '../ResponsiveImage';
import logo from './logo.svg';

export const ApplePayLogo = ({ size }) => {
  const width = size === 'small' ? 46 : 63;
  const height = size === 'small' ? 32 : 44;

  return (
    <ResponsiveImage
      size={size}
      src={logo}
      alt="Apple Pay"
      width={width}
      height={height}
    />
  );
};

ApplePayLogo.displayName = 'ApplePayLogo';
ApplePayLogo.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
};
