'use client';
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: ${props => props.theme.spacing(4)};
  padding: ${props => props.theme.spacing(2)};

  position: fixed;
  bottom: ${props => (props.isOverlaping ? props.theme.spacing(22) : 0)};
  @media ${props => props.theme.tablet} {
    bottom: 0;
  }
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: translateY(
    ${props => (props.shown ? `0px` : `${props.height + 10}px`)}
  );
  background: transparent;
  z-index: 100;
`;

export const Button = styled.button`
  text-align: center;
  border-radius: 50%;
  padding: 0;
  width: 64px;
  height: 64px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  background-color: ${props =>
    props.showUnreadMessageDot
      ? props.theme.colors.hero.blueberry
      : props.theme.colors.secondary.chia};
  border: 1px solid
    ${props =>
      props.showUnreadMessageDot
        ? props.theme.colors.hero.blueberry
        : props.theme.colors.secondary.chia};

  &:focus,
  &:hover {
    border-color: ${props => props.theme.colors.border.dark};
    outline: none;
  }

  &:active {
    border-color: ${props => props.theme.colors.text.primary};
  }

  &:disabled {
    border: none;
    background-color: ${props => props.theme.colors.background.disabled};
  }
`;

export const UnreadMessageDot = styled.div`
  position: fixed;
  z-index: 101;
  background: ${props => props.theme.colors.hero.cranberry};
  border: 1px solid ${props => props.theme.colors.text.lightest};
  border-radius: 50%;
  width: ${props => props.theme.spacing(5)};
  height: ${props => props.theme.spacing(5)};
  right: ${props => props.theme.spacing(2)};
  bottom: ${props => props.theme.spacing(13)};
`;

export const ProactiveMessage = styled.div`
  color: #fff;
  background-color: ${props => props.theme.colors.hero.blueberry};
  border-radius: ${props => props.theme.spacing(6)};
  padding: ${props => props.theme.spacing(4)};
  position: absolute;
  margin: auto;
  bottom: 90px;
  right: 10px;
  height: fit-content;
  width: 290px;
  box-shadow: 0 0 5px #333;
  text-align: left;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    bottom: -6px;
    right: 20px;
    width: ${props => props.theme.spacing(6)};
    height: ${props => props.theme.spacing(6)};
    transform: rotate(45deg);
    background-color: inherit;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -15px;
  right: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
`;
