'use client';
/* newlines-between doesn't seem to like the inline requires */
/* related bug: https://github.com/benmosher/eslint-plugin-import/issues/1936 */
/* eslint import/order: ['error', { groups: ['builtin', 'external', 'parent', 'sibling', 'index'], 'newlines-between': 'ignore'}] */
import React from 'react';
import PropTypes from 'prop-types';
import {
  createGlobalStyle,
  ThemeProvider,
  css,
  StyleSheetManager,
} from 'styled-components';
import { get } from 'lodash';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-display: swap;
    font-family: 'Founders Grotesk';
    font-weight: normal;
    font-style: normal;
    src: url(${require('../public/fonts/founders-grotesk-web-regular.woff2')}) format('woff2');
  }

  @font-face {
    font-display: swap;
    font-family: 'Founders Grotesk';
    font-weight: bold;
    font-style: normal;
    src: url(${require('../public/fonts/founders-grotesk-web-medium.woff2')}) format('woff2');
  }

  @font-face {
    font-display: swap;
    font-family: 'Founders Grotesk Condensed';
    font-weight: normal;
    font-style: normal;
    src: url(${require('../public/fonts/founders-grotesk-condensed-web-medium.woff2')}) format('woff2');
  }

  @font-face {
    font-display: swap;
    font-family: 'Founders Grotesk X-Condensed';
    font-weight: normal;
    font-style: normal;
    src: url(${require('../public/fonts/founders-grotesk-x-condensed-web-medium.woff2')}) format('woff2');
  }

  @font-face {
    font-family: 'Adjusted Arial Fallback';
    src: local(Arial);
    /* stylelint-disable-next-line */
    size-adjust: 88%;
    ascent-override: 109%;
    descent-override: 21%;
    line-gap-override: normal;
  }

  @font-face {
    font-family: 'Adjusted Helvetica';
    src: local(Helvetica);
    /* stylelint-disable-next-line */
    size-adjust: 88%;
    ascent-override: 109%;
    descent-override: 21%;
    line-gap-override: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Adjusted Condensed Arial Fallback';
    src: local(Arial);
    /* stylelint-disable-next-line */
    size-adjust: 88%;
    ascent-override: 109%;
    descent-override: 21%;
    line-gap-override: normal;
  }

  @font-face {
    font-family: 'Adjusted X-Condensed Arial Fallback';
    src: local(Arial);
    /* stylelint-disable-next-line */
    size-adjust: 88%;
    ascent-override: 109%;
    descent-override: 21%;
    line-gap-override: normal;
  }

  * > * {
    box-sizing: border-box;
  }

  body {
    ${props => props.theme.fontFamily.base}
    color: ${props => props.theme.colors.text.primary};
    background-color: ${props => props.theme.colors.background.base};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    * {
      margin: 0;
    }
    img {
      max-width: 100%;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
  }

  a {
    text-decoration: none;
  }

  input, select, button {
    ${props => props.theme.fontFamily.base}
  }

  ::-moz-focus-inner {
    border: 0;
  }

  :focus {
    outline: none;
  }

  button {
    cursor: pointer;
    &:disabled {
      cursor: auto;
    }
  }

  #gladlyChat_container .minimizedChatButton.minimizedChatButton,
  #gladlyChat_container .minimizedChatButtonSelector.minimizedChatButtonSelector {
    display: none !important;
  }
`;

// Screen sizes
export const size = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tabletS: '600px',
  tablet: '768px',
  tabletL: '960px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

/**
 * @type {import('styled-components').DefaultTheme}
 */
export const theme = {
  colors: {
    text: {
      primary: '#403C36',
      secondary: '#5E584E',
      tertiary: '#82746C',
      lighter: '#F4F4EC',
      lightest: '#FFFFFF',
    },
    background: {
      base: '#F4F4EC',
      lightest: '#FFFFFF',
      dark: '#5E584e',
      disabled: '#E6E6E6',
      bone: '#EEEADF',
    },
    border: {
      fur: '#A69991',
      dark: '#5E584E',
    },
    neutral: {
      darkestFur: '#403C36',
      darkerFur: '#5E584E',
      bone: '#E8E4D7',
      lightBone: '#F4F4EC',
      white: '#FFFFFF',
    },
    hero: {
      sweetPotato: '#E0A292',
      spinach: '#97AC97',
      cranberry: '#E0676C',
      blueberry: '#728DB5',
      huckleberry: '#4170B6',
      watermelon: '#FFB696',
    },
    secondary: {
      kale: '#4C5D4C',
      egg: '#EFBE7D',
      carrot: '#E88B4E',
      chia: '#3A546F',
      mixedBerry: '#C392AA',
      yam: '#A15576',
      black: '#000',
      sea: '#70B0C7',
    },
    status: {
      errorPrimary: '#BF4249',
      errorLight: '#FBF1F2',
      successPrimary: '#108249',
      successLight: '#F1F7F4',
      warningPrimary: '#C48321',
      warningLight: '#FEF9F2',
      warningDark: '#A5670A',
    },
    withAlpha(path, alpha, defaultColor) {
      const hexColor = this.get(path, defaultColor);
      let r = 0,
        g = 0,
        b = 0;

      // 3 digits, #ABC
      if (hexColor.length === 4) {
        r = '0x' + hexColor[1] + hexColor[1];
        g = '0x' + hexColor[2] + hexColor[2];
        b = '0x' + hexColor[3] + hexColor[3];

        // 6 digits, #AABBCC
      } else if (hexColor.length === 7) {
        r = '0x' + hexColor[1] + hexColor[2];
        g = '0x' + hexColor[3] + hexColor[4];
        b = '0x' + hexColor[5] + hexColor[6];
      }

      return `rgb(${+r}, ${+g}, ${+b}, ${alpha})`;
    },
    get(path, defaultColor) {
      const color = get(theme.colors, path, defaultColor);

      if (!color) {
        throw new TypeError(`${path} does not exist on theme.colors.`);
      }

      return color;
    },
  },

  transparent: 'transparent',
  shadowGrey: 'rgba(0, 0, 0, 0.16)',
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tabletOnly: `(min-width: ${size.tabletS}) and (max-width: ${size.laptop})`,
  tabletS: `(min-width: ${size.tabletS})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletL: `(min-width: ${size.tabletL})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,

  scrollBar: css`
    overflow-y: auto;
    scroll-margin-right: ${props => props.theme.spacing(-2)};

    /* This is disabled due to a problem with a :focus elsewhere */
    /* stylelint-disable  no-descending-specificity */
    & ::-webkit-scrollbar-track {
      border-radius: ${props => props.theme.spacing(10)};
      background-color: ${props => props.theme.colors.text.secondary};
    }

    & ::-webkit-scrollbar {
      width: ${props => props.theme.spacing(2)};
    }

    & ::-webkit-scrollbar-thumb {
      border-radius: ${props => props.theme.spacing(10)};
      background-color: ${props => props.theme.colors.secondary.egg};
    }

    scrollbar-track-color: ${props => props.theme.colors.text.secondary};
    scrollbar-base-color: ${props => props.theme.colors.secondary.egg};
    /* stylelint-enable */
  `,

  spacing: (...args) => {
    let spacingString = args.map(spacingValue => {
      if (typeof spacingValue === 'number') {
        return `${4 * spacingValue}px`;
      }
      return spacingValue;
    });
    return spacingString.join(' ');
  }, //end of spacing string

  calcGridColumn: value => {
    const columValue = value ? `span ${value}` : 'unset';
    return `grid-column: ${columValue};`;
  },

  getContrastColors: value => {
    switch (value) {
      case 'background.dark':
        return css`
          background-color: ${({ theme }) => theme.colors.background.dark};
          color: ${({ theme }) => theme.colors.text.lightest};
        `;
      case 'background.base':
        return css`
          background-color: ${({ theme }) => theme.colors.background.base};
        `;
      case 'background.lightest':
        return css`
          background-color: ${({ theme }) => theme.colors.background.lightest};
          color: ${({ theme }) => theme.colors.text.secondary};
        `;

      case 'light':
        return css`
          background-color: ${({ theme }) => theme.colors.neutral.bone};
          color: ${({ theme }) => theme.colors.text.secondary};
        `;
      case 'blueberry':
        return css`
          background-color: ${({ theme }) => theme.colors.hero.blueberry};
          color: ${({ theme }) => theme.colors.text.lightest};
        `;
      case 'kale':
        return css`
          background-color: ${({ theme }) => theme.colors.secondary.kale};
          color: ${({ theme }) => theme.colors.text.lightest};
        `;
      case 'egg':
        return css`
          background-color: ${({ theme }) => theme.colors.secondary.egg};
          color: ${({ theme }) => theme.colors.text.secondary};
        `;
      case 'chia':
        return css`
          background-color: ${({ theme }) => theme.colors.secondary.chia};
          color: ${({ theme }) => theme.colors.text.lightest};
        `;
      case 'spinach':
        return css`
          background-color: ${({ theme }) => theme.colors.hero.spinach};
          color: ${({ theme }) => theme.colors.text.lightest};
        `;
      case 'cranberry':
        return css`
          background-color: ${({ theme }) => theme.colors.hero.cranberry};
          color: ${({ theme }) => theme.colors.text.lightest};
        `;
      case 'warning':
        return css`
          background-color: ${({ theme }) =>
            theme.colors.status.warningPrimary};
          color: ${({ theme }) => theme.colors.text.lightest};
        `;
      case 'mixedBerry':
        return css`
          background-color: ${({ theme }) => theme.colors.secondary.mixedBerry};
          color: ${({ theme }) => theme.colors.text.lightest};
        `;
      case 'yam':
        return css`
          background-color: ${({ theme }) => theme.colors.secondary.yam};
          color: ${({ theme }) => theme.colors.text.lightest};
        `;
      default:
        return css`
          background-color: ${({ theme }) => theme.colors.background.dark};
          color: ${({ theme }) => theme.colors.text.lightest};
        `;
    }
  },

  getFocusOutline: (parentColor = 'neutral.bone') => {
    const getOutlineColor = parentColor => {
      switch (parentColor) {
        case 'neutral.darkerFur':
        case 'secondary.kale':
        case 'secondary.chia':
        case 'secondary.yam':
          return theme.colors.neutral.lightBone;
        default:
          return theme.colors.neutral.darkestFur;
      }
    };

    return css`
      &:focus,
      &:active,
      &:focus-within {
        border-radius: ${({ theme }) => theme.spacing(2)};
        outline: 2px solid ${() => getOutlineColor(parentColor)};
        outline-offset: ${({ theme }) => theme.spacing(0.5)};
      }
    `;
  },

  fontFamily: {
    base: css`
      font-family: 'Founders Grotesk', 'Adjusted Arial Fallback',
        'Adjusted Helvetica', sans-serif;
    `,
    condensed: css`
      font-family: 'Founders Grotesk Condensed',
        'Adjusted Condensed Arial Fallback', 'Adjusted Helvetica', sans-serif;
    `,
    xcondensed: css`
      font-family: 'Founders Grotesk X-Condensed',
        'Adjusted X-Condensed Arial Fallback', 'Adjusted Helvetica', sans-serif;
    `,
  },

  typography: {
    headingMastiff: css`
      font-size: 3rem;
      line-height: 1.16666667;
      font-weight: normal;
      letter-spacing: 0.01em;

      @media ${props => props.theme.tablet} {
        font-size: 3.5rem;
        line-height: 1.14285714;
      }
    `,
    heading1: css`
      font-size: 2.5rem;
      line-height: 1.2;
      font-weight: normal;
      letter-spacing: 0.01em;

      @media ${props => props.theme.tablet} {
        font-size: 3rem;
        line-height: 1.16666667;
      }
    `,
    heading2: css`
      font-size: 2rem;
      line-height: 1.1875;
      font-weight: normal;
      letter-spacing: 0.01em;

      @media ${props => props.theme.tablet} {
        font-size: 2.5rem;
        line-height: 1.2;
      }
    `,
    heading3: css`
      font-size: 1.75rem;
      line-height: 1.21428571;
      font-weight: normal;
      letter-spacing: 0.01em;

      @media ${props => props.theme.tablet} {
        font-size: 2rem;
        line-height: 1.1875;
      }
    `,
    heading4: css`
      font-size: 1.5rem;
      line-height: 1.25;
      font-weight: normal;
      letter-spacing: 0.01em;
    `,
    heading5: css`
      font-size: 1.25rem;
      line-height: 1.4;
      font-weight: normal;
      letter-spacing: 0.01em;
    `,
    heading6: css`
      font-size: 1.125rem;
      line-height: 1.33333333;
      font-weight: bold;
      letter-spacing: 0.01em;
    `,
    subheading1: css`
      ${props => props.theme.fontFamily.condensed}
      font-size: 1.25rem;
      line-height: 1.4;
      font-weight: normal;
      letter-spacing: 0.01em;
    `,
    subheading2: css`
      ${props => props.theme.fontFamily.condensed}
      font-size: 1rem;
      line-height: 1.25;
      font-weight: normal;
      letter-spacing: 0.01em;
    `,
    button: css`
      font-size: 1.125rem;
      line-height: 1.33333333;
      font-weight: normal;
      letter-spacing: 0.01em;
    `,
    body: css`
      font-size: 1rem;
      line-height: 1.625;
      font-weight: normal;
      letter-spacing: 0.01em;
    `,
    bodyBold: css`
      font-size: 1rem;
      line-height: 1.625;
      font-weight: bold;
      letter-spacing: 0.01em;
    `,
    label: css`
      font-size: 0.875rem;
      line-height: 1.57;
      font-weight: normal;
      letter-spacing: 0.01em;
    `,
    label2: css`
      font-size: 0.875rem;
      line-height: 1.33333333;
      font-weight: normal;
      letter-spacing: 0.01em;
    `,
  },

  visuallyHidden: css`
    position: fixed;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  `,
};

// Use this wrapped theme component instead of importing ThemeProvider and theme
export const OllieTheme = ({ children }) => (
  <StyleSheetManager enableVendorPrefixes>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </StyleSheetManager>
);

OllieTheme.propTypes = {
  children: PropTypes.node.isRequired,
};
