import React from 'react';
import PropTypes from 'prop-types';
import { NavigationBar } from './NavigationBar';
import { Header } from './styles';

export const BaseHeader = ({
  topSegment,
  leftSegment,
  centerSegment,
  rightSegment,
  bottomSegment,
  logoUrl,
  logoSrText,
  sticky = false,
  stickyBanner = false,
  variant,
}) => {
  return (
    <Header
      role="banner"
      $hasBanner={!!topSegment}
      $sticky={sticky}
      $stickyBanner={stickyBanner}
    >
      {topSegment}
      <NavigationBar
        logoUrl={logoUrl}
        logoSrText={logoSrText}
        leftSegment={leftSegment}
        centerSegment={centerSegment}
        rightSegment={rightSegment}
        variant={variant}
      />
      {bottomSegment}
    </Header>
  );
};

BaseHeader.displayName = 'BaseHeader';

BaseHeader.propTypes = {
  variant: PropTypes.string,
  logoUrl: PropTypes.string,
  logoSrText: PropTypes.string,
  topSegment: PropTypes.node,
  leftSegment: PropTypes.node,
  centerSegment: PropTypes.node,
  rightSegment: PropTypes.node,
  bottomSegment: PropTypes.node,
  sticky: PropTypes.bool,
  stickyBanner: PropTypes.bool,
};
