'use client';
import Link from 'next/link';
import styled, { css } from 'styled-components';

export const StyledDiscountBanner = styled.div`
  background-color: ${props => props.theme.colors.background.dark};
  padding: 9.5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* This is needed to make sure it doesn't get */
  /* bigger when the text enters. */
  height: 40px;
  ${props =>
    props.$sticky &&
    css`
      position: fixed;
      top: 0;
      z-index: 100;
    `};
`;

export const OfferBannerText = styled.p`
  color: ${props => props.theme.colors.text.lightest};
  ${props => props.theme.typography.body};
  font-weight: inherit;
`;

export const OfferLink = styled(Link)`
  display: inline-flex;
  font-weight: bold;
  margin-left: 8px;
  border-bottom: 1px solid ${props => props.theme.colors.background.lightest};
`;
