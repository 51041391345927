'use client';
import PropTypes from 'prop-types';
import axios from 'axios';
import useSWR, { unstable_serialize, useSWRConfig } from 'swr';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { useEnvVars } from '../../app/contexts/EnvContext';
import { useLocalStorage } from '../../hooks';
import { SessionContext } from './session';
import {
  SESSION_KEY,
  LOADING_STATUS,
  AUTHENTICATED_STATUS,
  UNAUTHENTICATED_STATUS,
} from './constants';
import { useSingleton } from './container';

const getCsrfToken = async () => {
  const response = await axios.get(`/api/auth/csrf/`);
  return response.data.csrfToken;
};

const EMPTY_SESSION = {
  data: undefined,
  error: undefined,
  isValidating: false,
  isLoading: false,
};

export class Session {
  constructor(cache, mutate) {
    this.cache = cache;
    this.mutate = mutate;
  }

  get _cache() {
    return this.cache.get(unstable_serialize(SESSION_KEY)) ?? EMPTY_SESSION;
  }

  get data() {
    return this._cache.data;
  }

  get user() {
    return this.data?.user;
  }

  get status() {
    const { data, error, isValidating } = this._cache;
    return !data && !error && isValidating
      ? LOADING_STATUS
      : data?.user
      ? AUTHENTICATED_STATUS
      : UNAUTHENTICATED_STATUS;
  }

  register = async (firstName, email, customerGroupId) => {
    const response = await axios.post(
      `/api/auth/callback/register/`,
      new URLSearchParams({
        first_name: firstName,
        email,
        customer_group_id: customerGroupId,
        csrfToken: await getCsrfToken(),
        callbackUrl: String(window.location),
        json: 'true',
      }),
    );
    await this.mutate();

    return response;
  };

  login = async (email, password) => {
    const response = await axios.post(
      `/api/auth/callback/login/`,
      new URLSearchParams({
        email,
        password,
        csrfToken: await getCsrfToken(),
        callbackUrl: String(window.location),
        json: 'true',
      }),
    );
    await this.mutate();

    return response;
  };

  logout = async () => {
    const response = await axios.post(
      `/api/auth/signout/`,
      new URLSearchParams({
        csrfToken: await getCsrfToken(),
        callbackUrl: String(window.location),
        json: 'true',
      }),
    );
    await this.mutate();

    return response;
  };
}

const screeningFlows = ['weight_web_acquisition'];

export const AppSessionProvider = ({ children }) => {
  const searchParams = useSearchParams();
  const appEnvVars = useEnvVars();
  const [isAppSession, setIsAppSession] = useLocalStorage('isAppSession');
  const [screeningSession, setScreeningSession] =
    useLocalStorage('screeningSession');

  const flow = searchParams?.get('flow');
  const isAppSessionQuery = searchParams?.get('isAppSession');
  const firstName = searchParams?.get('firstName');
  const email = searchParams?.get('email');
  const result = searchParams?.get('result');

  const isScreeningSession =
    screeningFlows.includes(flow) ||
    (Date.now() < screeningSession?.expire &&
      screeningFlows.includes(screeningSession?.flow));

  const [isRegistering, setIsRegistering] = useState(true);
  const { cache } = useSWRConfig();

  const { mutate, data, error, isValidating } = useSWR(SESSION_KEY, {
    ...(appEnvVars.OLLIE_ENV !== 'storybook' && {
      fetcher: url => axios.get(url).then(resp => resp.data),
    }),
  });

  const service = useSingleton(Session, () => new Session(cache, mutate));

  useEffect(() => {
    if (isAppSessionQuery) setIsAppSession(true);
    if (screeningFlows.includes(flow)) {
      const date = new Date();
      const expire = date.setDate(date.getDate() + 1);
      setScreeningSession({
        flow,
        result,
        expire,
      });
    }
    if (firstName && email && !data) {
      setIsRegistering(true);
      service.register(firstName, email).then(() => setIsRegistering(false));
    } else setIsRegistering(false);
  }, [
    data,
    email,
    firstName,
    flow,
    isAppSessionQuery,
    result,
    service,
    setIsAppSession,
    setScreeningSession,
  ]);

  const session = useMemo(
    () => ({
      data,
      error,
      isValidating,
      service,
      status: service.status,
      user: service.user,
      register: service.register,
      login: service.login,
      logout: service.logout,
      isAppSession: Boolean(isAppSession || isAppSessionQuery),
      isScreeningSession,
      isRegistering,
    }),
    [
      data,
      error,
      isValidating,
      service,
      isAppSession,
      isAppSessionQuery,
      isScreeningSession,
      isRegistering,
    ],
  );
  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
};

AppSessionProvider.propTypes = {
  children: PropTypes.node,
};
