'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { useReporter } from '../../../reporter';
import { useOffer } from '../../../offer';
import { headerBannerClicked } from './events';
import { StyledDiscountBanner, OfferBannerText, OfferLink } from './styles';

export const DiscountBanner = ({ sticky = false }) => {
  const { message } = useOffer();
  const link = message
    ? {
        text: 'Try now!',
        target: '/onboarding/',
      }
    : null;
  const reporter = useReporter();
  const trackEvent = headerBannerClicked(message, link);
  return (
    <StyledDiscountBanner $sticky={sticky}>
      {message && <OfferBannerText>{message}</OfferBannerText>}
      {link && (
        <OfferLink href={link.target} onClick={() => reporter.tag(trackEvent)}>
          <OfferBannerText>{link.text}</OfferBannerText>
        </OfferLink>
      )}
    </StyledDiscountBanner>
  );
};

DiscountBanner.displayName = 'DiscountBanner';

DiscountBanner.propTypes = {
  sticky: PropTypes.bool,
};
