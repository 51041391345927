import React from 'react';
import PropTypes from 'prop-types';
import { StyledLink, RichLink } from './styles.js';

export const SmolLink = ({
  href,
  target,
  bold,
  children,
  onClick,
  className,
  variant = 'hero.blueberry',
  underline = false,
}) => {
  return (
    <StyledLink
      href={href}
      className={className}
      target={target}
      onClick={onClick}
      bold={bold}
      variant={variant}
      underline={underline}
    >
      {children}
    </StyledLink>
  );
};

// It's a link. It's a button. It's a link as a button
export const ButtonLink = ({
  onClick,
  bold,
  children,
  variant = 'hero.huckleberry',
  underline = false,
  disabled = false,
}) => (
  <StyledLink
    as="button"
    onClick={onClick}
    bold={bold}
    type="button"
    variant={variant}
    underline={underline}
    disabled={disabled}
  >
    {children}
  </StyledLink>
);

export const RichTextLink = ({
  href,
  target,
  bold,
  children,
  onClick,
  className,
  underline = false,
}) => {
  return (
    <RichLink
      href={href}
      className={className}
      target={target}
      onClick={onClick}
      bold={bold}
      underline={underline}
    >
      {children}
    </RichLink>
  );
};

SmolLink.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  target: PropTypes.string,
  bold: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  className: PropTypes.string,
  underline: PropTypes.bool,
};

ButtonLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  bold: PropTypes.bool,
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
  underline: PropTypes.bool,
  disabled: PropTypes.bool,
};

RichTextLink.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  target: PropTypes.string,
  bold: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  underline: PropTypes.bool,
};
