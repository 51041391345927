import React from 'react';
import PropTypes from 'prop-types';
import { useReporter } from '../../reporter';
import { headerButtonClicked } from './events';
import { StyledHeaderButton } from './styles';

export const HeaderButton = ({ text, position = 0, onClick }) => {
  const reporter = useReporter();
  const onClickAction = () => {
    onClick?.();

    reporter.tag(headerButtonClicked(text, position));
  };

  return (
    <StyledHeaderButton onClick={onClickAction}>{text}</StyledHeaderButton>
  );
};

HeaderButton.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};
