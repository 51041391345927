export const errorPageHeader = {
  400: 'Whoops!',
  404: 'Whoops!',
  500: 'Whoops!',
  503: 'Under Maintenance',
};

export const errorPageCopy = {
  400: "We couldn't handle this page. Are you sure you're in the right place...? Meanwhile, we're still selling the healthiest pet food on the market!",
  404: 'Something’s gone wrong. Either the page you’re looking for doesn’t exist, or a puppy made a meal out of it.',
  500: 'Something’s gone wrong. Either the page you’re looking for doesn’t exist, or a puppy made a meal out of it.',
  503: "We're currently undergoing scheduled maintenance. Please check back later or contact customer experience at 844-88-OLLIE (65543).",
};
