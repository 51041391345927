import React from 'react';
import PropTypes from 'prop-types';
import { TitleCTAContainer } from '../TitleCTAContainer';
import { ResponsiveImage } from '../ResponsiveImage';
import {
  ProductTitle,
  ProductImageContainer,
  ProductDescription,
  ProductRecipes,
  Recipe,
  RecipeImage,
} from './styles';

export const ProductsSummaryDisplay = ({
  sectionHeader,
  sectionHeaderIcon = null,
  recipeLinks = [],
  ctaHref,
  ctaButtonText = 'See all',
  ctaButtonVariant = 'egg',
  description = '',
  onRecipeClick,
}) => {
  const button = {
    text: ctaButtonText,
    variant: ctaButtonVariant,
    href: ctaHref,
    onClick: onRecipeClick,
  };

  const header = sectionHeaderIcon ? (
    <ProductTitle>
      {sectionHeader}
      <ProductImageContainer>
        <ResponsiveImage
          src={sectionHeaderIcon.src}
          alt={sectionHeaderIcon.alt}
          width={78}
          height={29}
        />
      </ProductImageContainer>
    </ProductTitle>
  ) : (
    sectionHeader
  );

  return (
    <TitleCTAContainer title={header} ctaButton={button}>
      <ProductDescription>{description}</ProductDescription>
      <ProductRecipes>
        {recipeLinks.map(({ img, text, href }) => (
          <Recipe key={text} href={href} onClick={onRecipeClick}>
            <RecipeImage>
              <ResponsiveImage {...img} />
            </RecipeImage>
            <p>{text}</p>
          </Recipe>
        ))}
      </ProductRecipes>
    </TitleCTAContainer>
  );
};

ProductsSummaryDisplay.propTypes = {
  sectionHeader: PropTypes.string.isRequired,
  sectionHeaderIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  recipeLinks: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
    }),
  ),
  ctaHref: PropTypes.string.isRequired,
  ctaButtonText: PropTypes.string,
  ctaButtonVariant: PropTypes.string,
  description: PropTypes.string,
  onRecipeClick: PropTypes.func.isRequired,
};
