'use client';
import { useState, useCallback } from 'react';
import { ApiResult, useService } from '../api';
import { PaymentIntentService } from './PaymentIntentService';
import { getPendingIntentInternalId } from './functions';

export const usePaymentIntent = () => {
  const service = useService(PaymentIntentService);
  const [paymentIntents, setPaymentIntents] = useState([]);

  const createIntent = useCallback(
    async (cartId, paymentMethodId) =>
      ApiResult.callAsync(async () => {
        const response = await service.create(cartId, paymentMethodId);
        setPaymentIntents(response.data);
        return response.data;
      }),
    [service],
  );

  const updateIntent = useCallback(
    async (cartId, elements, paymentMethodId) =>
      ApiResult.callAsync(async () => {
        const pendingIntentInternalId =
          getPendingIntentInternalId(paymentIntents);
        const response = await service.update(
          cartId,
          pendingIntentInternalId,
          paymentMethodId,
        );
        setPaymentIntents([
          response.data,
          ...paymentIntents.filter(
            intent => intent.id !== pendingIntentInternalId,
          ),
        ]);
        // recommended stripe api for syncing payment element to PaymentIntent
        await elements.fetchUpdates();
        return response.data;
      }),
    [paymentIntents, service],
  );

  const confirmPaymentIntent = async ({
    stripe,
    elements,
    clientSecret,
    options,
  }) =>
    ApiResult.callAsync(async () => {
      if (!stripe) {
        throw new Error('stripe or elements has not yet loaded');
      }
      if (clientSecret)
        elements.update({ customerSessionClientSecret: clientSecret });
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        ...options,
        clientSecret: clientSecret,
      });

      if (error) {
        throw error;
      }
      return paymentIntent;
    });

  const confirmAsyncTransaction = useCallback(
    async (id, paymentMethodId) =>
      ApiResult.callAsync(async () => {
        const response = await service.confirm(id, paymentMethodId);
        return response.data;
      }),
    [service],
  );
  const clearIntents = useCallback(() => {
    setPaymentIntents([]);
  }, [setPaymentIntents]);

  return {
    paymentIntents,
    createIntent,
    updateIntent,
    confirmPaymentIntent,
    confirmAsyncTransaction,
    clearIntents,
  };
};
