import useSWR from 'swr';
import {
  PRODUCT_TYPE_FRESH,
  PRODUCT_TYPE_DRY,
  PRODUCT_TYPE_MIXED,
  DEFAULT_FRESH,
  DEFAULT_DRY,
  DEFAULT_MIXED,
  HALF_PORTION,
  PRODUCT_TYPE_HALF_FRESH,
  DEFAULT_HALF_PORTION,
} from './constants';
import { getRecommendationsUrl } from './service';

/**
 * @typedef {Object} RecParams
 * @property {string} [petId]
 * @property {number} [weight]
 * @property {string} [activity]
 * @property {boolean} [neutered]
 * @property {array} [allergies]
 * @property {boolean} [fullProducts]
 * @property {string} [couponCode]
 * @property {string } [recommendationVersion]
 */

/**
 * @param {RecParams} params
 * @returns
 */
export const useProductRecommendations = ({
  petId,
  weight,
  activity,
  neutered,
  allergies,
  fullProducts = true,
  couponCode,
  recommendationVersion,
}) => {
  const hasParameter = petId || weight || activity || neutered || allergies;
  const recommendations = hasParameter
    ? getRecommendationsUrl({
        petId,
        weight,
        activity,
        neutered,
        allergies,
        fullProducts,
        couponCode,
        recommendationVersion,
      })
    : null;
  const recommendationsHalfProduct = hasParameter
    ? getRecommendationsUrl({
        petId,
        weight,
        activity,
        neutered,
        allergies,
        fullProducts,
        couponCode,
        recommendationVersion,
        portionSize: HALF_PORTION,
      })
    : null;

  const {
    data: fullPortionData,
    mutate: mutateFullPortion,
    error: fullPortionError,
    isValidating: fullPortionIsValidating,
  } = useSWR(recommendations);
  const {
    data: halfPortionData,
    mutate: mutateHalfPortion,
    error: halfPortionError,
    isValidating: halfPortionIsValidating,
  } = useSWR(recommendationsHalfProduct);

  const refreshPlans = () => {
    mutateFullPortion();
    mutateHalfPortion();
  };

  const recommendationsMap = {
    [PRODUCT_TYPE_FRESH]: DEFAULT_FRESH,
    [PRODUCT_TYPE_DRY]: DEFAULT_DRY,
    [PRODUCT_TYPE_MIXED]: DEFAULT_MIXED,
    [PRODUCT_TYPE_HALF_FRESH]: DEFAULT_HALF_PORTION,
  };

  const error = fullPortionError || halfPortionError;

  if (fullPortionData?.length)
    fullPortionData.forEach(
      ({
        product_type,
        starter_boxes,
        products,
        available_recipes,
        recommended,
      }) =>
        (recommendationsMap[product_type] = {
          starterBoxes: starter_boxes,
          products: products,
          availableRecipes: available_recipes,
          recommended: recommended,
        }),
    );

  if (halfPortionData?.length)
    recommendationsMap[PRODUCT_TYPE_HALF_FRESH] = {
      starterBoxes: halfPortionData[0].starter_boxes,
      products: halfPortionData[0].products,
      availableRecipes: halfPortionData[0].available_recipes,
      recommended: halfPortionData[0].recommended,
    };

  return {
    fresh: recommendationsMap[PRODUCT_TYPE_FRESH],
    dry: recommendationsMap[PRODUCT_TYPE_DRY],
    mixed: recommendationsMap[PRODUCT_TYPE_MIXED],
    half_fresh: recommendationsMap[PRODUCT_TYPE_HALF_FRESH],
    error,
    isLoading: hasParameter
      ? (fullPortionIsValidating || halfPortionIsValidating) && !error
      : false,
    isValidating: fullPortionIsValidating || halfPortionIsValidating,
    refreshPlans,
  };
};
