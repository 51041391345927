import { xor, difference, capitalize, union } from 'lodash';
import { costFormatter } from '@format/currency';
import {
  ALL_RECIPES,
  ALL_PRODUCT_TYPES,
  PRODUCT_TYPE_FRESH,
  PRODUCT_TYPE_MIXED,
  PRODUCT_TYPE_HALF_FRESH,
  productTypeReference,
  HALF_PORTION,
} from './constants';

export const getDefaultSubscriptionProduct = subscriptionProducts => {
  // We start by looking for the 4 week box and search down.
  // We _should_ always find something before exiting the loop.
  // If we don't, worst case, starter box == subscription box.
  let numWeeks = 4;

  while (numWeeks) {
    const productForWeeks = subscriptionProducts.find(
      // eslint-disable-next-line no-loop-func
      product => product.weeks_of_food === numWeeks,
    );

    if (productForWeeks) {
      return productForWeeks;
    }

    numWeeks--;
  }

  // This should never happen but ensures we always have something to work with.
  return subscriptionProducts[0];
};

export const getStarterBoxProduct = product => product.starterBoxes[0];

export const getStarterBoxes = ({ fresh, dry, mixed }) => ({
  fresh: fresh.starterBoxes[0],
  dry: dry.starterBoxes[0],
  mixed: mixed.starterBoxes[0],
});

export const getUnavailableRecipes = availableRecipes =>
  ALL_RECIPES.filter(recipe => !availableRecipes.includes(recipe));

export const getUnavailableProductTypes = availableProductTypes =>
  ALL_PRODUCT_TYPES.filter(
    productType => !availableProductTypes.includes(productType),
  );

export const getPetAllergicToOwnPlan = (petPlan, availableRecipes) => {
  if (!petPlan) {
    return false;
  }

  const allergicToFresh =
    availableRecipes.fresh &&
    !petPlan.variants[0].recipes.fresh.every(recipe =>
      availableRecipes.fresh.includes(recipe),
    );
  const allergicToDry =
    availableRecipes.dry &&
    !petPlan.variants[0].recipes.dry.every(recipe =>
      availableRecipes.dry.includes(recipe),
    );

  // If pet's current recipe combo isn't in available recipes,
  // they're allergic
  return Boolean(allergicToFresh || allergicToDry);
};

export const getNumStarterBoxWeeks = recommendedStarterBox =>
  recommendedStarterBox.weeks_of_food;

export const getTotalPacks = (hasPlan, petPlan, recommendedVariant) =>
  hasPlan ? petPlan.tray_count : recommendedVariant.tray_count;

export const getSuggestedVariant = (hasPlan, petPlan, recommended) =>
  hasPlan ? petPlan.variants[0] : recommended;

export const getVariantByRecipes = (variants, recipes) => {
  const variant = variants.find(
    variant =>
      !xor(variant.recipes.fresh, recipes.fresh).length &&
      !xor(variant.recipes.dry, recipes.dry).length,
  );
  return variant;
};

export const getStarterBoxFreshConfig = (
  fresh,
  petPlan,
  selectedFreshRecipes,
) => {
  const availableRecipes = fresh.availableRecipes.fresh;
  const recommended = fresh.recommended;
  const starterBoxProduct = getStarterBoxProduct(fresh);
  const config = getFreshConfig(
    availableRecipes,
    recommended,
    starterBoxProduct,
    petPlan,
  );
  return {
    ...config,
    defaultRecipes: selectedFreshRecipes ?? config.defaultRecipes,
    subscriptionProducts: fresh.products,
    numStarterBoxWeeks: getNumStarterBoxWeeks(starterBoxProduct),
  };
};

export const getCurrentProductTypeConfig = (products, petProduct) => {
  const { fresh, dry, mixed, half_fresh } = products;
  const availableProductTypes = [];
  if (fresh.availableRecipes.fresh.length) availableProductTypes.push('fresh');
  if (half_fresh.availableRecipes.fresh.length)
    availableProductTypes.push('half_fresh');
  if (dry.availableRecipes.dry.length) availableProductTypes.push('dry');
  if (mixed.availableRecipes.fresh.length || mixed.availableRecipes.dry.length)
    availableProductTypes.push('mixed');

  const { defaultProductType, unavailableProductTypes } = getProductTypesConfig(
    availableProductTypes,
    petProduct,
  );

  return {
    defaultProductType,
    unavailableProductTypes,
    subscriptionProducts: {
      fresh: fresh.products,
      half_fresh: half_fresh.products,
      dry: dry.products,
      mixed: mixed.products,
    },
    currentFrequency: petProduct.weeks_of_food,
  };
};

export const getStarterBoxProductTypeConfig = (
  { fresh, dry, mixed, half_fresh },
  petProduct,
  selectedProductType,
) => {
  const availableProductTypes = [];
  if (fresh.availableRecipes.fresh.length) availableProductTypes.push('fresh');
  if (mixed.availableRecipes.fresh.length || mixed.availableRecipes.dry.length)
    availableProductTypes.push('mixed');
  if (half_fresh?.availableRecipes.fresh.length)
    availableProductTypes.push('half_fresh');
  if (dry.availableRecipes.dry.length) availableProductTypes.push('dry');
  const config = getProductTypesConfig(availableProductTypes, petProduct);
  return {
    ...config,
    defaultProductType: selectedProductType ?? config.defaultProductType,
    subscriptionProducts: {
      fresh: fresh.products,
      dry: dry.products,
      mixed: mixed.products,
      half_fresh: half_fresh.products,
    },
  };
};

export const getVariantsByFrequency = (
  productTypes,
  subscriptionProducts,
  unavailableProductTypes,
  currentFrequency,
) => {
  const recommendedFreshFrequency = subscriptionProducts.fresh.find(product =>
    product.variants.find(
      variant =>
        variant.sku === productTypes.fresh.recommended.subscription_sku,
    ),
  )?.weeks_of_food;
  const recommendedHalfFreshFrequency = subscriptionProducts.half_fresh.find(
    product =>
      product.variants.find(
        variant =>
          variant.sku === productTypes.half_fresh.recommended.subscription_sku,
      ),
  )?.weeks_of_food;
  const recommendedMixedFrequency = subscriptionProducts.mixed.find(product =>
    product.variants.find(
      variant =>
        variant.sku === productTypes.mixed.recommended.subscription_sku,
    ),
  )?.weeks_of_food;
  const recommendedDryFrequency = subscriptionProducts.dry?.find(product =>
    product.variants.find(
      variant => variant.sku === productTypes.dry.recommended?.subscription_sku,
    ),
  )?.weeks_of_food;

  const freshVariants = getVariantsSortedByPrice(
    subscriptionProducts.fresh.find(
      product => product.weeks_of_food === currentFrequency,
    )?.variants ||
      subscriptionProducts.fresh.find(
        product => product.weeks_of_food === recommendedFreshFrequency,
      )?.variants ||
      [],
  );

  const half_freshVariants = getVariantsSortedByPrice(
    subscriptionProducts.half_fresh.find(
      product => product.weeks_of_food === currentFrequency,
    )?.variants ||
      subscriptionProducts.half_fresh.find(
        product => product.weeks_of_food === recommendedHalfFreshFrequency,
      )?.variants ||
      [],
  );

  const mixedVariants = getVariantsSortedByPrice(
    subscriptionProducts.mixed.find(
      product => product.weeks_of_food === currentFrequency,
    )?.variants ||
      subscriptionProducts.mixed.find(
        product => product.weeks_of_food === recommendedMixedFrequency,
      )?.variants ||
      [],
  );

  const dryVariants = getVariantsSortedByPrice(
    subscriptionProducts.dry.find(
      product => product.weeks_of_food === currentFrequency,
    )?.variants ||
      subscriptionProducts.dry.find(
        product => product.weeks_of_food === recommendedDryFrequency,
      )?.variants ||
      [],
  );

  const products = {
    fresh: !unavailableProductTypes.includes('fresh') ? freshVariants[0] : null,
    half_fresh: !unavailableProductTypes.includes('half_fresh')
      ? half_freshVariants[0]
      : null,
    mixed: !unavailableProductTypes.includes('mixed') ? mixedVariants[0] : null,
    dry: !unavailableProductTypes.includes('dry') ? dryVariants[0] : null,
    freshVariants,
    half_freshVariants,
    mixedVariants,
    dryVariants,
  };

  return products;
};

export const getStarterBoxDryConfig = (
  dry,
  cartProduct,
  selectedBakedRecipes,
) => {
  const availableRecipes = dry.availableRecipes.dry;
  const recommended = dry.recommended;
  const starterBoxProduct = getStarterBoxProduct(dry);
  const config = getDryConfig(
    availableRecipes,
    recommended,
    starterBoxProduct,
    cartProduct,
  );
  return {
    ...config,
    defaultRecipes: selectedBakedRecipes ?? config.defaultRecipes,
    subscriptionProducts: dry.products,
    numStarterBoxWeeks: getNumStarterBoxWeeks(starterBoxProduct),
  };
};

export const getCurrentFreshConfig = (fresh, petPlan) => {
  const availableRecipes = union(
    fresh.availableRecipes.fresh,
    petPlan.variants[0].recipes.fresh,
  );
  const recommended = fresh.recommended;
  const product =
    fresh.products.find(p => p.sku === petPlan.sku) ||
    getDefaultSubscriptionProduct(fresh.products);

  return getFreshConfig(availableRecipes, recommended, product, petPlan);
};

export const createSkuWeeksMap = data => {
  const skuWeeksMap = {};

  // Loop through the products array
  data.products.forEach(product => {
    const weeksOfFood = product.weeks_of_food;

    // Loop through the variants array within each product
    product.variants.forEach(variant => {
      skuWeeksMap[variant.sku] = weeksOfFood;
    });
  });

  return skuWeeksMap;
};

export const findProductByCadence = (data, cadence) => {
  return data.products.find(prod => prod.weeks_of_food === cadence);
};

export const getFreshConfig = (
  availableFreshRecipes,
  recommended,
  product,
  petPlan,
) => {
  const hasPlan = !!petPlan;
  const totalPacks = getTotalPacks(hasPlan, petPlan, product);
  const isPetAllergicToOwnPlan = getPetAllergicToOwnPlan(petPlan, {
    fresh: availableFreshRecipes,
  });

  let defaultRecipes;

  if (hasPlan && !isPetAllergicToOwnPlan) {
    defaultRecipes = !petPlan.variants[0].recipes.fresh.length
      ? recommended.recipes.fresh
      : petPlan.variants[0].recipes.fresh;
  } else {
    defaultRecipes = recommended.recipes.fresh;
  }

  const suggestedVariant = getSuggestedVariant(hasPlan, petPlan, recommended);
  const unavailableRecipes = getUnavailableRecipes(availableFreshRecipes);

  return {
    product,
    availableRecipes: availableFreshRecipes,
    totalPacks: Number(totalPacks),
    defaultRecipes,
    suggestedVariant,
    unavailableRecipes,
  };
};

export const getProductTypesConfig = (availableProductTypes, petProduct) => {
  const hasProduct = !!petProduct;

  let productType = null;

  if (hasProduct) {
    if (
      petProduct.product_type === PRODUCT_TYPE_FRESH &&
      petProduct.portion_size === '0.5'
    ) {
      productType = PRODUCT_TYPE_HALF_FRESH;
    } else if (petProduct.product_type === PRODUCT_TYPE_MIXED) {
      productType = PRODUCT_TYPE_MIXED;
    } else {
      productType = PRODUCT_TYPE_FRESH;
    }
  }

  const defaultProductType = hasProduct
    ? productTypeReference[productType]
    : availableProductTypes[0];

  const unavailableProductTypes = getUnavailableProductTypes(
    availableProductTypes,
  );
  return {
    defaultProductType,
    unavailableProductTypes,
  };
};

export const getCurrentDryConfig = (dry, petPlan) => {
  const availableRecipes = dry.availableRecipes.dry;
  const recommended = dry.recommended;
  const product =
    dry.products.find(p => p.sku === petPlan.sku) ||
    getDefaultSubscriptionProduct(dry.products);

  return getDryConfig(availableRecipes, recommended, product, petPlan);
};

export const getDryConfig = (
  availableBakedRecipes,
  recommended,
  product,
  petPlan,
) => {
  const hasPlan = !!petPlan;
  const totalPacks = getTotalPacks(hasPlan, petPlan, product);
  const isPetAllergicToOwnPlan = getPetAllergicToOwnPlan(petPlan, {
    dry: availableBakedRecipes,
  });

  let defaultRecipes;

  if (hasPlan && !isPetAllergicToOwnPlan) {
    defaultRecipes = !petPlan.variants[0].recipes.dry.length
      ? recommended.recipes.dry
      : petPlan.variants[0].recipes.dry;
  } else {
    defaultRecipes = recommended.recipes.dry;
  }

  const suggestedVariant = getSuggestedVariant(hasPlan, petPlan, recommended);
  const unavailableRecipes = getUnavailableRecipes(availableBakedRecipes);

  return {
    product,
    availableRecipes: availableBakedRecipes,
    totalPacks: Number(totalPacks),
    defaultRecipes,
    suggestedVariant,
    unavailableRecipes,
  };
};

export const getNewPlanValues = (
  productRecommendations,
  currentFrequency,
  currentRecipes,
  recommendedProduct,
  currentPortionSize = '1.0',
) => {
  let newVariant;
  const currentHalfPortion = currentPortionSize === '0.5';
  // Find the product whose frequency matches the current plan's
  let newProduct;
  if (currentRecipes.fresh.length && currentRecipes.dry.length) {
    newProduct = productRecommendations?.mixed?.products.find(product => {
      if (product.weeks_of_food !== currentFrequency) return false;

      // Check if product includes current recipes. If it doesn't, find the variant whose recipes
      // match the recommended
      if (
        difference(currentRecipes.fresh, product.available_recipes?.fresh)
          .length !== 0 &&
        difference(currentRecipes.dry, product.available_recipes?.dry)
          .length !== 0
      ) {
        newVariant = getVariantByRecipes(product.variants, {
          fresh: productRecommendations.mixed.recommended.recipes.fresh,
          dry: productRecommendations.mixed.recommended.recipes.dry,
        });
        return true;
      }

      // Find the variant whose recipes match the current plan's
      newVariant = getVariantByRecipes(product.variants, {
        fresh: currentRecipes.fresh,
        dry: currentRecipes.dry,
      });

      return true;
    });
  } else if (currentRecipes.fresh.length && currentHalfPortion) {
    newProduct = productRecommendations?.half_fresh?.products.find(product => {
      if (product.weeks_of_food !== currentFrequency) return false;

      // Check if product includes current recipes. If it doesn't, find the variant whose recipes
      // match the recommended
      if (
        difference(currentRecipes.fresh, product.available_recipes?.fresh)
          .length !== 0
      ) {
        newVariant = getVariantByRecipes(product.variants, {
          fresh: productRecommendations.fresh.recommended.recipes.fresh,
          dry: [],
        });
        return true;
      }

      // Find the variant whose recipes match the current plan's
      newVariant = getVariantByRecipes(product.variants, {
        fresh: currentRecipes.fresh,
        dry: currentRecipes.dry,
      });

      return true;
    });
  } else if (currentRecipes.fresh.length) {
    newProduct = productRecommendations?.fresh?.products.find(product => {
      if (product.weeks_of_food !== currentFrequency) return false;

      // Check if product includes current recipes. If it doesn't, find the variant whose recipes
      // match the recommended
      if (
        difference(currentRecipes.fresh, product.available_recipes?.fresh)
          .length !== 0
      ) {
        newVariant = getVariantByRecipes(product.variants, {
          fresh: productRecommendations.fresh.recommended.recipes.fresh,
          dry: [],
        });
        return true;
      }

      // Find the variant whose recipes match the current plan's
      newVariant = getVariantByRecipes(product.variants, {
        fresh: currentRecipes.fresh,
        dry: currentRecipes.dry,
      });

      return true;
    });
  } else if (currentRecipes.dry.length) {
    newProduct = productRecommendations?.dry?.products.find(product => {
      if (product.weeks_of_food !== currentFrequency) return false;

      // Check if product includes current recipes. If it doesn't, find the variant whose recipes
      // match the recommended
      if (
        difference(currentRecipes.dry, product.available_recipes?.dry)
          .length !== 0
      ) {
        newVariant = getVariantByRecipes(product.variants, {
          fresh: [],
          dry: productRecommendations.dry.recommended.recipes.dry,
        });
        return true;
      }

      // Find the variant whose recipes match the current plan's
      newVariant = getVariantByRecipes(product.variants, {
        fresh: currentRecipes.fresh,
        dry: currentRecipes.dry,
      });

      return true;
    });
  }

  // If no product was found that matches current frequency, try finding a product that matches current recipes.
  if (!newProduct) {
    newProduct = recommendedProduct;
    newVariant =
      getVariantByRecipes(recommendedProduct.variants, {
        fresh: currentRecipes,
        dry: [],
      }) ?? newProduct.variants[0];
  }

  // fallback to recommended
  if (!newProduct || !newVariant) {
    newProduct = recommendedProduct;
    newVariant = newProduct.variants[0];
  }

  return {
    newPricePerWeek: newVariant.price_per_week,
    newRecipes: newVariant.recipes,
    newTrayCount: newProduct.variants[0].tray_count,
    newWeeksOfFood: newProduct.weeks_of_food,
    newSku: newVariant.sku,
    newBagCount: newVariant.bag_count,
    newPortionSize: newProduct.portion_size,
    newProductCalories: parseFloat(newProduct?.kcalories_per_day),
  };
};

export const getEditedPlanValues = (
  currentFrequency,
  productTypes,
  selectedFreshRecipes,
  selectedBakedRecipes,
  mealPrepType,
) => {
  const selectedPrepType = productTypes[mealPrepType];
  // GF Plans do NOT have a subscription_sku. Because of this,
  // we must fall back to the cohortRecommended sku and use that
  // to find a recommendedFrequency as a cadence may not exist
  // for a GF Plan's current product frequency – this will NOT affect
  // current Cohort plans
  const recommendedSku =
    selectedPrepType.recommended.subscription_sku ??
    selectedPrepType.cohortRecommended.subscription_sku;
  const recommendedFrequency = selectedPrepType.products.find(product =>
    product.variants.find(variant => variant.sku === recommendedSku),
  )?.weeks_of_food;

  // Search for the current frequency, then the recommended product's frequency.
  // For GF plans, we want to search through them first before searching through new cohort variants
  // As such, we need to confirm that we're returning an accurate newProduct that matches selected recipes as well
  // while still allowing GF plans to stay within their plan first
  const newProduct =
    selectedPrepType.products.find(
      product =>
        product.weeks_of_food === currentFrequency &&
        !!getVariantByRecipes(product.variants, {
          fresh: selectedFreshRecipes,
          dry: selectedBakedRecipes,
        }),
    ) ??
    selectedPrepType.products.find(
      product =>
        product.weeks_of_food === recommendedFrequency &&
        !!getVariantByRecipes(product.variants, {
          fresh: selectedFreshRecipes,
          dry: selectedBakedRecipes,
        }),
    );

  const newVariant = getVariantByRecipes(newProduct.variants, {
    fresh: selectedFreshRecipes,
    dry: selectedBakedRecipes,
  });

  return {
    newProduct: newProduct,
    newPricePerWeek: newVariant.price_per_week,
    newRecipes: newVariant.recipes,
    newTrayCount: newProduct.tray_count,
    newWeeksOfFood: newProduct.weeks_of_food,
    newSku: newVariant.sku,
  };
};

export const formatPlanValuesRecipes = recipes => {
  const hasDry = recipes.dry.length > 0;
  const hasFresh = recipes.fresh.length > 0;

  if (hasFresh && hasDry) {
    return [
      ...recipes.fresh.map(recipe => `Fresh ${capitalize(recipe)}`).join(', '),
      ',',
      ' ',
      ...recipes.dry.map(recipe => `Baked ${capitalize(recipe)}`).join(', '),
    ];
  }

  if (hasFresh) {
    return recipes.fresh
      .map(recipe => `Fresh ${capitalize(recipe)}`)
      .join(', ');
  }

  if (hasDry) {
    return recipes.dry.map(recipe => `Baked ${capitalize(recipe)}`).join(', ');
  }
};

export const addonsToArray = addons => {
  if (!addons?.length) return [];
  return [
    ...addons
      ?.filter(addon => addon.product_type === 'TR')
      .map(
        ({ product_name, quantity }) =>
          `${capitalize(product_name)} (${quantity})`,
      ),
    ...addons
      ?.filter(addon => addon.product_type === 'SP')
      .map(
        ({ product_name, quantity }) =>
          `${capitalize(product_name)} (${quantity})`,
      ),
  ];
};

export const determinePlanType = (recipes, portionSize) => {
  const hasDry = recipes.dry && recipes.dry.length > 0;
  const hasFresh = recipes.fresh && recipes.fresh.length > 0;
  if (hasDry && hasFresh) return 'mixed';
  if (hasDry) return 'baked';
  if (hasFresh && portionSize === HALF_PORTION) return 'half_fresh';
  if (hasFresh) return 'fresh';
  throw new Error('Invalid object');
};

export const getVariantsSortedByPrice = variants => {
  const result = variants.sort((prev, curr) => {
    return prev.price_per_week - curr.price_per_week;
  });
  return result;
};

export const getPriceAndMessageFromVariant = (
  variant,
  defaultVariantPrice,
  selectedRecipes,
) => {
  const newVariantPrice =
    parseFloat(variant?.price_per_week || 0) || parseFloat(defaultVariantPrice); // allows test to pass because of missing varriants in mirage

  let selectedVariantPrice = !newVariantPrice // if the new variant doesn't exist, then default to default variant price
    ? defaultVariantPrice
    : selectedRecipes.length // if there are recipes selected, then take the selected variant price
    ? newVariantPrice
    : defaultVariantPrice; // we should always have a default variant. falls back to first variant of product in parent container.

  const message =
    selectedRecipes.length === 0
      ? 'Please select recipes above.'
      : defaultVariantPrice === selectedVariantPrice
      ? ''
      : selectedVariantPrice > defaultVariantPrice
      ? `Because you adjusted your recipes, your price increased from ${costFormatter(
          defaultVariantPrice,
        )}/week to ${costFormatter(selectedVariantPrice)}/week.`
      : selectedVariantPrice < defaultVariantPrice
      ? `Because you adjusted your recipes, your price decreased from ${costFormatter(
          defaultVariantPrice,
        )}/week to ${costFormatter(selectedVariantPrice)}/week.`
      : '';

  const price = `${costFormatter(selectedVariantPrice)} PER WEEK`;
  return { price, message };
};

export const truncatePrice = price =>
  price?.slice(-2) === '00' ? price?.split('.')[0] : price;

/**
 *
 * @param {string} period
 * @param {Object} options={}
 * @returns {string}
 */
export const getPricePropertyName = (period, options = {}) => {
  const { discounted } = options;
  switch (period) {
    case 'week':
      return discounted ? 'discounted_price_per_week' : 'price_per_week';
    case 'meal':
      return discounted ? 'discounted_price_per_meal' : 'price_per_meal';
    case 'day':
      return discounted ? 'discounted_price_per_day' : 'price_per_day';
    default:
      throw new Error(
        `The provided 'period' '${period}' is not currently supported. Only 'week', 'meal', and 'day' are supported  `,
      );
  }
};
