'use client';
import PropTypes from 'prop-types';
import React from 'react';
import { ErrorPage } from '.';

export class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    reporter: PropTypes.object.isRequired,
  };

  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.props.reporter.error(error, { contexts: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // Render ErrorPage fallback UI
      return <ErrorPage statusCode={500} />;
    }
    return this.props.children;
  }
}
