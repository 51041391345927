'use client';
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { ToastContext } from './ToastContext.js';
import { ToastWrapper } from './styles.js';
import { Toast } from './index';

const ToastContainer = ({ toasts }) => {
  return ReactDOM.createPortal(
    <ToastWrapper>
      {toasts.map(toast => (
        <Toast
          key={toast.id}
          status={toast.status}
          statusMessage={toast.message}
          statusHeadline={toast.headline}
          shown={toast.shown}
          removeToast={toast.remove}
        />
      ))}
    </ToastWrapper>,
    document.body,
  );
};

let id = 1;
export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);
  const showToast = useCallback(
    ({ status, message, headline }) => {
      const toastId = id++;
      setToasts(toasts => [
        ...toasts,
        {
          id: toastId,
          shown: true,
          remove: () =>
            setToasts(toasts => toasts.filter(t => t.id !== toastId)),
          status,
          message,
          headline,
        },
      ]);
    },
    [setToasts],
  );

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toasts.length > 0 && <ToastContainer toasts={toasts} />}
    </ToastContext.Provider>
  );
};

ToastProvider.displayName = 'ToastProvider';

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
