import axios from 'axios';
import {
  CUSTOMER_EXISTS,
  INVALID_EMAIL,
  TOO_MANY_FAILED_LOGIN_ATTEMPTS,
} from './auth';
import {
  CONFLICT,
  ADDRESS_ERROR_MAP,
  ADDRESS_ENDPOINT_REGEX,
  CUSTOMER_ENDPOINT_REGEX,
  PAYMENT_METHODS_REGEX,
  CHECKOUT_ENDPOINT_REGEX,
  DELIVERY_ENDPOINT_REGEX,
  AUTH_ENDPOINT_REGEX,
} from './constants';

const STRIPE_ERROR_CODE = {
  INVALID_EXPIRY_YEAR_PAST: 'invalid_expiry_year_past',
  INVALID_NUMBER: 'invalid_number',
  INCOMPLETE_CVC: 'incomplete_cvc',
  INCORRECT_CVC: 'incorrect_cvc',
  INVALID_CVC: 'invalid_cvc',
  INCOMPLETE_NUMBER: 'incomplete_number',
  INCORRECT_NUMBER: 'incorrect_number',
  INCOMPLETE_EXPIRY: 'incomplete_expiry',
  CARD_DECLINED: 'card_declined',
  API_CONNECTION_ERROR: 'api_connection_error',
  EXPIRED_CARD: 'expired_card',
  PROCESSING_ERROR: 'processing_error',
};

const CHECKOUT_PAYMENT_INVALID_ERROR_CODE = {
  INVALID_PAYMENT_METHOD: 'invalid_payment_method',
  PAYMENT_ERROR: 'payment_error',
};

const CHECKOUT_PAYMENT_INSUFFICIENT_FUNDS_ERROR_CODE = {
  FAILED_TEMPORARY_HOLD: 'failed_temporary_hold',
};

const CHECKOUT_UNABLE_TO_PROCESS_ERROR_CODE = {
  NO_CART_FOUND: 'no_cart_found',
  PET_IN_CART_NOT_FOUND: 'pet_in_cart_not_found',
  BC_ORDER_FAILED: 'bc_order_failed',
  BC_ORDER_STATUS_ERROR: 'bc_order_status_error',
};

const CHECKOUT_ADDRESS_ERROR_CODE = {
  INVALID_ADDRESS: 'invalid_address',
  NO_TRANSIT_OPTION_FOUND: 'no_transit_option_found',
};

/**
 * @param {Error} err
 * @returns {ApiFailureResult}
 */
export const wrapErrorWithResult = err => {
  if (axios.isAxiosError(err)) {
    // No response or status 0 is a network error
    if (!err.response?.status) {
      return ApiResult.error.network(err);
    }
    // Server problem, nothing we can do.
    if (err.response.status >= 500) {
      return ApiResult.error.server(err.response.data.message, err);
    }

    // Client problem, should fix with validation or display error.
    if (err.response.status >= 400) {
      if (AUTH_ENDPOINT_REGEX.test(err.response.config.url)) {
        if (err.response.status === 429) {
          return ApiResult.error.tooManyFailedLogin(err.error, err);
        }
        const authError = new URL(err.response.data.url).searchParams.get(
          'error',
        );

        switch (authError) {
          case CUSTOMER_EXISTS:
            return ApiResult.error.existingCustomer(err);
          case INVALID_EMAIL:
            return ApiResult.error.invalidEmail(err);
          case TOO_MANY_FAILED_LOGIN_ATTEMPTS:
            return ApiResult.error.tooManyFailedLogin(err.message, err);
          default:
            return ApiResult.error.client(err.message, err);
        }
      }

      if (
        err.response.status === 400 &&
        ADDRESS_ENDPOINT_REGEX.test(err.response.config.url)
      ) {
        return ApiResult.error.address(err);
      }

      if (
        err.response.status === 400 &&
        CUSTOMER_ENDPOINT_REGEX.test(err.response.config.url)
      )
        return ApiResult.error.invalidEmail(err);
      // TODO: customer endpoint throws 400 for more reasons than this.
      if (
        err.response.status === 422 &&
        PAYMENT_METHODS_REGEX.test(err.response.config.url)
      )
        return ApiResult.error.paymentInvalid(err.response.data.detail, err);

      if (err.response.status === 402)
        return ApiResult.error.paymentInvalid(err.response.data.detail, err);

      if (
        err.response.status === 400 &&
        DELIVERY_ENDPOINT_REGEX.test(err.response.config.url)
      )
        return ApiResult.error.skipNextDeliveryErr(err);

      if (
        err.response.status === 422 &&
        CHECKOUT_ENDPOINT_REGEX.test(err.response.config.url)
      ) {
        if (CHECKOUT_PAYMENT_INVALID_ERROR_CODE[err.response.data.code])
          return ApiResult.error.paymentInvalid(err.response.data.detail, err);

        if (
          CHECKOUT_PAYMENT_INSUFFICIENT_FUNDS_ERROR_CODE[err.response.data.code]
        )
          return ApiResult.error.paymentInsufficientFunds(
            err.response.data.detail,
            err,
          );

        if (CHECKOUT_UNABLE_TO_PROCESS_ERROR_CODE[err.response.data.code])
          return ApiResult.error.unableToProcessOrder(
            err.response.data.detail,
            err,
          );

        if (CHECKOUT_ADDRESS_ERROR_CODE[err.response.data.code]) {
          return ApiResult.error.checkoutAddress(err.response.data.detail, err);
        }

        if (err.response.data.code === 'CUSTOMER_PASSWORD_NOT_SET') {
          return ApiResult.error.passwordNotSet(err.response.data.detail, err);
        }
      }

      if (err.response.status === CONFLICT) {
        if (CUSTOMER_ENDPOINT_REGEX.test(err.response.config.url)) {
          return ApiResult.error.emailConflict(err.error, err);
        }

        return ApiResult.error.conflict(err.response.data.message, err);
      }

      // If no validation error, or we haven't accounted for it, mark as client error.
      return ApiResult.error.client(err.response.data.message, err);
    }
  }

  // Stripe error handler
  if (err?.code) {
    switch (err.code) {
      case STRIPE_ERROR_CODE.INCOMPLETE_CVC:
      case STRIPE_ERROR_CODE.INVALID_CVC:
      case STRIPE_ERROR_CODE.INCORRECT_CVC:
      case STRIPE_ERROR_CODE.INCOMPLETE_EXPIRY:
      case STRIPE_ERROR_CODE.INVALID_EXPIRY_YEAR_PAST:
      case STRIPE_ERROR_CODE.CARD_DECLINED:
      case STRIPE_ERROR_CODE.INVALID_NUMBER:
      case STRIPE_ERROR_CODE.INCOMPLETE_NUMBER:
      case STRIPE_ERROR_CODE.INCORRECT_NUMBER:
      case STRIPE_ERROR_CODE.API_CONNECTION_ERROR:
      case STRIPE_ERROR_CODE.EXPIRED_CARD:
      case STRIPE_ERROR_CODE.PROCESSING_ERROR:
        return ApiResult.error.paymentInvalid(err.message, err);
      // no default
    }
  }

  // If axios didn't cause it, or we have an unmapped case, mark as client error.
  return ApiResult.error.client(err.message, err);
};

/**
 * @template T
 * @typedef {{ success: true; payload?: T }} ApiSuccessResult<T>
 */

/**
 * @typedef {
 *  | ServerError
 *  | ClientError
 *  | NetworkError
 *  | PaymentInvalidError
 *  | PaymentInsufficientFundsError
 *  | UnableToProcessOrderError
 *  | CheckoutAddressError
 *  | ExistingCustomerError
 *  | InvalidEmailError
 *  | AddressError
 *  | EmailConflictError
 *  | ConflictError
 *  | SkipNextDeliveryError
 *  | TooManyFailedError
 *  } ApiError
 * @typedef {{success: false; error: ApiError; }} ApiFailureResult
 */

/**
 * @template M
 * @template R
 * @typedef {Object} Matchers<M, R>
 * @property {(n: M) => R} [success]
 * @property {{ [K in ApiError['type']]?: (e: ApiError) => R}} [error]
 */

/**
 * @template T
 * @typedef {ApiSuccessResult<T> | ApiFailureResult} ApiResult<T>
 */
export const ApiResult = {
  /**
   * @template T
   * @param {T} [payload]
   * @returns {ApiSuccessResult<T>}
   */
  success(payload) {
    return { success: true, payload };
  },

  error: {
    /**
     * @param {string} message
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    server(message, err) {
      return { success: false, error: new ServerError(message, err) };
    },

    /**
     * @param {string} message
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    client(message, err) {
      return { success: false, error: new ClientError(message, err) };
    },

    /**
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    network(err) {
      return { success: false, error: new NetworkError(err) };
    },

    /**
     * @param {string} message
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    paymentInvalid(message, err) {
      return { success: false, error: new PaymentInvalidError(message, err) };
    },

    /**
     * @param {string} message
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    paymentInsufficientFunds(message, err) {
      return {
        success: false,
        error: new PaymentInsufficientFundsError(message, err),
      };
    },

    /**
     * @param {string} message
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    unableToProcessOrder(message, err) {
      return {
        success: false,
        error: new UnableToProcessOrderError(message, err),
      };
    },

    /**
     * @param {string} message
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    passwordNotSet(message, err) {
      return {
        success: false,
        error: new PasswordNotSetError(message, err),
      };
    },

    /**
     * @param {string} message
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    checkoutAddress(message, err) {
      return {
        success: false,
        error: new CheckoutAddressError(message, err),
      };
    },

    /**
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    existingCustomer(err) {
      return { success: false, error: new ExistingCustomerError(err) };
    },

    /**
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    invalidEmail(err) {
      return {
        success: false,
        error: new InvalidEmailError(err),
      };
    },

    /**
     * @param {string} message
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    emailConflict(message, err) {
      return { success: false, error: new EmailConflictError(message, err) };
    },

    /**
     * @param {string} message
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    conflict(message, err) {
      return { success: false, error: new ConflictError(message, err) };
    },

    /**
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    address(err) {
      return { success: false, error: new AddressError(err) };
    },
    /**
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    skipNextDeliveryErr(err) {
      return {
        success: false,
        error: new SkipNextDeliveryError(err),
      };
    },

    /**
     * @param {string} message
     * @param {Error} err
     * @returns {ApiFailureResult}
     */
    tooManyFailedLogin(message, err) {
      return { success: false, error: new TooManyFailedError(message, err) };
    },
  },

  callAsync: async func => {
    try {
      return ApiResult.success(await func());
    } catch (error) {
      return wrapErrorWithResult(error);
    }
  },

  /**
   * @template T
   * @template V
   * @param {ApiResult<T>} result
   * @param {(val: T) => Promise<ApiResult<V>>} pred
   * @returns {Promise<ApiResult<V>>}
   */
  async flatMapAsync(result, pred) {
    if (!result.success) {
      return /** @type {ApiFailureResult} */ (result);
    }

    try {
      return await pred(result.payload);
    } catch (err) {
      return wrapErrorWithResult(err);
    }
  },

  /**
   * Performs a match against the provided results. If the result is in a success state,
   * calls the matcher at `success`. Otherwise, calls the matcher at `error[type]`, where
   * `type` is the error type matched against. If a result is passed that doesn't have
   * a matcher, throw an error.
   *
   * @template T
   * @template R
   * @param {ApiResult<T>} result
   * @param {Matchers<T, R>} matchers
   * @returns R
   */
  match(result, matchers) {
    // If we get an ApiResult, fall through never hits
    // eslint-disable-next-line default-case
    switch (result.success) {
      case false:
        return matchers.error?.[result.error.type]?.(result.error);
      case true:
        return matchers.success?.(result.payload);
    }
  },
  /**
   * Performs a check on the ApiResult to check for errors. If the result is is not a success state,
   * performs a call to the callback parameter.
   *
   * @template T
   * @param {ApiResult<T>} result
   * @param {(error: ApiError) => void} callback
   */
  ifError(result, callback) {
    if (!result.success) {
      callback(result.error);
    }
  },
};
/**
 * Intentionally not exported. Do not export.
 * This is not the `instanceof` you're looking for.
 * `switch` on `type` instead.
 */
class ServerError {
  /** @readonly */
  type = 'server';

  /**
   * @param {string} message
   * @param {Error} original
   */
  constructor(message, original) {
    this.message = message;
    this.original = original;
  }
}

class ClientError {
  /** @readonly */
  type = 'client';

  /**
   * @param {string} message
   * @param {Error} original
   */
  constructor(message, original) {
    this.message = message;
    this.original = original;
  }
}

class NetworkError {
  /** @readonly */
  type = 'network';

  /**
   * @param {Error} original
   */
  constructor(original) {
    this.original = original;
  }
}

class PaymentInvalidError {
  /** @readonly */
  type = 'paymentInvalid';

  /**
   * @param {string} message
   * @param {Error} original
   */
  constructor(message, original) {
    this.message = message;
    this.original = original;
  }
}

class PaymentInsufficientFundsError {
  /** @readonly */
  type = 'paymentInsufficientFunds';

  /**
   * @param {string} message
   * @param {Error} original
   */
  constructor(message, original) {
    this.message = message;
    this.original = original;
  }
}

class UnableToProcessOrderError {
  /** @readonly */
  type = 'unableToProcessOrder';

  /**
   * @param {string} message
   * @param {Error} original
   */
  constructor(message, original) {
    this.message = message;
    this.original = original;
  }
}

class PasswordNotSetError {
  /** @readonly */
  type = 'passwordNotSet';

  /**
   * @param {string} message
   * @param {Error} original
   */
  constructor(message, original) {
    this.message = message;
    this.original = original;
  }
}

class CheckoutAddressError {
  /** @readonly */
  type = 'checkoutAddress';

  /**
   * @param {string} message
   * @param {Error} original
   */
  constructor(message, original) {
    this.message = message;
    this.original = original;
  }
}

class ExistingCustomerError {
  /** @readonly */
  type = 'existingCustomer';

  /**
   * @param {Error} original
   */
  constructor(original) {
    this.original = original;
  }
}

class InvalidEmailError {
  /** @readonly */
  type = 'invalidEmail';

  /**
   * @param {Error} original
   */
  constructor(original) {
    this.original = original;
  }
}

class EmailConflictError {
  /** @readonly */
  type = 'emailConflict';

  /**
   * @param {string} message
   * @param {Error} original
   */
  constructor(message, original) {
    this.message = message;
    this.original = original;
  }
}

class ConflictError {
  /** @readonly */
  type = 'conflict';

  /**
   * @param {string} message
   * @param {Error} original
   */
  constructor(message, original) {
    this.message = message;
    this.original = original;
  }
}

class AddressError {
  /** @readonly */
  type = 'address';

  /**
   * @param {string} message
   * @param {Error} original
   */
  constructor(original) {
    this.original = original;
    const error = original.response.data;
    this.nonFieldErrors = error.non_field_errors?.join('');
    this.fields = Object.keys(error)
      .filter(key => key !== 'non_field_errors')
      .map(key => ({
        name: ADDRESS_ERROR_MAP[key],
        message: error[key].join(''),
      }));
  }
}

class SkipNextDeliveryError {
  /** @readonly */
  type = 'skipNextDeliveryErr';

  /**
   * @param {Error} original
   */
  constructor(original) {
    this.original = original;
  }
}

class TooManyFailedError {
  /** @readonly */
  type = 'tooManyFailedLogin';

  /**
   * @param {string} message
   * @param {Error} original
   */
  constructor(message, original) {
    this.message = message;
    this.original = original;
  }
}
