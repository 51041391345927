'use client';
import { useCallback } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import { ApiResult, useClient, useService, useSession } from '../api';
import { useProductsList } from '../products';
import { SUBSCRIPTIONS_URL } from '../subscriptions/constants';
import {
  CUSTOMER_ENDPOINT,
  CUSTOMER_STATUS_ACTIVE,
  CUSTOMER_STATUS_LEAD,
  CUSTOMER_SMS_UPDATES_PARAM,
  ADDON_PRODUCT_TYPES_LIST,
  ANONYMOUS_USER,
} from './constants';
import { CustomerService } from './service';

export { useAddress } from './useAddress';
export { formToModel, modelToForm } from './functions';

export const useSessionCustomer = (includeSMS = false) => {
  const { data: session, status, isAppSession } = useSession();

  const isSessionLoading = status === 'loading';
  const { isLoading: isCustomerLoading, ...customerApi } = useCustomer(
    session?.user?.id,
    includeSMS,
  );

  return {
    ...customerApi,
    isLoading: isSessionLoading || isCustomerLoading,
    isAuthenticated: status === 'authenticated',
    isAppSession,
  };
};

export function useCustomer(customerId, includeSMS = false) {
  const service = useService(CustomerService);
  const { mutate: globalMutate } = useSWRConfig();
  const is_sms = includeSMS ? CUSTOMER_SMS_UPDATES_PARAM : '';
  const { data, error, mutate } = useSWR(
    customerId ? `${CUSTOMER_ENDPOINT}${customerId}/${is_sms}` : null,
  );

  const updateCustomer = useCallback(
    async customer => {
      const response = await service.updateCustomer(data.id, customer);
      mutate(response.data, false);
      return response;
    },
    [data?.id, mutate, service],
  );

  // we needed the ability to update a customer immediately after
  // registering them during the free weight screening acquisition
  const updateCustomerWithId = useCallback(
    async (customerId, customer) => {
      const response = await service.updateCustomer(customerId, customer);
      mutate(response.data, false);
      return response;
    },
    [mutate, service],
  );

  const getCustomer = async customerId => {
    const response = await service.getCustomer(customerId);
    globalMutate(`${CUSTOMER_ENDPOINT}${customerId}/`, response.data, false);
    return response;
  };

  const updatePassword = useCallback(
    password => ApiResult.callAsync(() => service.updatePassword(password)),
    [service],
  );

  const createPassword = useCallback(
    password => ApiResult.callAsync(() => service.createPassword(password)),
    [service],
  );

  const forgotPassword = useCallback(
    (email, captchaToken) =>
      ApiResult.callAsync(() => service.forgotPassword(email, captchaToken)),
    [service],
  );

  const validateNewPasswordRequestToken = useCallback(
    (customer_id, token) =>
      ApiResult.callAsync(() =>
        service.validateNewPasswordRequestToken(customer_id, token),
      ),
    [service],
  );

  const recoverForgottenPassword = useCallback(
    (customerId, { new_password, confirm_password, token }) =>
      ApiResult.callAsync(() =>
        service.recoverForgottenPassword(customerId, {
          new_password,
          confirm_password,
          token,
        }),
      ),
    [service],
  );
  // Transaction ids are the stripe client secrets, for us its the `public_id` value
  // on the payment intent object
  const performCheckout = useCallback(
    (transactionIds, campaignId) =>
      ApiResult.callAsync(async () => {
        const response = await service.performCheckout(
          customerId,
          transactionIds,
          campaignId,
        );
        mutate({ ...data, status: CUSTOMER_STATUS_ACTIVE }, true);
        globalMutate(SUBSCRIPTIONS_URL, null, true);
        return response;
      }),
    [service, customerId, mutate, data, globalMutate],
  );

  // User registration requires a first name but Product/Design didn't want
  // to ask for it for non-member screening customers. 'Acquisition' is a
  // placeholder first name for all of these customers but we don't want
  // this to appear in the UI, so we remove it here.
  const firstName = data?.first_name?.toLowerCase();
  if (
    (data && !firstName) ||
    firstName === 'pup parent' ||
    firstName === 'acquisition'
  ) {
    data.first_name = '';
  }

  return {
    customer: customerId && data ? data : ANONYMOUS_USER,
    error,
    isLoading: customerId ? !data && !error : false,
    isLead: data?.status === CUSTOMER_STATUS_LEAD,
    isActive: data?.status === CUSTOMER_STATUS_ACTIVE,
    updateCustomer,
    updateCustomerWithId,
    getCustomer,
    updatePassword,
    createPassword,
    forgotPassword,
    validateNewPasswordRequestToken,
    recoverForgottenPassword,
    performCheckout,
  };
}

export const useCart = () => {
  const { data: session, status } = useSession();
  const client = useClient();
  const isSessionLoading = status === 'loading';
  const userId = session?.user?.id;
  const { data, error, mutate, isValidating } = useSWR(
    userId ? `${CUSTOMER_ENDPOINT}${userId}/cart/` : null,
  );

  const addAddonsToCart = useCallback(
    ({ pet_id, addons }) =>
      ApiResult.callAsync(async () => {
        const response = await client.put(
          `${CUSTOMER_ENDPOINT}${userId}/cart/`,
          {
            line_items: addons.map(addon => ({
              sku: addon.sku,
              quantity: addon.quantity,
              frequency: addon.frequency,
              recurrence: addon.recurrence,
              pet_id,
            })),
            subscriptions: {
              [pet_id]: {
                pet_id,
                addons,
              },
            },
          },
        );
        mutate(response.data, false);
        return response;
      }),
    [client, userId, mutate],
  );

  const addMealPlanToCart = useCallback(
    ({ product_sku, subscription_sku, quantity = 1, pet_id, addons = [] }) =>
      ApiResult.callAsync(async () => {
        const response = await client.put(
          `${CUSTOMER_ENDPOINT}${userId}/cart/`,
          {
            line_items: [
              {
                sku: product_sku,
                quantity,
                pet_id,
              },
            ],
            subscriptions: {
              [pet_id]: {
                sku: subscription_sku,
                pet_id,
                addons,
              },
            },
          },
        );
        mutate(response.data, false);
        return response;
      }),
    [client, userId, mutate],
  );

  const removeFromCart = useCallback(
    ({ petId, addons }) =>
      ApiResult.callAsync(async () => {
        const response = await client.delete(
          `${CUSTOMER_ENDPOINT}${userId}/cart/`,
          {
            data: {
              ...(petId && { pet_ids: [petId] }),
              ...(addons && { line_items: addons }),
            },
          },
        );
        mutate(response.data, false);
        return response;
      }),
    [client, mutate, userId],
  );

  const refreshCart = useCallback(
    ({ resyncTaxes = false } = {}) =>
      ApiResult.callAsync(async () => {
        if (!resyncTaxes) {
          return mutate();
        }

        const response = await client.get(
          `${CUSTOMER_ENDPOINT}${userId}/cart/?resync_taxes=true`,
        );
        return mutate(response.data, false);
      }),
    [client, userId, mutate],
  );

  const updateOfferCode = useCallback(
    offerCode =>
      ApiResult.callAsync(async () => {
        const response = await client.put(
          `${CUSTOMER_ENDPOINT}${userId}/cart/`,
          {
            coupon_code: offerCode,
          },
        );
        await mutate(response.data, false);
        return response;
      }),
    [client, userId, mutate],
  );

  return {
    cart: data,
    isLoading: userId ? !error && !data : isSessionLoading,
    error,
    refreshCart,
    isValidating,
    addMealPlanToCart,
    addAddonsToCart,
    removeFromCart,
    updateOfferCode,
  };
};

export const useCartLineItemForPet = petId => {
  const cartApi = useCart();
  const isAddon = productType => ADDON_PRODUCT_TYPES_LIST.includes(productType);
  const lineItemForPet = cartApi.cart?.line_items.find(
    sub => sub.pet_id === petId && !isAddon(sub.product_type),
  );
  const addonsForPet = cartApi.cart?.line_items
    .filter(sub => sub.pet_id === petId && isAddon(sub.product_type))
    .map(addon => ({
      ...addon,
      ...cartApi.cart.subscriptions[petId].addons.find(
        subAddon => addon.sku === subAddon.sku,
      ),
    }));

  return {
    ...cartApi,
    lineItemForPet,
    addonsForPet,
  };
};

export const useCartProducts = () => {
  const cartApi = useCart();
  const productsListApi = useProductsList(
    cartApi.cart?.line_items.map(item => item.sku).sort(),
  );

  return {
    cartApi,
    productsListApi,
  };
};

export const useCartSubscriptionProducts = () => {
  const cartApi = useCart();
  const subscriptionProductsListApi = useProductsList(
    Object.values(cartApi.cart?.subscriptions ?? {})
      .map(item => item.sku)
      .sort(),
  );

  return {
    cartApi,
    subscriptionProductsListApi,
  };
};
