import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { theme } from '@ui/theme';

const getColor = color => {
  try {
    const fillColor = theme?.colors?.get(color);
    return fillColor;
  } catch {
    const fillColor = color;
    return fillColor;
  }
};

export const SVGIcon = ({
  icon,
  height,
  width,
  alt,
  fillColor = 'text.secondary',
  strokeColor = 'text.primary',
  strokeWidth = '1.5',
  fillRule = 'evenodd',
  viewBox = '0 0 24 24',
}) => {
  const [svgPaths, setSvgPaths] = useState([]);
  useEffect(() => {
    const fetchSvgPath = async () => {
      try {
        const response = await fetch(icon?.src);
        const svg = await response?.text?.();
        //get all paths
        const regex = /<path[^>]*>/g;
        const allSvgPaths = svg?.match(regex);

        //create an array of objects so we can get stroke or fill
        const pathsData = allSvgPaths?.map(path => {
          const pathUrl = path.match(/d="([^"]+)"/);
          const pathStroke = path.match(/stroke="([^"]+)"/);
          const pathFill = path.match(/fill="([^"]+)"/);
          return {
            d: pathUrl ? pathUrl[1] : null,
            stroke: pathStroke ? pathStroke[1] : null,
            fill: pathFill ? pathFill[1] : null,
          };
        });
        setSvgPaths(pathsData);
      } catch (err) {
        setSvgPaths([]);
      }
    };
    if (icon) {
      fetchSvgPath();
    }
  }, [icon]);
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      viewBox={viewBox}
    >
      {alt && <title>{alt}</title>}
      {svgPaths?.map((svgPath, index) => (
        <path
          key={index}
          d={svgPath.d}
          fill={svgPath.fill && getColor(fillColor)}
          stroke={svgPath.stroke && getColor(strokeColor)}
          stroke-width={strokeWidth}
          fill-rule={fillRule}
        />
      ))}
    </svg>
  );
};

SVGIcon.displayName = 'SVGIcon';

SVGIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  strokeWidth: PropTypes.string,
  fillRule: PropTypes.string,
  viewBox: PropTypes.string,
};
