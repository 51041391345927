'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { SVGIcon } from '@web/atoms';
import { useReporter } from '../../reporter';
import {
  StyledLink,
  LinkIconContainer,
  FooterColumnLink,
  StyledBlurb,
} from './styles';
import { footerLinkClicked } from './events';

export const BottomLink = ({ href, text, index }) => {
  const reporter = useReporter();
  const trackEvent = footerLinkClicked(text, href, 'Bottom', index);
  return (
    <StyledLink href={href} onClick={() => reporter.tag(trackEvent)}>
      {text}
    </StyledLink>
  );
};

export const FooterLink = ({ href, text, icon, index, columnTitle, blurb }) => {
  const reporter = useReporter();
  const trackEvent = footerLinkClicked(text, href, columnTitle, index);
  return (
    <FooterColumnLink icon={!!icon}>
      {icon && (
        <LinkIconContainer>
          <SVGIcon
            icon={icon}
            alt={icon.alt}
            height={'17px'}
            width={'19px'}
            viewBox={'0 0 22 24'}
            fillColor={'#acacbb'}
            strokeColor={'#acacbb'}
          />
        </LinkIconContainer>
      )}
      <StyledLink href={href} onClick={() => reporter.tag(trackEvent)}>
        {text}
      </StyledLink>
      {blurb && <StyledBlurb> {blurb}</StyledBlurb>}
    </FooterColumnLink>
  );
};

FooterLink.displayName = 'FooterLink';

FooterLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
  }),
  index: PropTypes.number.isRequired,
  columnTitle: PropTypes.string.isRequired,
  blurb: PropTypes.string,
};

BottomLink.displayName = 'BottomLink';

BottomLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};
