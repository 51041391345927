'use client';
import styled from 'styled-components';

export const StyledPillText = styled.p`
  ${({ theme, size }) => theme.typography[size === 'small' ? 'label' : 'body']}
  ${({ theme }) => theme.fontFamily.condensed}
  ${props => props.theme.getContrastColors(props.variant)}
  border-radius: 500px;
  padding: ${({ theme, size }) =>
    size === 'small' ? theme.spacing(3, 2, 3, 2) : theme.spacing(0.6, 4, 1, 4)};
  height: ${({ size }) => (size === 'small' ? '16px' : '30px')};
  text-transform: uppercase;
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;
