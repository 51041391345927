import { BaseService } from '../api';
import { PRODUCTS_URL, RECOMMENDATIONS_URL } from './constants';

export const getRecommendationsUrl = ({
  petId = '',
  weight = '',
  activity = '',
  neutered = '',
  fullProducts = '',
  allergies = [],
  couponCode = '',
  recommendationVersion,
  portionSize = '',
}) => {
  const params = petId
    ? new URLSearchParams({
        pet_id: petId,
        full_products: fullProducts,
        coupon_code: couponCode,
        ...(portionSize !== '' && { portion_size: portionSize }),
      })
    : new URLSearchParams({
        activity_level: activity,
        is_neutered: neutered,
        allergies: allergies.join(','),
        full_products: fullProducts,
        weight,
        coupon_code: couponCode,
        ...(portionSize !== '' && { portion_size: portionSize }),
      });

  // Can't send an empty string or BE 400s
  if (recommendationVersion != null) {
    params.set('recommendation_version', recommendationVersion);
  }

  return `${RECOMMENDATIONS_URL}?${params.toString()}`;
};

export class ProductService extends BaseService {
  view = sku => this.client.get(`${PRODUCTS_URL}${sku}/`);
}
