import { useCallback, useState } from 'react';

export const useSessionStorage = (key, defaultValue) => {
  const [state, setState] = useState(
    // TODO(James): this can produce an initial render mismatch
    (typeof window !== 'undefined' &&
      JSON.parse(sessionStorage.getItem(key))) ||
      defaultValue,
  );

  const setSessionStorage = useCallback(
    (value, { skipRender = false } = {}) => {
      sessionStorage.setItem(key, JSON.stringify(value));
      if (!skipRender) {
        setState(value);
      }
    },
    [key],
  );

  return [state, setSessionStorage];
};
