import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@ui/button';
import { VisuallyHidden } from '@web/atoms';
import { SlideDownCard } from '../SlideDownCard';
import { SlidingContainer } from '../SlidingContainer';
import {
  Header,
  MobileHeader,
  Title,
  BackButton,
  CategoriesContainer,
  HeadingContainer,
  SlideDownContainer,
} from './styles';

export const SlideDownMenuSlider = ({
  header,
  categories,
  onCloseMenu,
  onGoBack,
  active,
}) => {
  return (
    <SlidingContainer
      active={active}
      onExit={onCloseMenu}
      $bgColor="background.base"
      direction="slideDown"
    >
      <SlideDownContainer>
        <HeadingContainer>
          <Header>
            <Title>{header}</Title>
          </Header>
          <MobileHeader>
            <BackButton>
              <IconButton
                ariaLabel="CloseIcon"
                icon={{
                  iconName: 'arrow-left',
                  alt: 'back arrow',
                  size: '20px',
                }}
                onClick={onGoBack}
                variant="spinach"
                id="slide_down_mobile_close"
              />
              <VisuallyHidden>Close</VisuallyHidden>
            </BackButton>
            <Title>{header}</Title>
          </MobileHeader>
        </HeadingContainer>
        <CategoriesContainer>
          {categories.map((category, index) => {
            return (
              <SlideDownCard {...category} key={index} onClick={onCloseMenu} />
            );
          })}
        </CategoriesContainer>
      </SlideDownContainer>
    </SlidingContainer>
  );
};

SlideDownMenuSlider.displayName = 'Slide Down Menu Slider';

SlideDownMenuSlider.propTypes = {
  header: PropTypes.string.isRequired,
  categories: PropTypes.array,
  onCloseMenu: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};
