'use client';
import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  background: ${props => props.theme.colors.background.bone};
  border-radius: 32px;
  padding: ${props => props.theme.spacing(6)};
  max-width: 420px;
  width: 100%;
`;

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & > a {
    ${props => props.theme.typography.label};
  }
`;

export const Title = styled.h4`
  ${props => props.theme.typography.heading4};
`;

export const ContainerBody = styled.div`
  ${props => props.theme.typography.body};
`;
