'use client';
import styled from 'styled-components';

export const StyledAddOnCardContainer = styled.div`
  background-color: ${props => props.theme.colors.background.bone};
  border-radius: ${props => props.theme.spacing(8)};
  width: 100%;
  @media ${props => props.theme.tablet} {
    max-width: ${props => props.theme.spacing(50)};
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 400px;
  }
`;

export const StyledImageContainer = styled.div`
  display: none;
  @media ${props => props.theme.tablet} {
    height: 35%;
    display: block;
  }
  & img {
    border-radius: ${props => props.theme.spacing(8, 8, 0, 0)};
  }
`;

export const StyledCardContent = styled.div`
  display: none;
  @media ${props => props.theme.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: ${props => props.theme.spacing(3)};
    padding: ${props => props.theme.spacing(2, 6)};
    margin-bottom: auto;
  }
`;

export const StyledContentButton = styled.div`
  margin-bottom: ${props => props.theme.spacing(4)};
  padding: ${props => props.theme.spacing(2, 6)};
  display: none;
  @media ${props => props.theme.tablet} {
    display: block;
  }
`;

export const StyledTitle = styled.h3`
  ${props => props.theme.typography.heading4}
  text-align: left;
  margin-bottom: ${props => props.theme.spacing(2)};
`;

export const StyledAddOnCardDescription = styled.p`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.neutral.darkerFur};
  text-align: left;
`;

export const MobileCardContent = styled.div`
  width: 100%;
  @media ${props => props.theme.tablet} {
    display: none;
  }
`;

export const MobileImageContainer = styled.div`
  width: 100%;
  padding: ${props => props.theme.spacing(0, 6, 6)};
  @media ${props => props.theme.tablet} {
    display: none;
  }
  & img {
    border-radius: ${props => props.theme.spacing(8)};
  }
`;
