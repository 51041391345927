import React from 'react';
import PropTypes from 'prop-types';
import { VisuallyHidden } from '@web/atoms';
import { ResponsiveImage } from '../../ResponsiveImage';
import { IconImgContainer, StyledLink } from './styles';

export const BackArrow = ({ onClick, backTarget, src, alt }) => {
  return (
    /* eslint-disable-next-line */
    <StyledLink href={backTarget} onClick={onClick}>
      <IconImgContainer>
        <ResponsiveImage src={src} alt={alt} width={16} height={16} />
      </IconImgContainer>
      <VisuallyHidden>{alt}</VisuallyHidden>
    </StyledLink>
  );
};

BackArrow.displayName = 'BackArrow';

BackArrow.propTypes = {
  onClick: PropTypes.func,
  backTarget: PropTypes.string.isRequired,
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string.isRequired,
};
