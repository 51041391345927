import { costFormatter, percentFormatter } from '@format/currency';
import {
  DOLLAR_AMOUNT_TYPE,
  PERCENT_AMOUNT_TYPE,
  CATEGORY_PERCENT_AMOUNT_TYPE,
} from './constants';

export const getFormattedOfferAmountByType = offer => {
  if (!offer?.amount || !offer?.type) return '';

  const isDollarAmount = offer.type === DOLLAR_AMOUNT_TYPE;
  const isPercentAmount = offer.type === PERCENT_AMOUNT_TYPE;
  const isCategoryPercentAmount = offer.type === CATEGORY_PERCENT_AMOUNT_TYPE;

  if (isDollarAmount) return costFormatter(offer.amount);
  if (isPercentAmount) return percentFormatter(offer.amount);
  if (isCategoryPercentAmount) return percentFormatter(offer.amount);

  throw new Error(
    `Unknown Offer type: "${offer.type}"; Must be one of [${DOLLAR_AMOUNT_TYPE}, ${PERCENT_AMOUNT_TYPE}, ${CATEGORY_PERCENT_AMOUNT_TYPE}]`,
  );
};
