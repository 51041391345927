export const CUSTOMER_ENDPOINT = '/api/v3/customers/';
export const UPDATE_PASSWORD_ENDPOINT = `/api/v3/auth/update-password/`;
export const CREATE_PASSWORD_ENDPOINT = `/api/v3/auth/create-password/`;
export const FORGOT_PASSWORD_ENDPOINT = `/api/v3/auth/password-recovery/request/`;
export const VALIDATE_REQUEST_PASSWORD_TOKEN_ENDPOINT = customerId =>
  `/api/v3/auth/password-recovery/${customerId}/validate/`;
export const RECOVER_FORGOTTEN_PASSWORD_ENDPOINT = customerId =>
  `/api/v3/auth/password-recovery/${customerId}/recover/`;
export const CUSTOMER_STATUS_ACTIVE = 'active';
export const CUSTOMER_STATUS_LEAD = 'lead';
export const CUSTOMER_SMS_UPDATES_PARAM = '?include=is_sms';
export const ADDRESS_ENDPOINT = customerId =>
  `/api/v3/customers/${customerId}/address/`;
export const CHECKOUT_URL = (customerId, campaignId = null) =>
  `/api/v3/customers/${customerId}/checkout/${
    campaignId ? `?campaign_id=${campaignId}` : ''
  }`;
export const ADDON_PRODUCT_TYPES = {
  treats: 'TR',
  supplements: 'SP',
};
export const ADDON_PRODUCT_TYPES_LIST = Object.values(ADDON_PRODUCT_TYPES);

export const ANONYMOUS_USER = {
  id: null,
  first_name: '',
  last_name: '',
  email: '',
};
