'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  border: none;
  color: ${({ theme, variant }) => theme.colors.get(variant)};
  outline: none;
  font-size: 1rem;
  background: none;
  padding: 0;
  /* button reset styles */
  margin: 0;
  display: inline;
  text-align: left;
  width: auto;
  overflow: visible;
  vertical-align: baseline;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* stylelint-disable-next-line */
  -webkit-appearance: none;
  /* end button reset styles */
  ${props => props.theme.typography.button}
  font-weight: ${props => (props.bold ? 'bold' : 'unset')};
  text-decoration: ${props => (props.underline ? 'underline ' : 'none')};
  text-underline-offset: ${({ theme, underline }) =>
    underline ? theme.spacing(1) : 'unset'};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &:focus {
    text-decoration: none;
    outline: 1px solid ${props => props.theme.colors.border.base};
    border-radius: 2px;
  }
  &:visited {
    text-decoration: underline;
  }
`;

export const RichLink = styled(Link)`
  border: none;
  color: inherit;
  outline: none;
  background: none;
  padding: 0;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'unset')};
  text-decoration: ${({ underline }) => (underline ? 'underline ' : 'none')};
  text-underline-offset: ${({ theme, underline }) =>
    underline ? theme.spacing(1) : 'unset'};
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &:focus {
    text-decoration: none;
    outline: 1px solid ${({ theme }) => theme.colors.border.base};
    border-radius: 2px;
  }
  &:visited {
    text-decoration: underline;
  }
`;
