export const createClientVisitor = (
  visitorId = null,
  authenticated,
  deviceType,
  searchParams = '',
) => {
  if (visitorId) visitorId += '';
  return {
    id: visitorId,
    context: {
      deviceType,
      queryParameters: searchParams,
    },
    isAuthenticated: authenticated,
  };
};
