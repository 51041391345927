import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { useReporter } from '../../reporter';
import { addPetClicked } from './events';

export const AddPupButton = ({
  href,
  fullWidth = false,
  variant,
  verticalPadding,
  horizontalPadding,
  text,
}) => {
  const reporter = useReporter();
  const addIcon = {
    size: '18px',
    iconName: 'plus',
    alt: 'add icon',
    color: 'hero.blueberry',
    position: 'end',
  };
  const onClickAdd = () => {
    reporter.tag(addPetClicked());
  };
  return (
    <LinkButton
      href={href}
      icon={addIcon}
      fullWidth={fullWidth}
      onClick={onClickAdd}
      variant={variant}
      verticalPadding={verticalPadding}
      horizontalPadding={horizontalPadding}
    >
      {text}
    </LinkButton>
  );
};

AddPupButton.displayName = 'AddPupButton';
AddPupButton.propTypes = {
  href: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string.isRequired,
  verticalPadding: PropTypes.number.isRequired,
  horizontalPadding: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};
