'use client';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: ${props => (props.$forward ? 'auto' : 'none')};
  z-index: ${props => (props.$forward ? '100' : '-100')};

  @media ${({ theme }) => theme.tablet} {
    top: ${props =>
      props.$direction === 'slideDown' && `${props.$headerHeight}px`};
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: ${props =>
    props.$direction === 'slideDown' ? `${props.$headerHeight}px` : 0};
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 0.3s ease-in;
  background-color: black;
  opacity: ${props => (props.$active ? '0.3' : '0')};
`;

export const StyledSlidingContainer = styled.div`
  ${props => props.theme.scrollBar}

  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${({ theme, $bgColor }) => theme.colors.get($bgColor)};

  position: fixed;
  ${({ $direction, $alignment, $maxWidth }) =>
    $direction === 'slideDown'
      ? `${$alignment}: 0`
      : `${$alignment}: -${$maxWidth}px`};

  max-width: ${props =>
    props.$direction === 'slideDown' ? 'none' : `${props.$maxWidth}px`};
  width: 100%;
  overflow-x: hidden;
  transition: transform ease-in-out 250ms;

  transform: ${({
    $direction,
    $maxWidth,
    $active,
    $alignment,
    $headerHeight,
  }) => {
    switch ($direction) {
      case 'slideOut':
        return $active
          ? $alignment === 'left'
            ? `translateX(${$maxWidth}px)`
            : `translateX(-${$maxWidth}px)`
          : 'translateX(0)';
      case 'slideDown':
        return $active
          ? 'translateY(0)'
          : `translateY(calc(-50vh - ${$headerHeight}px))`;
      default:
        return 'translateX(0)';
    }
  }};

  @media ${({ theme }) => theme.mobileS} {
    position: relative;
    height: 100vh;
    transform: ${({ $active }) => !$active && 'translateX(0)'};
    max-width: none;
    top: ${({ $direction }) => $direction === 'slideDown' && 0}px;
    padding: ${({ theme }) => theme.spacing(0, 6)};
  }

  @media ${({ theme }) => theme.tablet} {
    height: ${({ $direction }) =>
      $direction === 'slideDown' ? '80vh' : '100vh'};
    padding: ${({ theme }) => theme.spacing(6)};
    max-width: ${props =>
      props.$direction === 'slideDown' ? 'none' : `${props.$maxWidth}px`};
    transform: ${({ $active, $direction, $headerHeight }) =>
      !$active &&
      $direction === 'slideDown' &&
      `translateY(calc(-80vh - ${$headerHeight}px))`};
  }

  @media ${({ theme }) => theme.laptop} {
    min-height: ${({ $direction }) =>
      $direction === 'slideDown' ? 'fit-content' : '100vh'};
    transform: ${({ $active, $direction, $headerHeight }) =>
      !$active &&
      $direction === 'slideDown' &&
      `translateY(calc(-50vh - ${$headerHeight}px))`};
    height: ${({ $active }) => ($active ? '100%' : 'auto')};
    overflow-y: scroll;
  }
  @media ${({ theme }) => theme.laptopL} {
    min-height: ${({ $direction }) =>
      $direction === 'slideDown' ? 'fit-content' : '100vh'};
    transform: ${({ $active, $direction, $headerHeight }) =>
      !$active &&
      $direction === 'slideDown' &&
      `translateY(calc(-50vh - ${$headerHeight}px))`};
    height: auto;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spacing(18)};
`;

export const CloseIcon = styled.button`
  background: none;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  &:focus {
    outline: 1px solid ${props => props.theme.colors.text.primary};
  }
`;

export const OllieLogo = styled.div`
  margin: auto;
  padding-right: ${props => props.theme.spacing(5)};
`;

export const LogoImgContainer = styled.div`
  height: 100%;
  max-height: ${props => props.theme.spacing(6)};
`;
