'use client';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import {
  BaseHeader,
  DiscountBanner,
  LeftMenuLinks,
  CenterMenuLinks,
  LOGGEDIN_LINKS,
  LOGGEDOUT_LINKS,
  UserMenuLinks,
  ResponsiveImage,
} from '@web/molecules';
import { Heading } from '@web/atoms';
import { Footer } from '@web/components';
import dogsSpillFood from '../../images/mx-dogs-spill-food.jpg';
import { FOOTER_CONTENT } from '../../testing/constants';
import { useSessionCustomer } from '../../customer';
import { useReporter } from '../../reporter';
import { errorPageCopy, errorPageHeader } from './constants';
import {
  StyledErrorPage,
  Paragraph,
  ErrorWrapper,
  ErrorMessage,
  ErrorCode,
  ImageWrapper,
} from './styles.js';
import { errorPageView } from './events';

export const ErrorPage = ({ statusCode }) => {
  const { isActive } = useSessionCustomer();
  const reporter = useReporter();

  useEffect(() => {
    reporter.tag(errorPageView(statusCode));
  }, [reporter, statusCode]);

  return (
    <StyledErrorPage>
      <BaseHeader
        leftSegment={
          <LeftMenuLinks
            // TODO(James) get links from CMS
            links={isActive ? LOGGEDIN_LINKS : LOGGEDOUT_LINKS}
          />
        }
        topSegment={!isActive ? <DiscountBanner /> : null}
        centerSegment={
          <CenterMenuLinks
            links={isActive ? LOGGEDIN_LINKS : LOGGEDOUT_LINKS}
          />
        }
        rightSegment={<UserMenuLinks />}
        logoUrl="/"
        logoSrText="Ollie logo"
      />
      <ErrorWrapper>
        <ErrorMessage>
          <ErrorCode>{statusCode}</ErrorCode>
          <Heading
            headingLevel="h1"
            typography={'headingMastiff'}
            headingText={errorPageHeader[statusCode]}
            position="center"
            backgroundColor={'hero.blueberry'}
          />
          <Paragraph>{errorPageCopy[statusCode]}</Paragraph>
          {statusCode !== 503 && (
            <LinkButton
              href="/account"
              horizontalPadding={22}
              verticalPadding={4}
              variant="secondary"
            >
              Run back home
            </LinkButton>
          )}
        </ErrorMessage>
        <ImageWrapper>
          <ResponsiveImage
            src={dogsSpillFood}
            alt="Dogs spilling food"
            objectFit="cover"
            width={1280}
            height={853}
          />
        </ImageWrapper>
      </ErrorWrapper>
      <Footer {...FOOTER_CONTENT} />
    </StyledErrorPage>
  );
};

ErrorPage.displayName = 'ErrorPage';

ErrorPage.propTypes = {
  statusCode: PropTypes.oneOf([400, 404, 500, 503]).isRequired,
};
