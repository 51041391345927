import styled, { keyframes } from 'styled-components';

const pullUp = keyframes`
from {
  transform: translateY(-20px) translateX(-50%);
  display: block;
  opacity: 0;
} to {
  transform: translateY(-40px) translateX(-50%);
  opacity: 1;
}
`;

export const StyledTooltip = styled.span`
  position: relative;
  z-index: 0;
`;

export const TooltipButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  line-height: inherit;
  padding: 0;
`;

export const TooltipDialogContainer = styled.div`
  ${props => props.theme.typography.label};
  padding: ${props => props.theme.spacing(3)};
  z-index: 1;
  position: absolute;
  display: ${({ $shown }) => ($shown ? 'block' : 'none')};
  transform: translateY(${({ $shown }) => ($shown ? '-40px' : '-20px')})
    translateX(-50%);
  animation: ${pullUp} 0.2s ease-in-out;
  bottom: 0;
  width: 225px;
  left: 50%;
  background-color: #fafafa;
  border-radius: 8px;
  filter: drop-shadow(0 3px 1.5px rgba(0, -0, 0, 0.2));
  text-transform: none;
`;

export const TooltipBubble = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #fafafa;
    left: 50%;
    bottom: -18px;
    transform: translateX(-50%);
    z-index: 0;
  }
`;

export const TooltipDialog = styled.div``;
