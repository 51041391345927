'use client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import {
  StyledTooltip,
  TooltipButton,
  TooltipDialog,
  TooltipDialogContainer,
  TooltipBubble,
} from './styles.js';
import tooltipIcon from './tooltip-icon.svg';

export const Tooltip = ({
  button = (
    <ResponsiveImage
      alt="Info marker"
      src={tooltipIcon.src}
      width={tooltipIcon.width}
      height={tooltipIcon.height}
    />
  ),
  children,
  name,
}) => {
  const [shown, setShown] = useState(false);

  return (
    <StyledTooltip>
      <TooltipButton
        role="tooltip"
        onMouseOver={() => setShown(true)}
        onFocus={() => setShown(true)}
        onMouseOut={() => setShown(false)}
        onBlur={() => setShown(false)}
        aria-labelledby={name}
        type="button"
      >
        {button}
      </TooltipButton>
      <TooltipDialogContainer hidden={!shown} $shown={shown}>
        <TooltipBubble id={name}>
          <TooltipDialog>{children}</TooltipDialog>
        </TooltipBubble>
      </TooltipDialogContainer>
    </StyledTooltip>
  );
};

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  button: PropTypes.node,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};
