import { useCallback } from 'react';
import { ApiResult, useAuthenticatedSWR, useService } from '../api';
import { PaymentService } from './service';
import { PAYMENT_V3_URL } from './constants';

export const usePayments = customerId => {
  const service = useService(PaymentService);
  const { data, error, mutate } = useAuthenticatedSWR(
    customerId ? PAYMENT_V3_URL(customerId) : null,
  );

  const create = useCallback(
    paymentMethod =>
      ApiResult.callAsync(async () => {
        const response = await service.create(customerId, paymentMethod);
        mutate(response.data, false);
        return response;
      }),
    [customerId, mutate, service],
  );

  return {
    payments: data,
    isLoading: !data && !error,
    error,
    create,
  };
};
