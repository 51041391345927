import { useState, useRef, useEffect } from 'react';
import throttle from 'lodash/throttle';

export const useRefInView = (initialState = false) => {
  const [refInView, setRefInView] = useState(initialState);
  const triggerRef = useRef(null);

  useEffect(() => {
    const onViewportChange = () => {
      if (triggerRef.current) {
        const { top, bottom } = triggerRef.current.getBoundingClientRect();

        if (top >= 0 && bottom <= window.innerHeight) {
          setRefInView(true);
        } else {
          setRefInView(false);
        }
      }
    };

    const throttledOnViewportChange = throttle(onViewportChange, 100);
    onViewportChange();
    window.addEventListener('scroll', throttledOnViewportChange);
    window.addEventListener('resize', throttledOnViewportChange);
    return () => {
      window.removeEventListener('scroll', throttledOnViewportChange);
      window.removeEventListener('resize', throttledOnViewportChange);
    };
  }, []);

  return [refInView, triggerRef];
};
