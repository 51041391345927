export const PRODUCTS_URL = '/api/v3/products/';
export const RECOMMENDATIONS_URL = `/api/v3/product-recommendations/`;
export const ALL_RECIPES = ['chicken', 'beef', 'lamb', 'turkey', 'pork'];
export const ALL_PRODUCT_TYPES = ['fresh', 'dry', 'mixed', 'half_fresh'];
export const PRODUCT_TYPE_FRESH = 'FR';
export const PRODUCT_TYPE_DRY = 'DY';
export const PRODUCT_TYPE_MIXED = 'MX';
export const PRODUCT_TYPE_HALF_FRESH = 'half_fresh';
export const POST_GF_RECOMMENDATION_VERSION = '20210810';
export const productTypeReference = {
  [PRODUCT_TYPE_FRESH]: 'fresh',
  [PRODUCT_TYPE_MIXED]: 'mixed',
  [PRODUCT_TYPE_DRY]: 'dry',
  [PRODUCT_TYPE_HALF_FRESH]: 'half_fresh',
};
export const FRESH_PRODUCT_TYPES = [
  productTypeReference[PRODUCT_TYPE_FRESH],
  productTypeReference[PRODUCT_TYPE_HALF_FRESH],
];
export const DEFAULT_FRESH = {
  starterBoxes: [],
  products: [],
  availableRecipes: {
    fresh: [],
    dry: [],
  },
};
export const DEFAULT_DRY = {
  starterBoxes: [],
  products: [],
  availableRecipes: {
    fresh: [],
    dry: [],
  },
};
export const DEFAULT_MIXED = {
  starterBoxes: [],
  products: [],
  availableRecipes: {
    fresh: [],
    dry: [],
  },
};
export const DEFAULT_HALF_PORTION = {
  starterBoxes: [],
  products: [],
  availableRecipes: {
    fresh: [],
    dry: [],
  },
};

export const FULL_PORTION = '1';
export const HALF_PORTION = '0.5';

export const FREQUENCIES = {
  1: 'One',
  2: 'Two',
  3: 'Three',
  4: 'Four',
  5: 'Five',
};
