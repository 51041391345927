import getConfig from 'next/config';
import { config as validationConfig } from './validation';

// TODO -> This is just a "for now" fix, find long term solution
const { serverRuntimeConfig = {}, publicRuntimeConfig = {} } =
  getConfig() ?? {};
validationConfig();

export const server = {
  AWS_S3_REGION: serverRuntimeConfig.AWS_S3_REGION,
  AWS_S3_ENDPOINT: serverRuntimeConfig.AWS_S3_ENDPOINT,
  AWS_S3_GUIDELINES_BUCKET: serverRuntimeConfig.AWS_S3_GUIDELINES_BUCKET,
  EVENTBRITE_API_KEY: serverRuntimeConfig.EVENTBRITE_API_KEY,
  FACEBOOK_SHARE_APP_ID: serverRuntimeConfig.FACEBOOK_SHARE_APP_ID,
  FB_APP_ID: serverRuntimeConfig.FB_APP_ID,
  ITERABLE_API_KEY: serverRuntimeConfig.ITERABLE_API_KEY,
  MAINTENANCE_MODE: serverRuntimeConfig.MAINTENANCE_MODE === 'true',
  MUX_TOKEN_ID: serverRuntimeConfig.MUX_TOKEN_ID,
  MUX_TOKEN_SECRET: serverRuntimeConfig.MUX_TOKEN_SECRET,
  NEXTAUTH_JWT_ENCRYPTION_KEY: serverRuntimeConfig.NEXTAUTH_JWT_ENCRYPTION_KEY,
  NEXTAUTH_JWT_SIGNING_KEY: serverRuntimeConfig.NEXTAUTH_JWT_SIGNING_KEY,
  NEXTAUTH_SECRET: serverRuntimeConfig.NEXTAUTH_SECRET,
  REDIS_HOST: serverRuntimeConfig.REDIS_HOST,
  REDIS_PORT: serverRuntimeConfig.REDIS_PORT,
  STORYBLOK_WEBHOOK_SECRET: process.env.STORYBLOK_WEBHOOK_SECRET, // Why is this process.env instead of serverRuntimeConfig?
};

export const shared = {
  API_BASE_URL: publicRuntimeConfig.NEXT_PUBLIC_API_BASE_URL,
  AWS_WAF_CHALLENGE_INTEGRATION_URL:
    publicRuntimeConfig.AWS_WAF_CHALLENGE_INTEGRATION_URL,
  BRIGHTBACK_APP_ID: publicRuntimeConfig.BRIGHTBACK_APP_ID,
  COMMIT_SHA: publicRuntimeConfig.COMMIT_SHA,
  DATADOG_RUM_APPLICATION_ID: publicRuntimeConfig.DATADOG_RUM_APPLICATION_ID,
  DATADOG_RUM_CLIENT_TOKEN: publicRuntimeConfig.DATADOG_RUM_CLIENT_TOKEN,
  DATADOG_RUM_ENABLED_AND_LIST_PAGES:
    publicRuntimeConfig.DATADOG_RUM_ENABLED_AND_LIST_PAGES,
  DATADOG_RUM_SESSION_RECORDING:
    publicRuntimeConfig.DATADOG_RUM_SESSION_RECORDING === 'true',
  DEFAULT_OFFER_CODE: publicRuntimeConfig.DEFAULT_OFFER_CODE,
  DEFAULT_REACTIVATION_OFFER_CODE: publicRuntimeConfig.DEFAULT_REACTIVATION_OFFER_CODE, // prettier-ignore
  DIG_CONNECTION_ID: publicRuntimeConfig.DIG_CONNECTION_ID,
  DIG_TOKEN: publicRuntimeConfig.DIG_TOKEN,
  DIG_URL: publicRuntimeConfig.DIG_URL,
  DOMAIN_PUBLIC_NAME: publicRuntimeConfig.DOMAIN_PUBLIC_NAME,
  EXTOLE_CAMPAIGN_LABEL: publicRuntimeConfig.EXTOLE_CAMPAIGN_LABEL,
  FLAGSHIP_API_KEY: publicRuntimeConfig.FLAGSHIP_API_KEY,
  FLAGSHIP_ENV_ID: publicRuntimeConfig.FLAGSHIP_ENV_ID,
  GLADLY_API_URL: publicRuntimeConfig.GLADLY_API_URL,
  GLADLY_BRAND_ID: publicRuntimeConfig.GLADLY_BRAND_ID,
  GLADLY_CDN: publicRuntimeConfig.GLADLY_CDN,
  GLADLY_ORG_ID: publicRuntimeConfig.GLADLY_ORG_ID,
  GLADLY_PUBLISHABLE_KEY: publicRuntimeConfig.GLADLY_PUBLISHABLE_KEY,
  GOOGLE_RECAPTCHA_SITE_KEY: publicRuntimeConfig.GOOGLE_RECAPTCHA_SITE_KEY,
  ID_DOT_ME_CLIENT_ID: publicRuntimeConfig.ID_DOT_ME_CLIENT_ID,
  NEXT_PUBLIC_API_BASE_URL: publicRuntimeConfig.NEXT_PUBLIC_API_BASE_URL,
  NEXTAUTH_URL: publicRuntimeConfig.NEXTAUTH_URL,
  OLLIE_ENV: publicRuntimeConfig.NEXT_PUBLIC_OLLIE_ENV,
  SEGMENT_WRITE_KEY: publicRuntimeConfig.SEGMENT_WRITE_KEY,
  STRIPE_PUBLISHABLE_KEY: publicRuntimeConfig.STRIPE_PUBLISHABLE_KEY,
  STORYBLOK_API_ACCESS_TOKEN: publicRuntimeConfig.STORYBLOK_API_ACCESS_TOKEN,
  STORYBLOK_API_OAUTH_TOKEN: publicRuntimeConfig.STORYBLOK_API_OAUTH_TOKEN,
  STORYBLOK_REDIS_ENABLED:
    publicRuntimeConfig.STORYBLOK_REDIS_ENABLED !== 'false',
  WEB_SENTRY_DSN: publicRuntimeConfig.NEXT_PUBLIC_WEB_SENTRY_DSN,
};
