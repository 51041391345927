import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '../Tooltip';
import { StyledTextCallout, CalloutText, CalloutParagraph } from './styles.js';

export const TextCallout = ({ text, label, children }) => {
  return (
    <StyledTextCallout>
      <CalloutText>{text}</CalloutText>
      <Tooltip name={label}>
        <CalloutParagraph>{children}</CalloutParagraph>
      </Tooltip>
    </StyledTextCallout>
  );
};
TextCallout.displayName = 'TextCallout';

TextCallout.propTypes = {
  text: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
