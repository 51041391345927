import { setLocale } from 'yup';

export const FIELD_REQUIRED_MSG = 'Field is required';
export const INVALID_EMAIL_MSG =
  'Invalid email address. Please check your email and  try again. Your email must follow the format abc@123.com.';
export const CURRENT_WEIGHT_REQUIRED_MSG = 'Current weight is required.';
export const ACTIVITY_LEVEL_REQUIRED_MSG = 'Activity level is required.';
export const SPAYED_STATUS_REQUIRED_MSG = 'Spayed/Neutered status is required.';
export const BIRTH_YEAR_REQUIRED_MSG = 'Birth year is required.';
export const BIRTH_MONTH_REQUIRED_MSG = 'Birth month is required.';
export const NAME_REQUIRED_MSG = 'Name is required.';
export const INVALID_PHONE_NUMBER_MSG = 'A valid phone number is required.';
export const INVALID_EMAIL_ADDRESS_MSG =
  'A valid email address is required to proceed.';
export const ALREADY_USED_EMAIL_WITH_SCANNING_MSG =
  'Email has already been used for a free screening.';
export const EXISTING_ACCOUNT_MSG =
  'This email is already associated with an account.';
export const SMS_OPT_IN_REQUIRED_MSG =
  'You must check the box to agree to the SMS terms to get results via text.';

export const config = () => {
  setLocale({
    mixed: {
      required: fieldInfo => {
        return (
          FIELD_REQUIRED_MSG +
          '. Missing property: ' +
          fieldInfo.path +
          '. Full field info: ' +
          JSON.stringify(fieldInfo)
        );
      },
    },
    string: {
      email: INVALID_EMAIL_MSG,
    },
  });
};
