export const handlePhoneNumberFormat = value => {
  if (!value) return value;

  const inputValue = value.replace(/[^\d]/g, '');
  const inputValueLength = inputValue.length;

  if (inputValueLength < 4) return inputValue;
  if (inputValueLength < 7)
    return `${inputValue.slice(0, 3)}-${inputValue.slice(3)}`;
  return `${inputValue.slice(0, 3)}-${inputValue.slice(
    3,
    6,
  )}-${inputValue.slice(6, 10)}`;
};

export const formToModel = (address, customer) => ({
  address1: address.address,
  address2: address.apt,
  first_name: address.firstName || customer.first_name,
  last_name: address.lastName || customer.last_name,
  phone: address.phoneNumber,
  zip_code: address.zipCode,
  state: address.state,
  city: address.city,
  is_sms: address.smsUpdates,
});

export const modelToForm = (address, customer) => ({
  address: address.address1,
  apt: address.address2,
  firstName: address.first_name,
  lastName: address.last_name,
  phoneNumber: address.phone,
  zipCode: address.zip_code,
  state: address.state,
  city: address.city,
  smsUpdates: customer.is_sms || false,
});
