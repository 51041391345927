import React from 'react';
import PropTypes from 'prop-types';
import { useReporter } from '../../reporter';
import { PillText } from '../PillText';
import { headingLinkClicked } from './events';
import {
  HeadingStyled,
  HeadingWithBadge,
  StyledLink,
  LinkContainer,
  HeadingContainer,
} from './styles';

export const Heading = ({
  headingText,
  headingLevel,
  position = 'left',
  typography = 'headingMastiff',
  backgroundColor = 'background.base',
  verticalPadding = 0,
  horizontalPadding = 0,
  badge = '',
  link,
  fontColor,
}) => {
  const reporter = useReporter();

  typography =
    typeof typography === 'string'
      ? { desktop: typography, mobile: typography }
      : typography;

  return (
    <HeadingContainer
      $position={position}
      $bgColor={backgroundColor}
      $horizontalPadding={horizontalPadding}
      $verticalPadding={verticalPadding}
    >
      <HeadingWithBadge $position={position}>
        <HeadingStyled
          $bgColor={backgroundColor}
          as={headingLevel}
          $typography={typography}
          $position={position}
          $fontColor={fontColor}
        >
          {headingText}
        </HeadingStyled>
        {badge && (
          <>
            &nbsp;
            <PillText text={badge} variant="kale" />
          </>
        )}

        {link && (
          <LinkContainer>
            <StyledLink
              $fontColor={fontColor}
              $typography={typography}
              href={link.target}
              onClick={() =>
                reporter.tag(headingLinkClicked(link.text, headingText))
              }
            >
              {link.text}
            </StyledLink>
          </LinkContainer>
        )}
      </HeadingWithBadge>
    </HeadingContainer>
  );
};

Heading.displayName = 'Heading';

Heading.propTypes = {
  typography: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
    }),
  ]),
  horizontalPadding: PropTypes.number,
  verticalPadding: PropTypes.number,
  headingText: PropTypes.string.isRequired,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'])
    .isRequired,
  position: PropTypes.oneOf(['left', 'right', 'center']),
  backgroundColor: PropTypes.string,
  badge: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }),
  fontColor: PropTypes.string,
};
