'use client';
import styled from 'styled-components';

export const StyledAddOnSummaryCardsContainer = styled.div`
  margin-top: ${props => props.theme.spacing(5)};
  display: flex;
  flex-direction: column;
  max-width: 420px;
  width: 100%;
  @media ${props => props.theme.tablet} {
    margin-top: initial;
  }
`;

export const StyledTitle = styled.h3`
  ${props => props.theme.typography.heading4}
  text-align: left;
  margin-bottom: ${props => props.theme.spacing(5)};
`;

export const StyledCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${props => props.theme.spacing(6)};
  @media ${props => props.theme.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
