import React from 'react';
import NextApp from 'next/app';
import { setAppElement } from 'react-modal';
import Script from 'next/script';
import { OllieTheme, GlobalStyle } from '@ui/theme';
import { ExperimentProvider } from '../experiments/index';
import { Reporter, ReporterProvider } from '../reporter';
import { ToastProvider } from '../Toast/ToastProvider';
import { OfferProvider } from '../offer';
import { CampaignProvider } from '../campaign';
import { ApiProvider } from '../api';
import { ErrorBoundary } from '../containers/ErrorPage/ErrorBoundary';
import { StripeElementsProvider } from '../payment';
import { GladlyContainer } from '../gladly/container';

class App extends NextApp {
  reporter = new Reporter();

  static async getInitialProps(arg) {
    // Opting out of ASO to get runtime env config on every page.
    // Yes, this isn't great, but injecting the env vars at buildtime
    // isn't possible in a docker world.
    // TODO(James) remove this... somehow.
    // Hey, if I had a plan, I wouldn't have wrote this in the first place.
    return super.getInitialProps(arg);
  }

  componentDidCatch(error, errorInfo) {
    this.reporter.error(error, { contexts: errorInfo });
  }

  componentDidMount() {
    setAppElement('#__next');
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <ApiProvider initialSession={pageProps.session}>
        <ReporterProvider reporter={this.reporter}>
          <OllieTheme>
            <ToastProvider>
              <CampaignProvider>
                <OfferProvider>
                  <ExperimentProvider flagshipData={pageProps.flagshipData}>
                    <StripeElementsProvider>
                      <GlobalStyle />
                      <ErrorBoundary reporter={this.reporter}>
                        <Component {...pageProps} />
                      </ErrorBoundary>
                      <GladlyContainer gladlySettings={pageProps.gladly} />
                      <Script src="https://www.googletagmanager.com/gtag/js?id=G-H1JV5JVL6B" />
                      <Script id="google-analytics">
                        {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());

                        gtag('config', 'G-H1JV5JVL6B');
                      `}
                      </Script>
                    </StripeElementsProvider>
                  </ExperimentProvider>
                </OfferProvider>
              </CampaignProvider>
            </ToastProvider>
          </OllieTheme>
        </ReporterProvider>
      </ApiProvider>
    );
  }
}

export default App;
