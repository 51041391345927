import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '../ResponsiveImage';
import logo from './logo.svg';

export const GooglePayLogo = ({ size }) => {
  const width = size === 'small' ? 46 : 63;
  const height = size === 'small' ? 32 : 44;
  return (
    <ResponsiveImage
      size={size}
      src={logo}
      alt="Google Pay"
      width={width}
      height={height}
    />
  );
};

GooglePayLogo.displayName = 'GooglePayLogo';
GooglePayLogo.propTypes = {
  size: PropTypes.oneOf(['small', 'large']),
};
