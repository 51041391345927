/**
 * Convert a number to USD money format
 *
 * EX: 100 => $100.00
 *
 * @param {number} price
 */
const formatUSD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const costFormatter = val => {
  const number = Number(val);

  if (isNaN(number))
    throw new Error(`Cannot format "${val}" as a cost, as it becomes NaN`);

  if (number === 0) return 'FREE';

  const preface = number < 0 ? '-$' : '$';

  return `${preface}${Math.abs(number).toFixed(2)}`;
};

const percentFormatter = val => {
  const number = parseInt(val);

  if (isNaN(number))
    throw new Error(`Cannot format "${val}" as a percent, as it becomes NaN`);

  return `${number}%`;
};

const formatCostAsNumber = val => {
  if (val === 'FREE') {
    return 0;
  }
  const newVal = val?.replace('$', '');
  const number = Number(newVal);
  if (isNaN(number))
    throw new Error(`Cannot format "${val}" as a cost, as it becomes NaN`);
  return newVal;
};

const formatPrice = price => formatUSD.format(price);

export { costFormatter, percentFormatter, formatCostAsNumber, formatPrice };
