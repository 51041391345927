import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { VisuallyHidden } from '@web/atoms';
import { SlidingContainer } from '../SlidingContainer';
import { AddOnSummaryCards } from '../AddOnSummaryCards';
import { ProductsSummaryDisplay } from './ProductsSummaryDisplay';
import {
  Header,
  MobileHeader,
  Title,
  CloseIcon,
  BackLabel,
  ProductSummariesWrapper,
} from './styles';

export const ProductsSummarySlider = ({
  menuHeader,
  sections,
  onCloseMenu,
  onGoBack,
  active,
}) => {
  return (
    <SlidingContainer
      active={active}
      onExit={onCloseMenu}
      $bgColor="background.base"
      alignment="left"
      maxWidth={556}
    >
      <ProductSummariesWrapper>
        <Header>
          <Title>{menuHeader}</Title>
          <CloseIcon aria-label="CloseIcon" onClick={onCloseMenu}>
            <Icon
              iconName="close"
              alt=""
              size={1.4}
              id="prod_summary_desk_close"
            />
            <VisuallyHidden>Close</VisuallyHidden>
          </CloseIcon>
        </Header>
        <MobileHeader>
          <BackLabel aria-label="CloseIcon" onClick={onGoBack}>
            <Icon
              iconName="arrow-left"
              alt=""
              size={1.4}
              id="prod_summary_mobile_close"
            />
            <VisuallyHidden>Close</VisuallyHidden>
            <Title>Back</Title>
          </BackLabel>
        </MobileHeader>
        {sections.map((section, index) => {
          if (section.component === 'add_on_summary_cards')
            //tab index prevents refocus to closeIcon when selecting extras in older versions of safari
            <div tabIndex={-1} key={`${section.component}-${index}`}>
              <AddOnSummaryCards {...section} onClick={onCloseMenu} />
            </div>;
          return (
            //  have a default return so this one will be it for now since we can't use CMSBlok
            <ProductsSummaryDisplay
              {...section}
              key={`${section.component}-${index}`}
              onRecipeClick={onCloseMenu}
            />
          );
        })}
      </ProductSummariesWrapper>
    </SlidingContainer>
  );
};

ProductsSummarySlider.displayName = 'Products Summary Slider';

ProductsSummarySlider.propTypes = {
  menuHeader: PropTypes.string.isRequired,
  sections: PropTypes.array,
  onCloseMenu: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};
