'use client';
import React from 'react';
import PropTypes from 'prop-types';
import { VisuallyHidden } from '@web/atoms';
import { useReporter } from '../../../reporter';
import { ResponsiveImage } from '../../ResponsiveImage';
import logo from './ollie-logo.svg';
import {
  StyledNavigationBar,
  OllieLogo,
  LogoImgContainer,
  CenterAligned,
  LeftAligned,
  RightAligned,
  SkipToContent,
  LogoLink,
} from './styles.js';
import { logoClicked } from './events';

const color = {
  bone: 'background.base',
  white: 'background.lightest',
};

export const NavigationBar = ({
  logoUrl = '/',
  logoSrText = 'Ollie home',
  leftSegment,
  centerSegment,
  rightSegment,
  variant = 'bone',
}) => {
  const reporter = useReporter();

  const onLogoClick = () => {
    reporter.tag(logoClicked(window.location.pathname));
  };

  const hasCenterLinks = !(centerSegment === undefined);

  return (
    <StyledNavigationBar $variant={color[variant]} role="navigation">
      <SkipToContent href="#main">Skip to Content</SkipToContent>
      {/* Optional: left segment of nav (i.e: burger menu) */}
      <LeftAligned>{leftSegment}</LeftAligned>
      <OllieLogo $hasCenterLinks={hasCenterLinks}>
        <LogoLink href={logoUrl} onClick={onLogoClick}>
          <LogoImgContainer>
            <ResponsiveImage
              src={logo.src}
              alt="Ollie logo"
              width={logo.width}
              height={logo.height}
            />
          </LogoImgContainer>
          <VisuallyHidden>{logoSrText}</VisuallyHidden>
        </LogoLink>
      </OllieLogo>
      {/* Optional: this is always the middle segment of the nav */}
      <CenterAligned $hasCenterLinks={hasCenterLinks}>
        {centerSegment}
      </CenterAligned>
      {/* Optional: right segment of nav (i.e: cart icon, login) */}
      <RightAligned>{rightSegment}</RightAligned>
    </StyledNavigationBar>
  );
};

NavigationBar.propTypes = {
  variant: PropTypes.oneOf(['white', 'bone']),
  logoUrl: PropTypes.string,
  logoSrText: PropTypes.string,
  leftSegment: PropTypes.node,
  centerSegment: PropTypes.node,
  rightSegment: PropTypes.node,
};
