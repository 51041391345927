import React from 'react';
import PropTypes from 'prop-types';
import { useReporter } from '../../reporter';
import {
  useReferralRef,
  DESKTOP_MENU_ZONE,
  MOBILE_MENU_ZONE,
} from '../../referral';
import { headerLinkClicked, headerButtonClicked } from './events';
import { NavLink } from './styles';

const ZONE_BY_TYPE = {
  desktop: DESKTOP_MENU_ZONE,
  mobile: MOBILE_MENU_ZONE,
};

export const ReferAFriendLink = ({ text, position, type }) => {
  const reporter = useReporter();
  const rafRef = useReferralRef(ZONE_BY_TYPE[type]);

  const onClickAction = () => {
    reporter.tag(headerButtonClicked(text, position));
  };

  return (
    <NavLink as="button" ref={rafRef} onClick={onClickAction}>
      {text}
    </NavLink>
  );
};

ReferAFriendLink.displayName = 'ReferAFriendLink';

ReferAFriendLink.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['mobile', 'desktop']).isRequired,
};

export const HeaderLink = ({
  text,
  target,
  position,
  type,
  onClick,
  component,
}) => {
  const reporter = useReporter();
  const onClickAction = () => {
    onClick?.();

    reporter.tag(headerLinkClicked(text, target, position));
  };

  return (
    <NavLink href={target} onClick={onClickAction}>
      {text}
    </NavLink>
  );
};

HeaderLink.displayName = 'HeaderLink';

HeaderLink.propTypes = {
  text: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['mobile', 'desktop']),
  onClick: PropTypes.func,
  component: PropTypes.string,
};
