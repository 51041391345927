import { useCallback, useState } from 'react';

export const useLocalStorage = (key, defaultValue) => {
  const [state, setState] = useState(
    // TODO(James): this can produce an initial render mismatch
    (typeof window !== 'undefined' && JSON.parse(localStorage.getItem(key))) ||
      defaultValue,
  );

  const setLocalStorage = useCallback(
    (value, { skipRender = false } = {}) => {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(key, JSON.stringify(value));
        if (!skipRender) {
          setState(value);
        }
      }
    },
    [key],
  );

  return [state, setLocalStorage];
};
