import { PAYMENT_INTENT_STATUSES } from './constants';

export const getPendingIntentTransactionId = intents =>
  intents?.find(intent => intent.status === PAYMENT_INTENT_STATUSES.pending)
    ?.transaction_id;

export const getPendingIntentInternalId = intents =>
  intents?.find(intent => intent.status === PAYMENT_INTENT_STATUSES.pending)
    ?.id;

export const getSetupIntentTransactionId = setupIntent => {
  const parts = setupIntent?.transaction_id.split('_secret_');
  return parts[0];
};
