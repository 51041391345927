'use client';
import PropTypes from 'prop-types';
import axios from 'axios';
import useSWR, { unstable_serialize, useSWRConfig } from 'swr';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import { shared } from '../../config';
import { useLocalStorage } from '../../hooks';
import {
  SESSION_KEY,
  LOADING_STATUS,
  AUTHENTICATED_STATUS,
  UNAUTHENTICATED_STATUS,
} from './constants';
import { useSingleton } from './container';

const getCsrfToken = async () => {
  const response = await axios.get(`/api/auth/csrf/`);
  return response.data.csrfToken;
};

export const SessionContext = createContext(null);
export const useSession = () => useContext(SessionContext);
const EMPTY_SESSION = {
  data: undefined,
  error: undefined,
  isValidating: false,
  isLoading: false,
};

export class Session {
  constructor(cache, mutate) {
    this.cache = cache;
    this.mutate = mutate;
  }

  get _cache() {
    return this.cache.get(unstable_serialize(SESSION_KEY)) ?? EMPTY_SESSION;
  }

  get data() {
    return this._cache.data;
  }

  get user() {
    return this.data?.user;
  }

  get status() {
    const { data, error, isValidating } = this._cache;
    return !data && !error && isValidating
      ? LOADING_STATUS
      : data?.user
      ? AUTHENTICATED_STATUS
      : UNAUTHENTICATED_STATUS;
  }

  register = async (firstName, email, customerGroupId) => {
    const response = await axios.post(
      `/api/auth/callback/register/`,
      new URLSearchParams({
        ...(firstName ? { first_name: firstName } : {}),
        email,
        customer_group_id: customerGroupId,
        csrfToken: await getCsrfToken(),
        callbackUrl: String(window.location),
        json: 'true',
      }),
    );
    await this.mutate();

    return response;
  };

  login = async (email, password) => {
    const response = await axios.post(
      `/api/auth/callback/login/`,
      new URLSearchParams({
        email,
        password,
        csrfToken: await getCsrfToken(),
        callbackUrl: String(window.location),
        json: 'true',
      }),
    );
    await this.mutate();

    return response;
  };

  logout = async () => {
    const response = await axios.post(
      `/api/auth/signout/`,
      new URLSearchParams({
        csrfToken: await getCsrfToken(),
        callbackUrl: String(window.location),
        json: 'true',
      }),
    );
    await this.mutate();

    return response;
  };
}

const screeningFlows = ['weight_web_acquisition'];

export const SessionProvider = ({ children }) => {
  const router = useRouter();
  const [isAppSession, setIsAppSession] = useLocalStorage('isAppSession');
  const [screeningSession, setScreeningSession] =
    useLocalStorage('screeningSession');
  const isScreeningSession =
    screeningFlows.includes(router.query.flow) ||
    (Date.now() < screeningSession?.expire &&
      screeningFlows.includes(screeningSession?.flow));
  const [isRegistering, setIsRegistering] = useState(true);
  const { cache } = useSWRConfig();
  // this is preloaded on page load, so no need to revalidate
  // TODO(James) this isn't a generic solution to data being preloaded
  // how do we preload certain data and avoid revalidating on page load?
  const { mutate, data, error, isValidating } = useSWR(SESSION_KEY, {
    // Custom fetcher b/c this comes from ollieweb, not olliemain
    ...(shared.OLLIE_ENV !== 'storybook' && {
      fetcher: url => axios.get(url).then(resp => resp.data),
      revalidateOnMount: router.pathname === '/404',
    }),
  });
  const service = useSingleton(Session, () => new Session(cache, mutate));

  useEffect(() => {
    if (router.query.isAppSession) setIsAppSession(true);
    if (screeningFlows.includes(router.query.flow)) {
      const date = new Date();
      const expire = date.setDate(date.getDate() + 1);
      setScreeningSession({
        flow: router.query.flow,
        result: router.query.result,
        expire,
      });
    }
    if (router.query.firstName && router.query.email && !data) {
      setIsRegistering(true);
      service
        .register(router.query.firstName, router.query.email)
        .then(() => setIsRegistering(false));
    } else setIsRegistering(false);
  }, [
    data,
    router.query.email,
    router.query.firstName,
    router.query.flow,
    router.query.isAppSession,
    router.query.result,
    service,
    setIsAppSession,
    setScreeningSession,
  ]);

  const session = useMemo(
    () => ({
      data,
      error,
      isValidating,
      // Session service is mostly used by the API services that extend
      // BaseService from './service' or flows that need a live, mutable
      // reference to the underlying session data (e.g. register).
      service,
      status: service.status,
      user: service.user,
      register: service.register,
      login: service.login,
      logout: service.logout,
      isAppSession: Boolean(isAppSession || router.query.isAppSession),
      isScreeningSession,
      isRegistering,
    }),
    [
      data,
      error,
      isValidating,
      service,
      isAppSession,
      router.query.isAppSession,
      isScreeningSession,
      isRegistering,
    ],
  );

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
};
SessionProvider.propTypes = {
  children: PropTypes.node,
};
