import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { Icon } from '@ui/icons';
import { variants } from './variants';
import { StyledButton, IconContainer, HoverIcon } from './styles.js';

export const LinkButton = forwardRef(
  (
    {
      children,
      href,
      target,
      horizontalPadding = 8,
      disabled,
      icon,
      onClick,
      verticalPadding = 4,
      percentWidthMobile,
      variant = 'primary',
      hoverIcon = null,
      minWidth = 0,
      fullWidth = false,
    },
    ref,
  ) => {
    return (
      <Link href={href}>
        <StyledButton
          ref={ref}
          as="button"
          type="button"
          disabled={disabled}
          target={target}
          onClick={onClick}
          $horizontalPadding={horizontalPadding}
          $verticalPadding={verticalPadding}
          $percentWidthMobile={percentWidthMobile}
          $variant={variant}
          $hasHoverIcon={!!hoverIcon}
          $minWidth={minWidth}
          $fullWidth={fullWidth}
          $icon={icon?.position}
        >
          {icon?.position === 'before' && (
            <IconContainer aria-hidden="true" position={icon.position}>
              <Icon {...icon} id="link_button_before" />
            </IconContainer>
          )}
          {children}
          {icon?.position === 'after' ||
            (icon?.position === 'end' && (
              <IconContainer aria-hidden="true" position={icon.position}>
                <Icon {...icon} id="link_button_after" />
              </IconContainer>
            ))}
          {hoverIcon && (
            <HoverIcon aria-hidden="true">
              <Icon
                alt=""
                size="22px"
                iconName={hoverIcon}
                color={variants[variant].active.text}
                id="link_button_hover"
              />
            </HoverIcon>
          )}
        </StyledButton>
      </Link>
    );
  },
);

LinkButton.displayName = 'LinkButton';

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  horizontalPadding: PropTypes.number,
  verticalPadding: PropTypes.number,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.shape({
    position: PropTypes.oneOf(['before', 'after', 'end']),
  }),
  percentWidthMobile: PropTypes.number,
  hoverIcon: PropTypes.string,
  minWidth: PropTypes.number,
  fullWidth: PropTypes.bool,
  target: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(variants)),
};
