import React from 'react';
import PropTypes from 'prop-types';
import { StyledPillText } from './styles';

export const PillText = ({ text, variant = 'light', size = 'large' }) => (
  <StyledPillText variant={variant} size={size}>
    {text}
  </StyledPillText>
);

PillText.displayName = 'PillText';
PillText.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    'light',
    'dark',
    'blueberry',
    'kale',
    'egg',
    'chia',
    'spinach',
    'cranberry',
    'warning',
  ]),
  size: PropTypes.oneOf(['small', 'large']),
};
