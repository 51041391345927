'use client';
import { useEffect, useLayoutEffect } from 'react';

export { useLocalStorage } from './useLocalStorage';
export { useSessionStorage } from './useSessionStorage';
export { useCountdown } from './useCountdown';
export { useRefInView } from './useRefInView';
export { useAddonModal } from './useAddonModal';

export const useIsomorphicLayoutEffect =
  typeof window === 'undefined' ? useEffect : useLayoutEffect;

export const sideScroll = (columns, page, element, speed, distance, step) => {
  switch (page) {
    case 0:
      step = 950;
      if (element.scrollLeft > 30) {
        step = step * 10 * -1;
      }
      break;
    case 1:
      step = 20;
      if (columns.length > 5) {
        step = 250;
      }
      if (element.scrollLeft > 90) {
        step = step * -1;
      }
      break;
    case 2:
      step = 900;
      if (element.scrollLeft === 0) {
        step = distance * 4;
      }
      break;
    default:
      step = 0;
  }
  let scrollAmount = 0;
  const slideTimer = setInterval(() => {
    element.scrollLeft += step;
    scrollAmount += Math.abs(step);
    if (scrollAmount >= distance) {
      clearInterval(slideTimer);
    }
  }, speed);
};
