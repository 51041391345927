import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import {
  ContainerWrapper,
  ContainerHeader,
  Title,
  ContainerBody,
} from './styles';

export const TitleCTAContainer = ({ title, ctaButton = null, children }) => (
  <ContainerWrapper>
    <ContainerHeader>
      <Title>{title}</Title>
      {ctaButton ? (
        <LinkButton
          href={ctaButton.href}
          variant={ctaButton.variant}
          horizontalPadding={5}
          verticalPadding={1}
          minWidth={90}
          onClick={ctaButton.onClick}
          fullWidth={false}
        >
          {ctaButton.text}
        </LinkButton>
      ) : null}
    </ContainerHeader>
    <ContainerBody>{children}</ContainerBody>
  </ContainerWrapper>
);

TitleCTAContainer.propTypes = {
  title: PropTypes.node.isRequired,
  ctaButton: PropTypes.shape({
    text: PropTypes.string,
    variant: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};
