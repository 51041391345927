const DIMENSION_IMG_REGEX = /\d+x\d+/;

export const hasDimensions = src => DIMENSION_IMG_REGEX.test(src);

export const getDimensions = src => {
  const [width, height] = src.match(DIMENSION_IMG_REGEX)[0].match(/\d+/g);
  return { width: Number(width), height: Number(height) };
};

export const nextImageLoader = ({ src, width, quality }) => {
  return `${src}/m/${width}x0${quality ? `/filters:quality(${quality})` : ''}`;
};

export const getSrcSets = (src, width) => {
  return [
    {
      pixelDensity: 1,
      src: nextImageLoader({ src, width, quality: 70 }),
    },
    {
      pixelDensity: 2,
      src: nextImageLoader({ src, width: width * 2, quality: 70 }),
    },
  ];
};

export const storyblokAspectRatio = src => {
  const { width, height } = getDimensions(src);

  return `${(height / width) * 100}%`;
};
