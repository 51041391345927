import { useSWRList } from '@hooks/use-swr-list';
import { PRODUCTS_URL } from './constants';

export const useProductsList = products => {
  const { data, error } = useSWRList(
    products?.map(product => `${PRODUCTS_URL}${product}/`),
  );

  return {
    products: data,
    isLoading: !error && !data,
    error,
  };
};
