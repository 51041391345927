import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { VisuallyHidden } from '../VisuallyHidden';
import { XButton } from './styles.js';

export const CloseButton = ({ onClick, color = 'border.fur' }) => {
  return (
    <XButton onClick={onClick}>
      <Icon
        iconName="close-circle"
        size="40px"
        color={color}
        alt=""
        id="close_button"
      />
      <VisuallyHidden>Close Modal</VisuallyHidden>
    </XButton>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
};
