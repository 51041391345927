export const footerLinkClicked = (text, target, group, position) => ({
  type: 'Footer Link Clicked',
  data: {
    text,
    target,
    group,
    position,
  },
});

export const userClicksOnAppleDownloadButton = () => ({
  type: 'App Apple Download Clicked',
});

export const userClicksOnGoogleDownloadButton = () => ({
  type: 'App Google Download Clicked',
});
