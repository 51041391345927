'use client';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import Script from 'next/script';
import { usePathname } from 'next/navigation';
import { datadogRum } from '@datadog/browser-rum';
import { useEnvVars } from '@web/app/contexts/EnvContext';
import { shared } from '../config';
import { useSessionCustomer } from '../customer';
import { renderSnippet } from './segment';

/**
 * @returns {import('./Reporter').Reporter}
 */
export const useReporter = () => useContext(ReporterContext);

/**
 * @param {string} page
 */
export const useTrackPage = page => {
  const reporter = useReporter();

  useEffect(() => {
    reporter.page(page);
  }, [reporter, page]);
};

export const ReporterContext = createContext(null);

/**
 *
 * @param {string} pathname
 * @returns {boolean}
 */
const shouldInitializeDatadogRum = (pathname, envVars = null) => {
  const datadogConfig = envVars
    ? envVars.DATADOG_RUM_ENABLED_AND_LIST_PAGES
    : shared.DATADOG_RUM_ENABLED_AND_LIST_PAGES;
  if (!datadogConfig) return false;

  const configItems = datadogConfig.split(',');
  const isEnabled = configItems[0] === 'true';
  const regexPaths = configItems.slice(1);

  return (
    isEnabled && regexPaths.some(pattern => new RegExp(pattern).test(pathname))
  );
};

export const ReporterProvider = ({ reporter, children }) => {
  const pathname = usePathname();
  const { customer } = useSessionCustomer();
  const envVars = useEnvVars();
  const initialized = useRef(false);
  const enabled = envVars
    ? envVars.OLLIE_ENV !== 'local'
    : shared.OLLIE_ENV !== 'local';

  useEffect(() => {
    if (customer?.id && customer?.email) {
      reporter.user({ id: customer.id, email: customer.email });
    }
  }, [customer?.id, customer?.email, reporter]);

  useEffect(() => {
    if (
      !initialized.current &&
      enabled &&
      shouldInitializeDatadogRum(pathname, envVars)
    ) {
      initialized.current = true;
      datadogRum.init({
        applicationId: envVars
          ? envVars.DATADOG_RUM_APPLICATION_ID
          : shared.DATADOG_RUM_APPLICATION_ID,
        clientToken: envVars
          ? envVars.DATADOG_RUM_CLIENT_TOKEN
          : shared.DATADOG_RUM_CLIENT_TOKEN,
        service: 'ollieweb',
        version: envVars ? envVars.COMMIT_SHA : shared.COMMIT_SHA,
        env: envVars ? envVars.OLLIE_ENV : shared.OLLIE_ENV,
        // Sample nothing if disabled.
        sessionSampleRate: enabled ? 100 : 0,
        sessionReplaySampleRate: 100,
        allowedTracingUrls: [/http(s?):\/\/(\w+-)?(www|api)\.myollie\.com/],
        trackResources: true,
        trackUserInteractions: true,
        startSessionReplayRecordingManually: true,
        defaultPrivacyLevel: 'mask-user-input',
      });

      if (
        (envVars
          ? envVars.DATADOG_RUM_SESSION_RECORDING
          : shared.DATADOG_RUM_SESSION_RECORDING) &&
        enabled
      )
        datadogRum.startSessionReplayRecording();
    }
  }, [pathname, enabled, envVars]);

  return (
    <ReporterContext.Provider value={reporter}>
      <Script
        id="segment-script"
        dangerouslySetInnerHTML={{ __html: renderSnippet(envVars) }}
      />
      {children}
    </ReporterContext.Provider>
  );
};

ReporterProvider.displayName = 'ReporterProvider';
ReporterProvider.propTypes = {
  reporter: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export const PrefixedReporterProvider = ({ prefix, children }) => {
  const reporter = useReporter();
  const prefixedReporter = useMemo(
    () => reporter.withPrefix(prefix),
    [reporter, prefix],
  );

  return (
    <ReporterContext.Provider value={prefixedReporter}>
      {children}
    </ReporterContext.Provider>
  );
};

PrefixedReporterProvider.displayName = 'PrefixedReporterProvider';
PrefixedReporterProvider.propTypes = {
  prefix: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
