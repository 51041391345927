import useSWR from 'swr';
import { PRODUCTS_URL } from './constants';

export const useProduct = (sku, { couponCode } = {}) => {
  const params = couponCode
    ? new URLSearchParams({ coupon_code: couponCode })
    : '';
  const { data, error } = useSWR(
    sku ? `${PRODUCTS_URL}${sku}/?${params.toString()}` : null,
  );

  return {
    isLoading: sku ? !error && !data : false,
    isError: error,
    product: data,
  };
};
