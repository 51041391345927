import React from 'react';
import { ChatButton } from '@web/components';
import { useSession } from '../api';
import { GladlyScript } from '../head';

export const GladlyContainer = ({ gladlySettings }) => {
  const { isAppSession } = useSession();
  if (isAppSession) return <></>;

  return (
    <>
      <GladlyScript />
      <ChatButton gladlySettings={gladlySettings} />
    </>
  );
};
