import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { ResponsiveImage } from '../ResponsiveImage';
import {
  StyledCard,
  CardTitle,
  CardContent,
  CardBody,
  CardImageContainer,
  CardButtonContainer,
} from './styles';

export const SlideDownCard = ({
  header,
  description,
  button,
  image,
  onClick,
  buttonVariant = 'chia',
}) => {
  return (
    <StyledCard>
      <CardImageContainer>
        <ResponsiveImage
          src={image.src}
          alt={image.alt}
          width={280}
          height={210}
        />
      </CardImageContainer>

      <CardContent>
        <CardTitle>{header}</CardTitle>
        <CardBody>{description}</CardBody>
      </CardContent>

      {button && (
        <CardButtonContainer>
          <LinkButton
            verticalPadding={1}
            horizontalPadding={8}
            variant={buttonVariant}
            href={button.target}
            onClick={onClick}
            minWidth={90}
          >
            {button.text}
          </LinkButton>
        </CardButtonContainer>
      )}
    </StyledCard>
  );
};

SlideDownCard.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.node,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  buttonVariant: PropTypes.string,
};
