'use client';
import styled, { css } from 'styled-components';
import { variants } from './variants';

export const StyledButton = styled.button`
  ${props => props.theme.typography.button}
  border-radius: 500px;
  padding: ${props =>
    props.theme.spacing(props.$verticalPadding, props.$horizontalPadding)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: ${props => props.$minWidth}px;
  width: ${props => (props.$fullWidth ? '100%' : 'auto')};
  position: relative;
  transition: transform 0.5s;
  white-space: nowrap;

  ${({ $variant, theme }) => css`
    background-color: ${theme.colors.get(variants[$variant].active.background)};
    border: 1px solid ${theme.colors.get(variants[$variant].active.border)};
    color: ${theme.colors.get(variants[$variant].active.text)};

    //TODO: These pseudo-classes don't have designs yet and will be updated
    //on WEB-1543

    &:hover {
      border-color: ${props =>
        props.$variant === 'cancel'
          ? props.theme.colors.status.errorPrimary
          : props.$variant !== 'fur'
          ? props.theme.colors.border.dark
          : props.theme.colors.border.fur};
      outline: none;
    }

    &:active {
      border-color: ${props => props.theme.colors.text.primary};
    }

    &:disabled {
      opacity: 0.5;
    }
  `}

  ${props =>
    props.$hasHoverIcon &&
    css`
      border: 0;
      &:hover {
        transform: translateY(-2px);

        & ${HoverIcon} {
          opacity: 1;
          transform: translateX(0) translateY(-50%);
        }
      }
    `}


  ${props =>
    props.$percentWidthMobile &&
    !props.$fullWidth &&
    css`
      padding-left: 0;
      padding-right: 0;
      width: ${props.$percentWidthMobile}%;
    `}

    ${props => props.$icon === 'end' && WithPlusIcon};

  @media ${props => props.theme.tablet} {
    width: ${props => (props.$fullWidth ? '100%' : 'auto')};
    padding: ${props =>
      props.theme.spacing(props.$verticalPadding, props.$horizontalPadding)};
  }
`;

export const ButtonLoaderContainer = styled.div`
  position: absolute;
  right: ${props => props.theme.spacing(4)};
`;

export const IconContainer = styled.span`
  display: inline-flex;
  padding-right: ${props =>
    props.position === 'before' && props.theme.spacing(2)};
  padding-left: ${props =>
    props.position === 'after' && props.theme.spacing(2)};
`;

export const HoverIcon = styled.div`
  line-height: 0;
  position: absolute;
  right: ${props => props.theme.spacing(5)};
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.5s, transform 0.5s;

  @media ${props => props.theme.tablet} {
    opacity: 0;
    transform: translateX(-4px) translateY(-50%);
  }
`;

const WithPlusIcon = css`
  background: #f1f4f8;

  &:hover {
    border-color: ${props => props.theme.colors.hero.blueberry};
    outline: none;
  }

  svg {
    position: absolute;
    left: 89.13%;
    right: 10.67%;
    top: 26.28%;
    bottom: 22.78%;
  }
`;
