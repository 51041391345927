'use client';
import Link from 'next/link';
import styled from 'styled-components';
import get from 'lodash/get';

export const HeadingStyled = styled.h2`
  background-color: ${props =>
    props.$bgColor && props.theme.colors.get(props.$bgColor)};
  ${props => get(props.theme.typography, props.$typography.mobile)};
  text-align: ${props => props.$position};
  padding: ${props => props.theme.spacing(1, 2)};
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};
  @media ${props => props.theme.laptop} {
    ${props => get(props.theme.typography, props.$typography.desktop)};
  }
`;

export const HeadingWithBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.$position};
  flex-direction: column;
  @media ${props => props.theme.mobileL} {
    flex-direction: row;
    align-items: flex-end;
  }
  padding-bottom: ${props => props.theme.spacing(0.25)};
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${props => props.$position};
  padding: ${props =>
    props.theme.spacing(props.$verticalPadding, props.$horizontalPadding)};
  justify-content: ${props => props.$position};
  background-color: ${props =>
    props.$bgColor && props.theme.colors.get(props.$bgColor)};
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: Center;
`;

export const StyledLink = styled(Link)`
  border: none;
  color: ${props =>
    props.$fontColor && props.theme.colors.get(props.$fontColor)};
  outline: none;
  font-size: 1rem;
  background: none;
  padding: ${props => props.theme.spacing(1, 2)};
  /* button reset styles */
  margin: 0;
  display: inline;
  text-align: left;
  width: auto;
  overflow: visible;
  vertical-align: baseline;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  /* stylelint-disable-next-line */
  -webkit-appearance: none;
  /* end button reset styles */
  ${props => get(props.theme.typography, props.$typography.mobile)};

  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  &:focus {
    text-decoration: none;
    outline: 1px solid ${props => props.theme.colors.border.base};
    border-radius: 2px;
  }
  &:visited {
    text-decoration: underline;
  }

  @media ${props => props.theme.laptop} {
    ${props => get(props.theme.typography, props.$typography.desktop)};
  }
`;
