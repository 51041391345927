'use client';
import * as snippet from '@segment/snippet';
import { shared } from '../config';

export function renderSnippet(envVars) {
  const opts = {
    apiKey: envVars ? envVars.SEGMENT_WRITE_KEY : shared.SEGMENT_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}
