import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@ui/theme';

export const CheckmarkIcon = ({
  color = 'secondary.egg',
  size = '17',
  alt = '',
}) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      viewBox="0 0 17 16"
    >
      {alt && <title>{alt}</title>}
      <path
        d="M16.688.03c.22-.126.428.181.237.35-4.601 4.047-8.417 9.674-11.05 15.496a.206.206 0 0 1-.378-.002C3.972 12.396 2.13 9.602.087 8.08c-.175-.13-.066-.413.148-.385 2.073.27 4.465 1.935 5.45 3.53C8.376 6.4 12.152 2.628 16.689.03z"
        fill={theme.colors.get(color)}
      />
    </svg>
  );
};
CheckmarkIcon.displayName = 'CheckmarkIcon';
CheckmarkIcon.propTypes = {
  alt: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
