'use client';
import { useClient } from './client';
import { useSingleton } from './container';
import { useSession } from './session';

export class BaseService {
  /**
   * @param {import('axios').AxiosInstance} client
   * @param {import('./session').Session} session
   */
  constructor(client, session) {
    this.client = client;
    this.session = session;
  }
}

export const useService = Service => {
  const client = useClient();
  const { service: session } = useSession();
  return useSingleton(Service, () => new Service(client, session));
};
