import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { useReporter } from '../../reporter';
import { StyledCtaButton } from './styles.js';
import { ctaButtonClicked } from './events';

export const CtaButton = ({
  children,
  href,
  variant,
  onClick,
  fullWidth = false,
  openNewTab = false,
  minWidth = 240,
  hoverIcon = 'arrow-forward',
  headerText = '',
}) => {
  const getCtaPosition = href => {
    const params = new URLSearchParams(href.split('?')[1]);
    return params.get('cta_position');
  };

  const reporter = useReporter();

  const onClickWithReporter = () => {
    const ctaPosition = getCtaPosition(href);
    let text = null;
    if (typeof children === 'string') text = children;
    reporter.tag(ctaButtonClicked(ctaPosition, href, text, headerText));
    onClick?.();
  };

  return (
    // Would it make sense for CTA button to just extend LinkButton rather than wrap it?
    <StyledCtaButton>
      <LinkButton
        href={href}
        variant={variant}
        horizontalPadding={12}
        verticalPadding={4}
        minWidth={minWidth}
        onClick={onClickWithReporter}
        hoverIcon={hoverIcon}
        fullWidth={fullWidth}
        target={openNewTab ? '_blank' : null}
        rel={openNewTab ? 'noopener noreferrer' : null}
      >
        {children}
      </LinkButton>
    </StyledCtaButton>
  );
};

CtaButton.displayName = 'CtaButton';

CtaButton.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  openNewTab: PropTypes.bool,
  hoverIcon: PropTypes.string,
  headerText: PropTypes.string,
};
