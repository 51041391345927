import React from 'react';
import { ApplePayLogo, GooglePayLogo, ResponsiveImage } from '@web/molecules';
import { shared } from '../config';
import visa from './brandImages/visa.svg';
import mastercard from './brandImages/mastercard.svg';
import americanexpress from './brandImages/americanexpress.svg';
import discover from './brandImages/discover.svg';
import googlepay from './brandImages/googlepay.svg';
import applepay from './brandImages/applepay.svg';

export const DEFAULT = 'default';
export const PAYMENT_V3_URL = customer_id =>
  `/api/v3/customers/${customer_id}/payment_methods/`;
export const PAYMENT_INTENT_V3_URL = `/api/v3/payments/`;

export const STRIPE_REDIRECT_URL =
  (shared.NEXT_PUBLIC_OLLIE_ENV === 'local' ? 'http' : 'https') +
  '://' +
  shared.DOMAIN_PUBLIC_NAME +
  '/confirmation';
export const STRIPE_ACCOUNT_REDIRECT_URL =
  (shared.NEXT_PUBLIC_OLLIE_ENV === 'local' ? 'http' : 'https') +
  '://' +
  shared.DOMAIN_PUBLIC_NAME +
  '/account';

export const PAYMENT_METHOD_INTENT_V3_URL = customerId =>
  `/api/v3/customers/${customerId}/payment_methods/initiate/`;

export const PAYMENT_METHOD_CONFIRM_V3_URL = (customerId, paymentMethodId) =>
  `/api/v3/customers/${customerId}/payment_methods/${paymentMethodId}/confirm/`;

const height = 32;
const width = 46;

export const PAYMENT_METHODS_SVGS = {
  mastercard: mastercard,
  discover: discover,
  visa: visa,
  amex: americanexpress,
  google_pay: googlepay,
  apple_pay: applepay,
};

export const PAYMENT_METHOD_IMAGES = {
  mastercard: (
    <ResponsiveImage
      src={mastercard.src}
      alt="Mastercard Image"
      height={height}
      width={width}
    />
  ),
  discover: (
    <ResponsiveImage
      src={discover.src}
      alt="Discover Image"
      height={height}
      width={width}
    />
  ),
  visa: (
    <ResponsiveImage
      src={visa.src}
      alt="Visa Image"
      height={height}
      width={width}
    />
  ),
  americanexpress: (
    <ResponsiveImage
      src={americanexpress.src}
      alt="American Express Image"
      height={height}
      width={width}
    />
  ),
  google_pay: <GooglePayLogo size="small" />,
  apple_pay: <ApplePayLogo size="small" />,
};

export const PAYMENT_INTENT_STATUSES = {
  pending: 'Pending',
  authorized: 'Authorized',
  succeeded: 'Succeeded',
  failed: 'Failed',
};

export const STRIPE_ERRORS = {
  stripePromiseFailed: {
    title: '',
    message:
      'There was an error loading the payment fields. Please refresh your browser or reach out to Canine Care to complete your signup.',
  },
  stripePaymentFailed: {
    title: `There was a problem on Stripe's end`,
    message:
      'You can retry submitting, try a new card, or contact our Canine Care team to complete your order.',
  },
};
