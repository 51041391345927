'use client';
import styled from 'styled-components';

export const IngredientWrapper = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

export const IngredientIcon = styled.div`
  flex: 0 0 auto;
  padding-top: ${props => props.theme.spacing(2)};
  text-align: center;
  width: 84px;
`;

export const IngredientContent = styled.div`
  flex: 1 1 100%;
  padding-left: ${props => props.theme.spacing(4)};
`;

export const IngredientHeading = styled.h4`
  ${props => props.theme.typography.subheading2};
`;

export const IngredientBody = styled.p`
  ${props => props.theme.typography.body};
`;
