'use client';
import { css } from 'styled-components';
import { ICON_PATHS } from './constants';

/**
 * Generates the CSS for a pseudo element icon
 *
 * @param {string} iconName - Name of icon from VALID_ICONS list
 * @returns {import('styled-components').FlattenSimpleInterpolation} Interpolated css block to display pseudo element icon
 */
export const generateContentIconStyle = iconName => {
  return css`
    display: inline-block;
    content: '';
    mask: ${`url("data:image/svg+xml;utf8,<svg viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'><path d='${ICON_PATHS[iconName]}'></path></svg>")`};
    mask-size: cover;
  `;
};
