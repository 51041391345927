'use client';
/* eslint-disable import/order */
import React from 'react';
import { TextCallout } from '@web/components/TextCallout';
import { RecipeIngredient } from '@web/components/RecipeIngredient';
import { Paragraph } from '@web/atoms/Paragraph';
import lambImg from '../images/our-food/lamb@2x.jpg';
import chickenImg from '../images/our-food/chicken@2x.jpg';
import beefImg from '../images/our-food/beef@2x.jpg';
import turkeyImg from '../images/our-food/turkey@2x.jpg';
import freshBeefBowlImg from '../images/product-photos/bowls/Fresh-Beef-bowl.jpg';
import freshChickenBowlImg from '../images/product-photos/bowls/Fresh-Chicken-bowl.jpg';
import freshLambBowlImg from '../images/product-photos/bowls/Fresh-Lamb-bowl.jpg';
import freshTurkeyBowlImg from '../images/product-photos/bowls/Fresh-Turkey-bowl.jpg';
import marthaStewartLogo from '../images/press/martha-stewart-logo.png';
import gmaLogo from '../images/press/good-morning-america-logo.png';
import forbesLogo from '../images/press/forbes-logo.png';
import businessInsiderLogo from '../images/press/business-insider-logo.png';
import petLifeTodayLogo from '../images/press/press-pets-life-today.svg';
import readersDigest from '../images/press/press-readers-digest.svg';
import todayShowLogo from '../images/press/today-show-logo.png';
import vogueLogo from '../images/press/vogue-logo.png';
import puptainerIcon from '../images/Homepage/puptainer-icon.png';
import moneyBackIcon from '../images/Homepage/money-back-guarantee.svg';
import howItWorks1Img from '../images/Homepage/Home-howitworks1.jpg';
import howItWorks2Img from '../images/Homepage/Home-howitworks2.jpg';
import howItWorks3Img from '../images/Homepage/Home-howitworks3.jpg';
import ingredientsTurkeyThighImg from '../images/onboarding-ingredients/ingredients-turkey-thigh.png';
import ingredientsBlueberriesImg from '../images/onboarding-ingredients/ingredients-blueberries.png';
import ingredientsRedLentilsImg from '../images/onboarding-ingredients/ingredients-red-lentils.png';
import ingredientsKaleImg from '../images/onboarding-ingredients/ingredients-kale.png';
import phoneIcon from '../images/Footer/phone.svg';
import mailIcon from '../images/Footer/mail.svg';
import helpIcon from '../images/Footer/info.svg';
import scoopIcon from '../images/Homepage/scoop-icon.png';
import feedingGuidelineIcon from '../images/Homepage/feeding-guideline-icon.png';
import ollieLogo from '../images/Homepage/ollie-logo-inline.svg';
import foodPackIcon from '../images/Homepage/food-pack-icon.png';
import noArtificialFlavorsIcon from '../images/our-food/no-artificial-flavors.svg';
import noFillersIcon from '../images/our-food/no-fillers.svg';
import multiRecipesPackSplashImg from '../images/Homepage/multi-recipe-packs-splash.jpg';
import multiRecipesPackSplashx2Img from '../images/Homepage/multi-recipe-packs-splash@2x.jpg';
import ollieFeastSplashImg from '../images/Homepage/ollie-feast-splash.jpg';
import ollieFeastSplashx2Img from '../images/Homepage/ollie-feast-splash@2x.jpg';
import carrotOnForkIcon from '../images/Homepage/illustration-carrot-on-fork@2x.png';
import pettingDogIcon from '../images/Homepage/illustration-petting-dog@2x.png';
import ollieBoxIcon from '../images/Homepage/illustration-green-ollie-box@2x.png';
import humanGradeIcon from '../images/our-food/human-grade.svg';
import minimalProcessingIcon from '../images/our-food/minimal-processing.svg';
import bowlsCardDImg from '../images/recipe-cards/bowls-card-d.jpg';
import bowlsCardDx2Img from '../images/recipe-cards/bowls-card-d@2x.jpg';
import bowlsCardDx3Img from '../images/recipe-cards/bowls-card-d@3x.jpg';
import bowlsCardMImg from '../images/recipe-cards/bowls-card-m.jpg';
import bowlsCardMx2Img from '../images/recipe-cards/bowls-card-m@2x.jpg';
import bowlsCardMx3Img from '../images/recipe-cards/bowls-card-m@3x.jpg';
import freshPackIcon from '../images/recipes/fresh-pack-icon.svg';
import dryPackIcon from '../images/recipes/baked-pack-icon.svg';
import dryBeefBowl from '../images/product-photos/bowls/Dry-beef-bowl.jpg';
import dryChickenBowl from '../images/product-photos/bowls/Dry-chicken-bowl.jpg';
import beefOutlineIcon from '../images/our-food/beef-outline-icon.svg';
import packIngredientsFreshBeefImg from '../images/product-photos/pack-with-ingredients/pack-ingredients-freshbeef.jpg';
import sweetPotatoIcon from '../images/our-food/sweet-potato.svg';
import peasIcon from '../images/our-food/peas.svg';
import rosemaryIcon from '../images/our-food/rosemary.svg';
import chickenOutlineIcon from '../images/our-food/chicken-outline-icon.svg';
import freshChickenImg from '../images/product-photos/pack-with-ingredients/pack-ingredients-freshchicken.jpg';
import carrotsIcon from '../images/our-food/carrots.svg';
import spinachIcon from '../images/our-food/spinach.svg';
import whiteRiceIcon from '../images/our-food/white-rice.svg';
import lambOutlineIcon from '../images/our-food/lamb-outline-icon.svg';
import freshLambImg from '../images/product-photos/pack-with-ingredients/pack-ingredients-freshlamb.jpg';
import butternutSquashIcon from '../images/our-food/butternut-squash.svg';
import kaleIcon from '../images/our-food/kale.svg';
import turkeyOutlineIcon from '../images/our-food/turkey-outline-icon.svg';
import freshTurkey from '../images/product-photos/pack-with-ingredients/pack-ingredients-freshturkey.jpg';
import blueberriesIcon from '../images/our-food/blueberries.svg';
import chiaSeedsIcon from '../images/our-food/chia-seeds.svg';
import freshRecipesImage from '../images/recipes/types/ProductCard-Fresh-1.5.png';
import mixedRecipesImage from '../images/recipes/types/ProductCard-Mixed-1.5.png';
import dryRecipesImage from '../images/recipes/types/ProductCard-Dry-1.5.png';
import JerkyTreatBeefSweetPotatoImage from '../images/our-food/addons/JerkyTreats-BeefSweetPotato.jpg';
import JerkyTreatChickenAppleImage from '../images/our-food/addons/JerkyTreats-ChickenApple.jpg';
import SweetPotatoTreatImage from '../images/our-food/addons/StripTreats-SweetPotato.jpg';
import ZoomiesImage from '../images/our-food/addons/Supplements-Zoomies.png';
import ChillPillsImage from '../images/our-food/addons/Supplements-ChillPills.png';
import BellyRubsImage from '../images/our-food/addons/Supplements-BellyRubs.png';
import blogImage1 from '../images/account/BlogImage1.png';
import blogImage2 from '../images/account/BlogImage2.png';
import blogImage3 from '../images/account/BlogImage3.png';
import freshBeefPackImage from '../images/product-photos/pack-shots/Fresh-packs-beef-circled.png';
import freshChickenPackImage from '../images/product-photos/pack-shots/Fresh-packs-chicken.png';
import barcodeImage from '../images/product-photos/pack-barcode/D-Barcode-Explainer.png';
import photo from '../images/Icons/dog2.jpg';
import photo1 from '../images/before.png';
import photo2 from '../images/after.png';
import beef from '../images/Icons/Beef.png';
import chicken from '../images/Icons/Chicken.png';
import lamb from '../images/Icons/Lamb.png';
import turkey from '../images/Icons/Turkey.png';
import pork from '../images/Icons/Pork.png';

export const PRODUCT_TYPES = [
  {
    title: 'Freshly Cooked',
    label: 'Select',
    value: 'fresh',
    description: 'Slow-cooked real meat and veggies.',
    img: {
      src: freshRecipesImage.src,
      alt: 'fresh recipe',
    },
    pricingInfo: {
      discount: '$37.50',
      price: '$75.00/week',
    },
    badge: 'popular',
  },
  {
    title: 'a mixed bowl',
    label: 'Select Mixed',
    value: 'mixed',
    description: 'Half Fresh. Half Baked. Whole food.',
    img: {
      src: mixedRecipesImage.src,
      alt: 'mixed recipe',
    },
    pricingInfo: {
      discount: '$37.50',
      price: '$75.00/week',
    },
    badge: 'new',
  },
  {
    title: 'Gently baked',
    label: 'Select Baked',
    value: 'dry',
    description: 'Gently baked real meat and veggies.',
    img: {
      src: dryRecipesImage.src,
      alt: 'dry recipe',
    },
    pricingInfo: {
      discount: '$37.50',
      price: '$75.00/week',
    },
    badge: 'new',
  },
];

export const RECIPES_SUMMARY = {
  title: 'All recipes',
  sections: [
    {
      sectionHeader: 'Freshly Cooked',
      sectionHeaderIcon: { src: freshPackIcon.src, alt: 'fresh-pack-icon' },
      recipeLinks: [
        { img: freshBeefBowlImg, text: 'beef', href: '/fresh-dog-food/beef' },
        {
          img: freshChickenBowlImg,
          text: 'chicken',
          href: '/fresh-dog-food/chicken',
        },
        {
          img: freshTurkeyBowlImg,
          text: 'turkey',
          href: '/fresh-dog-food/turkey',
        },
        { img: freshLambBowlImg, text: 'lamb', href: '/fresh-dog-food/lamb' },
      ],
      ctaHref: '#',
      ctaButtonText: 'See all',
      ctaButtonVariant: 'egg',
      description:
        'Whole food ingredients, slow-cooked for nutrition and flavor and frozen for freshness.',
    },
    {
      sectionHeader: 'Gently Baked',
      sectionHeaderIcon: { src: dryPackIcon.src, alt: 'dry-pack-icon' },
      recipeLinks: [
        { img: dryBeefBowl, text: 'beef', href: '/baked-dog-food/beef' },
        {
          img: dryChickenBowl,
          text: 'chicken',
          href: '/baked-dog-food/chicken',
        },
      ],
      ctaHref: '#',
      ctaButtonText: 'See all',
      ctaButtonVariant: 'egg',
      description:
        'Real meat and veggies, gently baked in small batches for crunch and convenience.',
    },
  ],
};

export const RECIPES_CONTENT = {
  recipeImgs: {
    lamb: lambImg,
    chicken: chickenImg,
    beef: beefImg,
    turkey: turkeyImg,
  },
  recipeDescriptions: {
    beef: `Let your dog heed the cattle call! We’ve wrangled up a meaty feast
          that’s based on all things beef, including several organ meats that
          pack an extra nutritive punch. See what else we’ve included, like
          sweet potatoes, peas, and rosemary.`,
    chicken: `Here's a meal any dog would cross the road for. Inspired by your
              classic pot pie, this dish is filled with chicken and peas,
              plus nutritional powerhouses like blueberries and carrots. Take a gander
              at other key ingredients like cod liver oil and spinach.`,
    turkey: `What if Thanksgiving came twice a day? Your pup's sure to gobble up this
            meal that's built around heart-healthy turkey, along with vitamin-rich pumpkin
            and carrots. Be sure to check out all the ingredients, including superfoods like chia seeds.`,
    lamb: `If your dog's ready for some sheep thrills, we suggest this meal centered around
          lamb and butternut squash. Perfect for pups with allergies, this feast also contains
           nutrient-dense cranberries and kale. Check out the ingredients for more details.`,
  },
};

export const RECIPE_NAMES = ['Beef', 'Chicken', 'Turkey', 'Lamb'];

export const RECIPE_INFORMATION_SECTION_DATA = {
  headline: 'Everything else you need to know about Beef.',
  subheadline: 'Real Food with nothing to hide.',
  recipes: [
    {
      title: 'Beef',
      description:
        'Let your dog heed the cattle call! We’ve wrangled up a meaty feast that’s based on all things beef, including several organ meats that pack an extra nutritive punch. See what else we’ve included, like sweet potatoes, peas, and rosemary.',
      backgroundColor: 'hero.sweetPotato',
      buttonVariant: 'sweetPotato',
      icon: {
        alt: 'Beef outline icon',
        src: beefOutlineIcon,
        width: 45,
        height: 30,
      },
      image: {
        src: packIngredientsFreshBeefImg,
        alt: 'Arrangement of beef food packs and ingredients',
      },
      details: [
        {
          title: 'Key Ingredients',
          body: [
            {
              name: 'Sweet Potatoes',
              description: 'High in essential minerals and dietary fiber.',
              image: {
                src: sweetPotatoIcon,
                alt: 'Sweet potato illustration',
                width: 84,
                height: 29,
              },
            },
            {
              name: 'Peas',
              description:
                'Good source of lutein, for skin, eye, and heart health.',
              image: {
                src: peasIcon,
                alt: 'Peas illustration',
                width: 70,
                height: 33,
              },
            },
            {
              name: 'Rosemary',
              description: 'Safe, natural anti-microbial',
              image: {
                src: rosemaryIcon,
                alt: 'Rosemary illustration',
                width: 84,
                height: 29,
              },
            },
          ].map(item => <RecipeIngredient key={item.name} {...item} />),
        },
        {
          title: 'Full Ingredients',
          body: (
            <p>
              Beef, peas, sweet potato, potato, carrot, beef kidney, beef liver,
              spinach, blueberries, dicalcium phosphate, chia seeds, iodized
              salt, fish oil, zinc gluconate, rosemary, vitamin E supplement,
              pyridoxine hydrochloride (vitamin B6), riboflavin (vitamin B2),
              potassium iodide
            </p>
          ),
        },
        {
          title: 'Nutritional Info',
          body: (
            <>
              <h4>Guaranteed Analysis</h4>
              <table>
                <tbody>
                  <tr>
                    <td>Protein</td>
                    <td>min</td>
                    <td>9%</td>
                  </tr>
                  <tr>
                    <td>Fat</td>
                    <td>min</td>
                    <td>7%</td>
                  </tr>
                  <tr>
                    <td>Fiber</td>
                    <td>max</td>
                    <td>2%</td>
                  </tr>
                  <tr>
                    <td>Moisture</td>
                    <td>max</td>
                    <td>70%</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Beef Recipe is formulated to meet the nutritional levels
                established by the AAFCO Dog Food Nutrient Profiles for All Life
                Stages, including growth of large size Dogs (greater than 70
                pounds or more as an adult). Calorie content (calculated): 1540
                kcal ME/kg.
              </p>
            </>
          ),
        },
      ],
      link: {
        text: 'Order Now',
        target: '/onboarding/',
      },
    },
    {
      title: 'Chicken',
      description:
        "Here's a meal any dog would cross the road for. Inspired by your classic pot pie, this dish is filled with chicken and peas, plus nutritional powerhouses like blueberries and carrots. Take a gander at other key ingredients like cod liver oil and spinach.",
      backgroundColor: 'hero.spinach',
      buttonVariant: 'spinach',
      icon: {
        alt: 'Chicken outline icon',
        src: chickenOutlineIcon,
        width: 60,
        height: 30,
      },
      image: {
        src: freshChickenImg,
        alt: 'Arrangement of chicken food packs and ingredients',
      },
      details: [
        {
          title: 'Key Ingredients',
          body: [
            {
              name: 'Carrots',
              description: 'Full of phytonutrients for eye health.',
              image: {
                src: carrotsIcon,
                alt: 'Carrots illustration',
                width: 90,
                height: 38,
              },
            },
            {
              name: 'Spinach',
              description: 'High in iron and antioxidants.',
              image: {
                src: spinachIcon,
                alt: 'Spinach illustration',
                width: 62,
                height: 35,
              },
            },
            {
              name: 'White Rice',
              description: 'Full of phytonutrients for eye health.',
              image: {
                src: whiteRiceIcon,
                alt: 'White rice illustration',
                width: 72,
                height: 32,
              },
            },
          ].map(item => <RecipeIngredient key={item.name} {...item} />),
        },
        {
          title: 'Full Ingredients',
          body: (
            <p>
              Chicken, carrots, peas, rice, chicken liver, potatoes, spinach,
              dicalcium phosphate, whole dried eggs, fish oil, blueberries, chia
              seeds, calcium carbonate, iodized salt, cod liver oil, zinc
              gluconate, rosemary, copper gluconate, choline bitartrate, vitamin
              E supplement, manganese gluconate, potassium iodide, pyridoxine
              hydrochloride (vitamin B6), riboflavin (vitamin B2)
            </p>
          ),
        },
        {
          title: 'Nutritional Info',
          body: (
            <>
              <h4>Guaranteed Analysis</h4>
              <table>
                <tbody>
                  <tr>
                    <td>Protein</td>
                    <td>min</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>Fat</td>
                    <td>min</td>
                    <td>3%</td>
                  </tr>
                  <tr>
                    <td>Fiber</td>
                    <td>max</td>
                    <td>2%</td>
                  </tr>
                  <tr>
                    <td>Moisture</td>
                    <td>max</td>
                    <td>73%</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Our Chicken Recipe is formulated to meet the nutritional levels
                established by the AAFCO Dog Food Nutrient Profiles for All Life
                Stages, including growth of large size Dogs (greater than 70
                pounds or more as an adult). Calorie content (calculated): 1298
                kcal ME/kg.
              </p>
            </>
          ),
        },
      ],
      link: {
        text: 'Order Now',
        target: '/onboarding/',
      },
    },
    {
      title: 'Lamb',
      description:
        "If your dog's ready for some sheep thrills, we suggest this meal centered around lamb and butternut squash. Perfect for pups with allergies, this feast also contains nutrient-dense cranberries and kale. Check out the ingredients for more details.",
      backgroundColor: 'hero.cranberry',
      buttonVariant: 'cranberry',
      icon: {
        alt: 'Lamb outline icon',
        src: lambOutlineIcon,
        width: 57,
        height: 29,
      },
      image: {
        src: freshLambImg,
        alt: 'Arrangement of lamb food packs and ingredients',
      },
      details: [
        {
          title: 'Key Ingredients',
          body: [
            {
              name: 'Butternut Squash',
              description: 'Vitamins, minerals, and fiber for digestion.',
              image: {
                src: butternutSquashIcon,
                alt: 'Butternut squash illustration',
                width: 84,
                height: 29,
              },
            },
            {
              name: 'Kale',
              description: 'High in beta carotene for healthy skin and fur.',
              image: {
                src: kaleIcon,
                alt: 'Kale illustration',
                width: 73,
                height: 39,
              },
            },
          ].map(item => <RecipeIngredient key={item.name} {...item} />),
        },
        {
          title: 'Full Ingredients',
          body: (
            <p>
              Lamb, butternut squash, lamb liver, kale, rice, chickpeas,
              chickpea flour, cranberries, green beans, potatoes, dicalcium
              phosphate, chia seeds, cod liver oil, iodized salt, calcium
              carbonate, zinc gluconate, taurine, vitamin E supplement, iron
              sulfate, pantothenic acid, manganese gluconate, thiamin
              hydrochloride, pyridoxine hydrochloride (vitamin B6), potassium
              iodate, folic acid
            </p>
          ),
        },
        {
          title: 'Nutritional Info',
          body: (
            <>
              <h4>Guaranteed Analysis</h4>
              <table>
                <tbody>
                  <tr>
                    <td>Protein</td>
                    <td>min</td>
                    <td>10%</td>
                  </tr>
                  <tr>
                    <td>Fat</td>
                    <td>min</td>
                    <td>7%</td>
                  </tr>
                  <tr>
                    <td>Fiber</td>
                    <td>max</td>
                    <td>2%</td>
                  </tr>
                  <tr>
                    <td>Moisture</td>
                    <td>max</td>
                    <td>74%</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Our Lamb Recipe is formulated to meet the nutritional levels
                established by the AAFCO Dog Food Nutrient Profiles for All Life
                Stages, including growth of large size Dogs (greater than 70
                pounds or more as an adult). Calorie content (calculated): 1804
                kcal ME/kg.
              </p>
            </>
          ),
        },
      ],
      link: {
        text: 'Order Now',
        target: '/onboarding/',
      },
    },
    {
      title: 'Turkey',
      notice:
        'Our turkey recipe is currently unavailable due to a national turkey shortage and supply chain disruption. In the meantime, enjoy our other healthy recipes.',
      description:
        "What if Thanksgiving came twice a day? Your pup's sure to gobble up this meal that's built around heart-healthy turkey, along with vitamin-rich pumpkin and carrots. Be sure to check out all the ingredients, including superfoods like chia seeds.",
      backgroundColor: 'secondary.chia',
      buttonVariant: 'chia',
      icon: {
        alt: 'Turkey outline icon',
        src: turkeyOutlineIcon,
        width: 45,
        height: 34,
      },
      image: {
        src: freshTurkey,
        alt: 'Arrangement of turkey food packs and ingredients',
      },
      details: [
        {
          title: 'Key Ingredients',
          body: [
            {
              name: 'Carrots',
              description: 'Full of phytonutrients for eye health.',
              image: {
                src: carrotsIcon,
                alt: 'Carrots illustration',
                width: 90,
                height: 38,
              },
            },
            {
              name: 'Blueberries',
              description: 'High in antioxidents that fight free radicals.',
              image: {
                src: blueberriesIcon,
                alt: 'Blueberries illustration',
                width: 60,
                height: 30,
              },
            },
            {
              name: 'Chia seeds',
              description: 'Great source of copper, zinc, and manganese.',
              image: {
                src: chiaSeedsIcon,
                alt: 'Chia seeds illustration',
                width: 66,
                height: 41,
              },
            },
          ].map(item => <RecipeIngredient key={item.name} {...item} />),
        },
        {
          title: 'Full Ingredients',
          body: (
            <p>
              Turkey breast, kale, lentils, carrots, coconut oil, turkey liver,
              blueberries, pumpkin, dicalcium phosphate, chia seeds, iodized
              salt, calcium carbonate, cod liver oil, zinc gluconate, iron
              sulfate, choline bitartrate, manganese gluconate, manganese
              sulfate, copper gluconate, vitamin E supplement, thiamin,
              pyridoxine hydrochloride (vitamin B6), potassium iodate
            </p>
          ),
        },
        {
          title: 'Nutritional Info',
          body: (
            <>
              <h4>Guaranteed Analysis</h4>
              <table>
                <tbody>
                  <tr>
                    <td>Protein</td>
                    <td>min</td>
                    <td>11%</td>
                  </tr>
                  <tr>
                    <td>Fat</td>
                    <td>min</td>
                    <td>7%</td>
                  </tr>
                  <tr>
                    <td>Fiber</td>
                    <td>max</td>
                    <td>2%</td>
                  </tr>
                  <tr>
                    <td>Moisture</td>
                    <td>max</td>
                    <td>72%</td>
                  </tr>
                </tbody>
              </table>
              <p>
                Our Turkey Recipe is formulated to meet the nutritional levels
                established by the AAFCO Dog Food Nutrient Profiles for All Life
                Stages, including growth of large size Dogs (greater than 70
                pounds or more as an adult). Calorie content (calculated): 1390
                kcal ME/kg.
              </p>
            </>
          ),
        },
      ],
    },
  ],
};

export const RECIPE_DATA = {
  turkey: {
    name: 'Healthy Turkey Feast',

    nutrients: [
      { name: 'Protein', percent: '12.00', level: 'min' },
      { name: 'Fat', percent: '9.50', level: 'min' },
      { name: 'Fiber', percent: '2.00', level: 'max' },
      { name: 'Moisture', percent: '67.50', level: 'max' },
    ],
    description:
      'Our Turkey Recipe is formulated to meet the nutritional levels established by the AAFCO Dog Food Nutrient Profiles for All Life Stages, including growth of large size Dogs (greater than 70 pounds or more as an adult). Calorie content (calculated): 1390 kcal ME/kg.',
    recipe_code: 'turkey',
    ingredient_label:
      'Turkey breast, turkey liver, kale, carrots, lentils, blueberries, coconut oil, pumpkin, chia seeds, dicalcium phosphate, iodized salt, zinc gluconate, cod liver oil, iron sulfate, manganese gluconate, manganese sulfate, copper gluconate, vitamin E powder, thiamin HCL, potassium iodate',
  },
  beef: {
    name: 'Hearty Beef Eats',
    nutrients: [
      { name: 'Protein', percent: '12.00', level: 'min' },
      { name: 'Fat', percent: '9.50', level: 'min' },
      { name: 'Fiber', percent: '2.00', level: 'max' },
      { name: 'Moisture', percent: '67.50', level: 'max' },
    ],
    description:
      'Hearty Beef Eats is formulated to meet the nutritional levels established by the AAFCO Dog Food Nutrient Profiles for All Life Stages, including growth of large size Dogs (greater than 70 pounds or more as an adult).',
    recipe_code: 'beef',
    ingredient_label:
      'Beef, beef heart, sweet potato, peas, potato, beef kidney, carrot, beef liver, spinach, chia seed, dicalcium phosphate, blueberries, fish oil (preserved with tocopherols), iodized salt, zinc gluconate, rosemary, vitamin E supplement, pyridoxine hydrochloride (vitamin B6), riboflavin (vitamin B2), potassium iodide',
  },
  lamb: {
    name: 'Tasty Lamb Fare',
    nutrients: [
      { name: 'Protein', percent: '11.00', level: 'min' },
      { name: 'Fat', percent: '8.50', level: 'min' },
      { name: 'Fiber', percent: '1.50', level: 'max' },
      { name: 'Moisture', percent: '70.00', level: 'max' },
    ],
    description:
      'Our Lamb Recipe is formulated to meet the nutritional levels established by the AAFCO Dog Food Nutrient Profiles for All Life Stages, including growth of large size Dogs (greater than 70 pounds or more as an adult). Calorie content (calculated): 1804 kcal ME/kg.',
    recipe_code: 'lamb',
    ingredient_label:
      'Lamb, lamb liver, butternut squash,  kale, chickpeas, cranberries, potato flakes, chia seeds, dicalcium phosphate, iodized salt, calcium carbonate, zinc gluconate, taurine, vitamin E, iron sulfate, pantothenic acid, potassium iodate, manganese gluconate, thiamin HCL, folic acid',
  },
  chicken: {
    name: 'Chicken Goodness',
    nutrients: [
      { name: 'Protein', percent: '10.00', level: 'min' },
      { name: 'Fat', percent: '3.50', level: 'min' },
      { name: 'Fiber', percent: '2.00', level: 'max' },
      { name: 'Moisture', percent: '72.00', level: 'max' },
    ],
    description:
      'Our Chicken Recipe is formulated to meet the nutritional levels established by the AAFCO Dog Food Nutrient Profiles for All Life Stages, including growth of large size Dogs (greater than 70 pounds or more as an adult). Calorie content (calculated): 1298 kcal ME/kg.',
    recipe_code: 'chicken',
    ingredient_label:
      'Chicken, chicken gizzard, carrots, peas, chicken liver, rice, chia seeds, spinach, potatoes, whole dried eggs, dicalcium phosphate, calcium carbonate, blueberries, fish oil, Iodized salt, cod liver oil, zinc gluconate, rosemary, copper gluconate, vitamin e, potassium Iodide, pyridoxine hydrochloride (vitamin B6),  riboflavin (vitamin B2)',
  },
};

export const RECIPE_INFO_TABS = [
  'Key Ingredients',
  'Full Ingredients',
  'Nutritional Info',
];

export const RECIPE_BACKGROUND_COLORS = {
  beef: '#FED9DB',
  turkey: '#9E8FCC',
  chicken: '#AAD6DD',
  lamb: '#FCC54E',
};

export const SUBSCRIPTION_CARD_IMAGE_DATA = {
  beef: {
    alt: "Bowl of Ollie's beef recipe",
    src: freshBeefBowlImg,
    width: 512,
    height: 512,
    srcSet: [
      {
        src: freshBeefBowlImg,
        width: 256,
        height: 256,
      },
      {
        src: freshBeefBowlImg,
        width: 512,
        height: 512,
      },
      {
        src: freshBeefBowlImg,
        width: 768,
        height: 768,
      },
    ],
    sizes: '256px',
  },
  chicken: {
    alt: "Bowl of Ollie's chicken recipe",
    src: freshChickenBowlImg,
    width: 512,
    height: 512,
    srcSet: [
      {
        src: freshChickenBowlImg,
        width: 256,
        height: 256,
      },
      {
        src: freshChickenBowlImg,
        width: 512,
        height: 512,
      },
      {
        src: freshChickenBowlImg,
        width: 768,
        height: 768,
      },
    ],
    sizes: '256px',
  },
  lamb: {
    alt: "Bowl of Ollie's lamb recipe",
    src: freshLambBowlImg,
    width: 512,
    height: 512,
    srcSet: [
      {
        src: freshLambBowlImg,
        width: 256,
        height: 256,
      },
      {
        src: freshLambBowlImg,
        width: 512,
        height: 512,
      },
      {
        src: freshLambBowlImg,
        width: 768,
        height: 768,
      },
    ],
    sizes: '256px',
  },
  turkey: {
    alt: "Bowl of Ollie's turkey recipe",
    src: freshTurkeyBowlImg,
    width: 512,
    height: 512,
    srcSet: [
      {
        src: freshTurkeyBowlImg,
        width: 256,
        height: 256,
      },
      {
        src: freshTurkeyBowlImg,
        width: 512,
        height: 512,
      },
      {
        src: freshTurkeyBowlImg,
        width: 768,
        height: 768,
      },
    ],
    sizes: '256px',
  },
  allRecipes: {
    alt: "Full collection of Ollie's recipes",
    src: bowlsCardDx2Img,
    width: 1212,
    height: 260,
    picture: true,
    srcSet: [
      {
        srcSet: [
          {
            src: bowlsCardMImg,
            width: 257,
            height: 200,
          },
          {
            src: bowlsCardMx2Img,
            width: 514,
            height: 400,
          },
          {
            src: bowlsCardMx3Img,
            width: 771,
            height: 600,
          },
        ],
        media: '(max-width: 1024px)',
        sizes: '100%',
      },
      {
        srcSet: [
          {
            src: bowlsCardDImg,
            width: 606,
            height: 130,
          },
          {
            src: bowlsCardDx2Img,
            width: 1212,
            height: 260,
          },
          {
            src: bowlsCardDx3Img,
            width: 1818,
            height: 390,
          },
        ],
        sizes: '100%',
      },
    ],
  },
};

export const PRESS_LOGOS = [
  {
    src: marthaStewartLogo.src,
    width: marthaStewartLogo.width,
    height: marthaStewartLogo.height,
    alt: 'Martha Stewart press logo',
  },
  {
    src: gmaLogo.src,
    width: gmaLogo.width,
    height: gmaLogo.height,
    alt: 'Good Morning America press logo',
  },
  {
    src: vogueLogo.src,
    width: vogueLogo.width,
    height: vogueLogo.height,
    alt: 'Vogue press logo',
  },
  {
    src: todayShowLogo.src,
    width: todayShowLogo.width,
    height: todayShowLogo.height,
    alt: 'Today Show press logo',
  },
  {
    src: forbesLogo.src,
    width: forbesLogo.width,
    height: forbesLogo.height,
    alt: 'Forbes press logo',
  },
  {
    src: businessInsiderLogo.src,
    width: businessInsiderLogo.width,
    height: businessInsiderLogo.height,
    alt: 'Business Insider press logo',
  },
];

export const PRESS_LOGOS_WITH_QUOTES = [
  {
    src: petLifeTodayLogo.src,
    width: petLifeTodayLogo.width,
    height: petLifeTodayLogo.height,
    alt: 'Pet Life Today Logo',
    quote: '“Most customizable dog food delivery service”',
  },
  {
    src: forbesLogo.src,
    width: forbesLogo.width,
    height: forbesLogo.height,
    alt: 'Forbes Logo',
    quote: '“Most convenient packaging”',
  },
  {
    src: readersDigest.src,
    width: readersDigest.width,
    height: readersDigest.height,
    alt: "Reader's Digest Logo",
    quote: '“Best rigorously tested dog food”',
  },
];

export const PRESS_LOGOS_WITHOUT_QUOTES = [
  {
    src: petLifeTodayLogo.src,
    width: petLifeTodayLogo.width,
    height: petLifeTodayLogo.height,
    alt: 'Pet Life Today Logo',
    quote: '',
  },
  {
    src: forbesLogo.src,
    width: forbesLogo.width,
    height: forbesLogo.height,
    alt: 'Forbes Logo',
    quote: '',
  },
  {
    src: readersDigest.src,
    width: readersDigest.width,
    height: readersDigest.height,
    alt: "Reader's Digest Logo",
    quote: '',
  },
  {
    src: businessInsiderLogo.src,
    width: businessInsiderLogo.width,
    height: businessInsiderLogo.height,
    alt: 'Pet Life Today Logo',
    quote: '',
  },
];

export const COMPARISON_FEATURES = [
  { header: 'All natural food', subheader: 'All natural food' },
  { header: 'No corn, Wheat, or Soy' },
  { header: 'No Artifical Colors, Flavors or Preservatives' },
  { header: 'Real Food, Regularly Delivered' },
  { header: 'Veterinarian Recommended' },
  { header: 'Supports Animal Shelters', subheader: 'Supports Animal Shelters' },
];

export const US_STATE_OPTIONS = [
  {
    value: 'AL',
    label: 'AL',
  },
  {
    value: 'AR',
    label: 'AR',
  },
  {
    value: 'AZ',
    label: 'AZ',
  },
  {
    value: 'CA',
    label: 'CA',
  },
  {
    value: 'CO',
    label: 'CO',
  },
  {
    value: 'CT',
    label: 'CT',
  },
  {
    value: 'DE',
    label: 'DE',
  },
  {
    value: 'FL',
    label: 'FL',
  },
  {
    value: 'GA',
    label: 'GA',
  },
  {
    value: 'IA',
    label: 'IA',
  },
  {
    value: 'ID',
    label: 'ID',
  },
  {
    value: 'IL',
    label: 'IL',
  },
  {
    value: 'IN',
    label: 'IN',
  },
  {
    value: 'KS',
    label: 'KS',
  },
  {
    value: 'KY',
    label: 'KY',
  },
  {
    value: 'LA',
    label: 'LA',
  },
  {
    value: 'MA',
    label: 'MA',
  },
  {
    value: 'MD',
    label: 'MD',
  },
  {
    value: 'ME',
    label: 'ME',
  },
  {
    value: 'MI',
    label: 'MI',
  },
  {
    value: 'MN',
    label: 'MN',
  },
  {
    value: 'MO',
    label: 'MO',
  },
  {
    value: 'MS',
    label: 'MS',
  },
  {
    value: 'MT',
    label: 'MT',
  },
  {
    value: 'NC',
    label: 'NC',
  },
  {
    value: 'ND',
    label: 'ND',
  },
  {
    value: 'NE',
    label: 'NE',
  },
  {
    value: 'NH',
    label: 'NH',
  },
  {
    value: 'NJ',
    label: 'NJ',
  },
  {
    value: 'NM',
    label: 'NM',
  },
  {
    value: 'NV',
    label: 'NV',
  },
  {
    value: 'NY',
    label: 'NY',
  },
  {
    value: 'OH',
    label: 'OH',
  },
  {
    value: 'OK',
    label: 'OK',
  },
  {
    value: 'OR',
    label: 'OR',
  },
  {
    value: 'PA',
    label: 'PA',
  },
  {
    value: 'RI',
    label: 'RI',
  },
  {
    value: 'SC',
    label: 'SC',
  },
  {
    value: 'SD',
    label: 'SD',
  },
  {
    value: 'TN',
    label: 'TN',
  },
  {
    value: 'TX',
    label: 'TX',
  },
  {
    value: 'UT',
    label: 'UT',
  },
  {
    value: 'VA',
    label: 'VA',
  },
  {
    value: 'VT',
    label: 'VT',
  },
  {
    value: 'WA',
    label: 'WA',
  },
  {
    value: 'WI',
    label: 'WI',
  },
  {
    value: 'WV',
    label: 'WV',
  },
  {
    value: 'WY',
    label: 'WY',
  },
];

export const SUBSCRIPTION_CARD_DATA = [
  {
    key: 'multi',
    header: 'Mixed Recipe Box',
    featured: true,
    image: SUBSCRIPTION_CARD_IMAGE_DATA.allRecipes,
    link: {
      text: 'Learn more',
      target: '/products/multi-box',
    },
    body: (
      <>
        Choose up to four recipes — each one nutritiously balanced and cooked
        fresh for your pup.
      </>
    ),
  },
  {
    key: 'beef',
    header: 'Beef',
    image: SUBSCRIPTION_CARD_IMAGE_DATA.beef,
    link: {
      text: 'Learn more',
      target: '/products/beef-recipe',
    },
    body: (
      <>A popular pick that’s packed with protein, vitamins, and minerals.</>
    ),
  },

  {
    key: 'turkey',
    header: 'Turkey',
    image: SUBSCRIPTION_CARD_IMAGE_DATA.turkey,
    link: {
      text: 'Learn more',
      target: '/products/turkey-recipe',
    },
    body: <>A lean protein, perfect for healthy muscle and tissue building.</>,
  },
  {
    key: 'chicken',
    header: 'Chicken',
    image: SUBSCRIPTION_CARD_IMAGE_DATA.chicken,
    link: {
      text: 'Learn more',
      target: '/products/chicken-recipe',
    },
    body: <>A gentle, yet balanced, blend of chicken and veggies.</>,
  },
  {
    key: 'lamb',
    header: 'Lamb',
    image: SUBSCRIPTION_CARD_IMAGE_DATA.lamb,
    link: {
      text: 'Learn more',
      target: '/products/lamb-recipe',
    },
    body: <>An adventurous recipe for adventurous, active pups.</>,
  },
];

export const SIMPLIFIED_FOOTER_CONTENT = {
  variant: 'simplified',
  message:
    'If you are using a screen reader and having problems using this website, please call 1-844-88-OLLIE (65543) for assistance.',
  copyright: '© 2021 Ollie Pets Inc.',
  bottomLinks: [
    {
      text: 'Privacy Policy',
      path: '/privacy-policy',
    },
    {
      text: 'Terms',
      path: '/terms',
    },
  ],
};

export const FOOTER_CONTENT = {
  isAccount: true,
  variant: 'full',
  message:
    'If you are using a screen reader and having problems using this website, please call 1-844-88-OLLIE (65543) for assistance.',
  copyright: '© 2021 Ollie Pets Inc.',
  bottomLinks: [
    {
      text: 'Privacy',
      path: '/privacy-policy',
    },
    {
      text: 'Terms',
      path: '/terms',
    },
  ],
  columnLinks: [
    {
      title: 'About',
      links: [
        {
          text: 'Our Story',
          path: '/about',
          icon: null,
        },
        {
          text: 'Our Press',
          path: '/press',
          icon: null,
        },
        {
          text: 'Careers',
          path: 'https://jobs.lever.co/myollie',
          icon: null,
        },
        {
          text: 'Promotions',
          path: '/promo-code',
          icon: null,
        },
        {
          text: 'Affiliates',
          path: '/affiliate',
          icon: null,
        },
        {
          text: 'Learning Resources',
          path: '/learning-resources',
          icon: null,
        },
      ],
    },
    {
      title: 'Support',
      businessHours: (
        <>
          <p>Monday – Friday:</p>
          <p>9 AM — 8 PM ET</p>
          <p>Saturday:</p>
          <p>10 AM - 7 PM ET</p>
          <p>Sunday: CLOSED</p>
        </>
      ),
      links: [
        {
          text: 'Canine Support Center',
          path: '/faqs',
          icon: {
            src: helpIcon.src,
            alt: 'Help icon',
          },
        },
        {
          text: 'caninecare@myollie.com',
          path: 'mailto:caninecare@myollie.com',
          icon: {
            src: mailIcon.src,
            alt: 'Mail icon',
          },
        },
        {
          text: '844-88-OLLIE (65543)',
          path: 'tel:+18448865543',
          icon: {
            src: phoneIcon.src,
            alt: 'Phone icon',
          },
        },
        {
          text: 'press@myollie.com',
          path: 'mailto:press@myollie.com',
          icon: {
            src: mailIcon.src,
            alt: 'Mail icon',
          },
        },
      ],
      legalBlurb: (
        <>
          <p>
            *By texting us, you consent to receive text messages from Ollie at
            the mobile number you used to text and you are opting-in to receive
            future messages or a phone call at the number you provided. Message
            & Data rates may apply. Reply STOP anytime to opt-out.
          </p>
        </>
      ),
    },
    {
      title: 'Get Started',
      hideIfAccount: true,
      links: [
        {
          text: 'Login',
          path: '/auth/login/',
          icon: null,
        },
        {
          text: 'Take the quiz',
          path: '/onboarding',
          icon: null,
        },
      ],
    },
  ],
};

//CMSable Homepage
export const HOME_CMS_VALUES = {
  valueProps: {
    headline: (
      <>
        To know them is to love them — and feed them well.
        <br />
        At <img src={ollieLogo.src} alt="Ollie" />, we get to know who your dog
        is to design a meal plan made for them.
      </>
    ),
    reasons: [
      {
        image: {
          alt: 'carrot and steak moving in and out of a dog bowl',
          src: carrotOnForkIcon,
          width: 110,
          height: 110,
        },
        headline: 'Quality ingredients',
        subheadline: '100% Human-grade food cooked just for your pup.',
      },
      {
        image: {
          alt: 'three dogs of different sizes wagging their tails',
          src: pettingDogIcon,
          width: 110,
          height: 110,
        },
        headline: 'Balanced for all pups',
        subheadline:
          'Four vet-crafted recipes for all ages, breeds, and sizes.',
      },
      {
        image: {
          alt: 'a box of ollie that sparkles',
          src: ollieBoxIcon,
          width: 110,
          height: 110,
        },
        headline: 'Convenient delivery',
        subheadline: 'Free two-day shipping you can depend on.',
      },
    ],
  },
};

export const HOW_IT_WORKS_CMS_VALUES = {
  valueProps: {
    headline: 'How Ollie Works',
    reasons: [
      {
        headline: 'Introduce us to your pup',
        subheadline:
          'We’ve distilled our quiz down to the essential questions so you can spend less time online and more time playing fetch.',
      },
      {
        headline: 'Get your starter box',
        subheadline: (
          <>
            Your starter box arrives with the first two weeks of food and the
            tools you need for easy feeding at{' '}
            <TextCallout text="50% off" label="View details">
              Regular billing will automatically begin after two weeks. If your
              dog doesn’t love their food, we’ll fully refund your starter box.
            </TextCallout>
          </>
        ),
      },
      {
        headline: 'Real food, regularly delivered',
        subheadline:
          'No more last-minute trips to the pet store — we deliver your pup’s plan to your door on your schedule.',
      },
    ],
  },
};

export const RECIPES_CMS_VALUES = {
  valueProps: {
    eyebrow: 'Our Food',
    headline:
      "Ollie's fresh food is safe enough for humans to eat, but especially designed for your dog. Fur real.",
    reasons: [
      {
        headline: 'Minimal Processing',
        subheadline: 'Whole foods, gently cooked to preserve nutrients.',
        image: {
          alt: 'Outline of a leaf',
          src: minimalProcessingIcon,
          height: 70,
          width: 70,
        },
      },
      {
        headline: 'Human-grade',
        subheadline:
          'You could eat it with a fork. (But save it for your pup!)',
        image: {
          alt: 'Outline of a fork and knife',
          src: humanGradeIcon,
          height: 70,
          width: 70,
        },
      },
      {
        headline: 'No artificial flavors',
        subheadline: 'Fresh food tastes amazing on its own.',
        image: {
          alt: 'Outline of a crossed out droplet',
          src: noArtificialFlavorsIcon,
          height: 70,
          width: 70,
        },
      },
      {
        headline: 'No fillers',
        subheadline: 'Ollie contains zero soy, corn, or wheat.',
        image: {
          alt: 'Outline of a crossed out grain',
          src: noFillersIcon,
          height: 70,
          width: 70,
        },
      },
    ],
  },
};

export const SPLIT_SCREEN_GRID_CMS_VALUES = {
  starterBox: {
    splashImage: {
      src: multiRecipesPackSplashx2Img,
      alt: 'Packs of four Ollie recipes in ice',
      width: 1440,
      height: 1120,
      srcSet: [
        {
          src: multiRecipesPackSplashImg,
          pixelDensity: 1,
          width: 1440,
          height: 1120,
        },
      ],
    },
    gridItems: [
      {
        headline: 'Food packs',
        image: foodPackIcon.src,
        alt: 'Ollie food packs',
        text: 'Easy to open; easy to serve.',
        backgroundbackgroundColor: '#FFEEEC',
      },
      {
        headline: 'Feeding guide',
        image: feedingGuidelineIcon.src,
        alt: "Your pup's feeding guide",
        text: 'Customized to your pup’s unique needs.',
        backgroundbackgroundColor: '#FFEEEC',
      },
      {
        headline: 'Pup-tainer',
        image: puptainerIcon.src,
        alt: 'Ollie food storage container',
        text: 'Keeps freshness in and sneaky pups out.',
        backgroundbackgroundColor: '#FFEEEC',
        badge: 'FREE',
      },
      {
        headline: 'Scoop',
        image: scoopIcon.src,
        alt: 'Ollie food scoop',
        text: 'For perfect poritioning every time.',
        backgroundbackgroundColor: '#FFEEEC',
        badge: 'FREE',
      },
    ],
  },
  realIngredients: {
    splashImage: {
      src: ollieFeastSplashx2Img,
      alt: 'A dazzling display of fresh Ollie ingredients',
      width: 1296,
      height: 1008,
      srcSet: [
        {
          src: ollieFeastSplashImg,
          pixelDensity: 1,
          width: 1440,
          height: 1120,
        },
      ],
    },
    gridItems: [
      {
        headline: 'High-quality animal protein',
        image: ingredientsTurkeyThighImg.src,
        alt: 'Turkey thigh',
        text: 'Real meat is the primary source of protein in every Ollie recipe to help your pup maintain and build healthy muscles and tissues. ',
      },
      {
        headline: 'Fruit',
        image: ingredientsBlueberriesImg.src,
        alt: 'Blueberries',
        text: 'Blueberries and cranberries pack a nutritive punch, providing your pup with vitamins and antioxidants.',
      },
      {
        headline: 'Plant-based protein',
        image: ingredientsRedLentilsImg.src,
        alt: 'Red lentils',
        text: 'Chickpeas, lentils, and peas pick up where meats leave off to offer complete and balanced protein essential to pups.',
      },
      {
        headline: 'Leafy greens and root vegetables',
        image: ingredientsKaleImg.src,
        alt: 'A head of kale',
        text: 'From kale to sweet potatoes, veggies are a rich source of dietary fiber for digestive health (good poops!)',
      },
    ],
  },
};

export const TIMELINE_SCROLL_PROMO_CMS_VALUES = {
  steps: [
    {
      headline: 'Tell us about your pup',
      text: 'Take our quick quiz and we’ll create a customized meal plan that works for you both.',
    },
    {
      headline: 'Snag your starter box',
      text: 'We’ll send you two weeks of food, plus everything you need to dig into Ollie.',
    },
    {
      headline: 'Ramp up your routine',
      text: 'Receive regularly scheduled deliveries of fresh food for your pup — no more last-minute trips to the store.',
    },
  ],
  splashImages: [
    {
      srcSet: [
        {
          src: howItWorks1Img,
          alt: 'Tiny puppy with a hand under its chin',
          pixelDensity: 1,
          width: 1200,
          height: 1200,
        },
      ],
      src: howItWorks1Img,
      alt: 'Tiny puppy with a hand under its chin',
      width: 1200,
      height: 1200,
    },
    {
      srcSet: [
        {
          src: howItWorks2Img,
          alt: 'Dog licking its mouth looking at Ollie',
          pixelDensity: 1,
          width: 1200,
          height: 1200,
        },
      ],
      src: howItWorks2Img,
      alt: 'Dog licking its mouth looking at Ollie',
      width: 1200,
      height: 1200,
    },
    {
      srcSet: [
        {
          src: howItWorks3Img,
          alt: 'Dog next to Ollie box outside of a house door',
          pixelDensity: 1,
          width: 1200,
          height: 1200,
        },
      ],
      src: howItWorks3Img,
      alt: 'Dog next to Ollie box outside of a house door',
      width: 1200,
      height: 1200,
    },
  ],
  link: {
    text: 'How It Works',
    target: '#',
  },
  badge: {
    srcSet: [
      {
        src: moneyBackIcon,
        alt: '100% Money-back guarantee',
        pixelDensity: 1,
        width: 118,
        height: 117,
      },
    ],
    src: moneyBackIcon.src,
    alt: '100% Money-back guarantee',
    width: 118,
    height: 117,
  },
};

export const BLOGS = [
  {
    topic: 'DOG NUTRITION',
    title: {
      position: 'left',
      typography: 'body',
      headingText: 'The 10 Best Ingredients for Dogs',
      headingLevel: 'h6',
    },
    date: '21.JUN.2021',
    image: {
      src: blogImage1,
      alt: 'nicely plated fresh ingredients on a pink tablecloth',
      width: 335,
      height: 190,
    },
    blogLink: 'https://blog.myollie.com/10-healthiest-dog-food-ingredients/',
    backgroundColor: 'background.lightest',
  },
  {
    topic: 'TRAINING ADVICE',
    title: {
      position: 'left',
      typography: 'body',
      headingText: 'What to Do if Your Dog Eats Too Fast and Then Throws Up',
      headingLevel: 'h6',
    },
    date: '28.FEB.2021',
    image: {
      src: blogImage2,
      alt: 'doggo eating their food with great enthusiasm and speed',
      width: 335,
      height: 190,
    },
    blogLink:
      'https://blog.myollie.com/how-to-slow-down-a-dog-who-eats-too-fast/',
    backgroundColor: 'background.lightest',
  },
  {
    topic: 'DOG HEALTH',
    title: {
      position: 'left',
      typography: 'body',
      headingText: "What your Dog's Poop Says About Their Health",
      headingLevel: 'h6',
    },
    date: '20.MAY.2021',
    image: {
      src: blogImage3,
      alt: 'drawing of dog waste bag dispenser with the words "Party Pooper" written on it',
      width: 335,
      height: 190,
    },
    blogLink:
      'https://blog.myollie.com/what-your-dogs-poop-says-about-their-health/',
    backgroundColor: 'background.lightest',
  },
];

export const MULTI_RECIPE_OPTIONS = [
  {
    label: 'Beef',
    value: 'beef',
  },
  {
    label: 'Chicken',
    value: 'chicken',
  },
  {
    label: 'Turkey',
    value: 'turkey',
  },
  {
    label: 'Lamb',
    value: 'lamb',
  },
];

export const RECIPES_VARIANTS = {
  fresh: [
    {
      sku: '2FR:20210621:A[0.5B1-0.5C1]',
      recipes: { fresh: ['beef', 'chicken', 'lamb', 'turkey'], dry: [] },
      discounted_price: '24',
      discounted_price_per_week: '12',
      price: '30',
      price_per_week: '15',
      price_per_day: '1.71',
      id: 1,
    },
    {
      sku: '2FR:20210621:A[0.5B1-0.5C2]',
      recipes: { fresh: ['beef'], dry: [] },
      discounted_price: '24',
      discounted_price_per_week: '12',
      price: '24',
      price_per_week: '12',
      id: 2,
    },
    {
      sku: '2FR:20210621:A[0.5B1-0.5C3]',
      recipes: { fresh: ['beef', 'lamb'], dry: [] },
      discounted_price: '30',
      discounted_price_per_week: '15',
      price: '30',
      price_per_week: '15',
      id: 7,
    },
    {
      sku: '2FR:20210621:A[0.5B1-0.5C3]',
      recipes: { fresh: ['lamb'], dry: [] },
      discounted_price: '25',
      discounted_price_per_week: '15',
      price: '30',
      price_per_week: '15',
      id: 3,
    },
    {
      sku: '2FR:20210621:A[0.5B1-0.5C4]',
      recipes: { fresh: ['beef', 'chicken'], dry: [] },
      discounted_price: '24',
      discounted_price_per_week: '12',
      price: '24',
      price_per_week: '12',
      id: 4,
    },
    {
      sku: '2FR:20210621:A[0.5B1-0.5C5]',
      recipes: { fresh: ['beef', 'turkey'], dry: [] },
      discounted_price: '24',
      discounted_price_per_week: '12',
      price: '24',
      price_per_week: '12',
      id: 5,
    },
    {
      sku: '2FR:20210621:A[0.5B1-0.5C5]',
      recipes: { fresh: ['chicken'], dry: [] },
      discounted_price: '20',
      discounted_price_per_week: '10',
      price: '20',
      price_per_week: '10',
      id: 6,
    },
  ],
  dry: [
    {
      sku: '2FR:20210621:A[0.5B1-0.5C2]',
      recipes: { fresh: [], dry: ['beef'] },
      discounted_price: '24',
      discounted_price_per_week: '12',
      price: '24',
      price_per_week: '12',
      id: 8,
    },
    {
      sku: '2FR:20210621:A[0.5B1-0.5C4]',
      recipes: { fresh: [], dry: ['beef', 'chicken'] },
      discounted_price: '24',
      discounted_price_per_week: '12',
      price: '24',
      price_per_week: '12',
      id: 9,
    },
    {
      sku: '2FR:20210621:A[0.5B1-0.5C5]',
      recipes: { fresh: [], dry: ['chicken'] },
      discounted_price: '20',
      discounted_price_per_week: '10',
      price: '20',
      price_per_week: '10',
      id: 10,
    },
  ],
  // TODO:(Yamil) Fill the mixed recipes for testing
  mixed: [
    {
      sku: '2FR:20210621:A[0.5B1-0.5C5]',
      recipes: { fresh: ['beef'], dry: ['chicken'] },
      discounted_price: '20',
      discounted_price_per_week: '10',
      price: '20',
      price_per_week: '10',
      id: 6,
    },
    {
      sku: '2FR:20210621:A[0.5B1-0.5C5]',
      recipes: { fresh: ['chicken'], dry: ['chicken'] },
      discounted_price: '20',
      discounted_price_per_week: '10',
      price: '20',
      price_per_week: '10',
      id: 10,
    },
  ],
};

export const NARVAR_SCRIPT_SRC = 'https://js.mock.narvar.js';

export const BRIGHTBACK_PROPS = {
  customerId: 1,
  customerEmail: 'luke@rebellion.com',
  petSubscriptionId: '2',
};

export const addonProductTypes = {
  treats: 'TR',
  supplements: 'SP',
};

export const ADDON_SKUS = {
  beefjerky: 'JERKYSTRIPS-BE',
  chickenjerky: 'JERKYSTRIPS-CH',
  turkeyjerky: 'JERKYSTRIPS-TU',
  zoomies: 'ZOOMIES01',
  bellyrubs: 'BELLYRUBS01',
  chillpills: 'CHILLPILLS01',
  sweetpotatoes: 'SWEETPOTATOSLICES1',
  'dentalchews-xs': ' DENTALCHEWS-XS',
  'dentalchews-s': 'DENTALCHEWS-S',
  'dentalchews-m': 'DENTALCHEWS-M',
  'dentalchews-l': 'DENTALCHEWS-L',
};

export const treatsDropdownsAndValues = {
  dropdowns: [
    {
      name: 'quantity',
      label: 'Quantity',
      options: [
        { label: '1 Bag', value: 1 },
        { label: '2 Bags', value: 2 },
        { label: '3 Bags', value: 3 },
        { label: '4 Bags', value: 4 },
        { label: '5 Bags', value: 5 },
        { label: '6 Bags', value: 6 },
      ],
    },
    {
      name: 'frequency',
      label: 'Frequency',
      options: [
        { label: 'Every box', value: 1 },
        { label: 'Every other box', value: 2 },
      ],
    },
  ],
  defaultValues: {
    quantity: 1,
    frequency: 1,
  },
};

export const supplementsDropdownsAndValues = {
  dropdowns: [
    {
      name: 'quantity',
      label: 'Quantity',
      options: [
        { label: '1 Jar', value: 1 },
        { label: '2 Jars', value: 2 },
        { label: '3 Jars', value: 3 },
        { label: '4 Jars', value: 4 },
        { label: '5 Jars', value: 5 },
        { label: '6 Jars', value: 6 },
      ],
    },
    {
      name: 'frequency',
      label: 'Frequency',
      options: [
        { label: 'Every box', value: 1 },
        { label: 'Every other box', value: 2 },
      ],
    },
  ],
  defaultValues: {
    quantity: 1,
    frequency: 1,
    addons: [],
  },
};

export const ADDONS_BY_NAME = {
  chickenjerky: {
    sku: ADDON_SKUS.chickenjerky,
    product_name: 'Chicken and Apple Jerky',
    title: 'Chicken and Apple Jerky',
    description: 'Simple and delicious, your dog will crave these treats.',
    product_type: 'TR',
    subtitle: 'Jerky Treats',
    size: '1 bag (5oz.)',
    pricingInfo: { price: '$37.50', discount: '$25.00' },
    img: {
      src: JerkyTreatChickenAppleImage.src,
      alt: 'Chicken And Apple Recipe Jerky Image',
    },
  },
  beefjerky: {
    sku: ADDON_SKUS.beefjerky,
    product_name: 'Beef and Sweet Potato Jerky',
    title: 'Beef and Sweet Potato Jerky',
    description:
      'Clean, simple treats to reward the best and bravest behavior.',
    product_type: 'TR',
    subtitle: 'Jerky Treats',
    size: '1 bag (5oz.)',
    pricingInfo: { price: '$37.50', discount: '$25.00' },
    img: {
      src: JerkyTreatBeefSweetPotatoImage.src,
      alt: 'Beef and Sweet Potato Recipe Jerky Image',
    },
  },
  sweetpotatoes: {
    sku: ADDON_SKUS.sweetpotatoes,
    product_name: 'Sweet Potato Slices',
    title: 'Sweet Potato Slices',
    description:
      'One ingredient treats keep snack time simple, yet satisfying.',
    product_type: 'TR',
    subtitle: 'Slice Treats',
    size: '1 bag (5oz.)',
    pricingInfo: { price: '$27.50', discount: '$22.00' },
    img: {
      src: SweetPotatoTreatImage.src,
      alt: 'Sweet Potato Slices Image',
    },
  },
  zoomies: {
    sku: ADDON_SKUS.zoomies,
    product_name: 'Zoomies',
    title: 'Zoomies',
    description:
      'For healthy hips and joints, this supplement will add some pep to their step.',
    product_type: 'SP',
    subtitle: 'Hip and Joint Formula',
    size: '1 jar (60 chews)',
    pricingInfo: { price: '$37.50', discount: '$25.00' },
    img: {
      src: ZoomiesImage.src,
      alt: 'Hip and Joint Supplement Image',
    },
  },
  chillpills: {
    sku: ADDON_SKUS.chillpills,
    product_name: 'Chill Pills',
    title: 'Chill Pills',
    description:
      'Get your pup to kick back and relax with these mellowing, all-natural supplements.',
    product_type: 'SP',
    subtitle: 'Calming Formula',
    size: '1 jar (60 chews)',
    pricingInfo: { price: '$37.50', discount: '$25.00' },
    img: {
      src: ChillPillsImage.src,
      alt: 'Chill Pills Supplement Image',
    },
  },
  bellyrubs: {
    sku: ADDON_SKUS.bellyrubs,
    product_name: 'Belly Rubs',
    title: 'Belly Rubs',
    description:
      'Say goodbye to the tummy troubles with these delicious treats.',
    product_type: 'SP',
    subtitle: 'Probiotic Formula',
    size: '1 jar (60 chews)',
    pricingInfo: { price: '$37.50', discount: '$25.00' },
    img: {
      src: BellyRubsImage.src,
      alt: 'Belly Rubs Supplement Image',
    },
  },
};

export const FGRecipeFreshBeef = {
  heading: 'Fresh Beef',
  feedingGuidelinesRecipeVersions: [
    {
      versionName: '3202033 and Lower',
      packSize: [
        { label: 'B149', size: 'Small', weight: 'NET WT X.XX lbs' },
        { label: 'B150', size: 'Medium', weight: 'NET WT X.XX lbs' },
        { label: 'B151', size: 'Large', weight: 'NET WT X.XX lbs' },
        { label: 'B152', size: 'X-Large', weight: 'NET WT X.XX lbs' },
      ],
      imageGuide:
        'Find your exact recipe by looking at the barcode on the food pack',
      ingredients: (
        <>
          <p>
            Beef, peas, sweet potatoes, potatoes, carrots, beef kidney, beef
            liver, spinach, blueberries, tricalcium phosphate, chia seeds,
            salmon oil, salt, zinc gluconate, rosemary, copper amino acid
            chelate, vitamin e supplement, ferrous sulfate, choline bitartrate,
            manganese amino acid chelate, thiamine hydrochloride, vitamin d3,
            pyridoxine hydrochloride (vitamin b6), riboflavin (vitamin b2),
            potassium iodide.
          </p>
          <br />
          <p>
            Our Ollie Beef Dish with Sweet Potatoes Recipe for Dogs is
            formulated to meet the AAFCO Dog Food Nutrient Profiles for All Life
            Stages, including growth of large size dogs (70 lbs. or more as an
            adult).
          </p>
        </>
      ),
      barcodeImage: {
        src: barcodeImage,
        alt: 'Image of barcode',
        width: 219,
        height: 157,
      },
      packageImage: {
        src: freshBeefPackImage,
        alt: 'Fresh Beef pack',
        width: 219,
        height: 157,
      },
      calorieContent: (
        <p>
          700 KCAL/LB ME
          <br />
          1540 KCAL/KG ME
          <br />
          376 KCAL/CUP ME
          <br />
        </p>
      ),
      packSizeTitle: 'Pack Size',
      ingredientsTitle: 'Ingredients',
      guaranteedAnalysis: [
        { name: 'Crude Protein', range: 'Min', percentage: '9%' },
        { name: 'Crude fat', range: 'Min', percentage: '7%' },
        { name: 'Crude fiber', range: 'Max', percentage: '2%' },
        { name: 'Moisture', range: 'Max', percentage: '70%' },
      ],
      calorieContentTitle: 'Calorie Content (Calculated)',
      guaranteedAnalysisTitle: 'Guaranteed Analysis',
    },
    {
      versionName: '3202034 and Higher',
      packSize: [
        { label: 'B149', size: 'Small', weight: 'NET WT X.XX lbs' },
        { label: 'B150', size: 'Medium', weight: 'NET WT X.XX lbs' },
        { label: 'B151', size: 'Large', weight: 'NET WT X.XX lbs' },
        { label: 'B152', size: 'X-Large', weight: 'NET WT X.XX lbs' },
      ],
      imageGuide:
        'Find your exact recipe by looking at the barcode on the food pack',
      ingredients: (
        <>
          <p>
            Chicken, carrots, peas, rice, chicken liver, potatoes, spinach,
            dicalcium phosphate, whole dried eggs, fish oil, blueberries, chia
            seeds, calcium carbonate, iodized salt, cod liver oil, zinc
            gluconate, rosemary, copper gluconate, choline bitartrate, vitamin E
            supplement, manganese gluconate, potassium iodide, pyridoxine
            hydrochloride (vitamin B6), riboflavin (vitamin B2)
          </p>
          <br />
          <p>
            Our Ollie Beef Dish with Sweet Potatoes Recipe for Dogs is
            formulated to meet the AAFCO Dog Food Nutrient Profiles for All Life
            Stages, including growth of large size dogs (70 lbs. or more as an
            adult).
          </p>
        </>
      ),
      barcodeImage: {
        src: barcodeImage,
        alt: 'Image of barcode',
        width: 219,
        height: 157,
      },
      packageImage: {
        src: freshBeefPackImage,
        alt: 'Fresh Beef pack',
        width: 219,
        height: 157,
      },
      calorieContent: (
        <p>
          700 KCAL/LB ME
          <br />
          1540 KCAL/KG ME
          <br />
          376 KCAL/CUP ME
          <br />
        </p>
      ),
      packSizeTitle: 'Pack Size',
      ingredientsTitle: 'Ingredients',
      guaranteedAnalysis: [
        { name: 'Crude Protein', range: 'Min', percentage: '6%' },
        { name: 'Crude fat', range: 'Min', percentage: '12%' },
        { name: 'Crude fiber', range: 'Max', percentage: '4%' },
        { name: 'Moisture', range: 'Max', percentage: '65%' },
      ],
      calorieContentTitle: 'Calorie Content (Calculated)',
      guaranteedAnalysisTitle: 'Guaranteed Analysis',
    },
  ],
};

export const FGRecipeFreshChicken = {
  heading: 'Fresh Chicken',
  feedingGuidelinesRecipeVersions: [
    {
      versionName: '3202033 and Lower',
      packSize: [
        { label: 'B149', size: 'Small', weight: 'NET WT X.XX lbs' },
        { label: 'B150', size: 'Medium', weight: 'NET WT X.XX lbs' },
        { label: 'B151', size: 'Large', weight: 'NET WT X.XX lbs' },
        { label: 'B152', size: 'X-Large', weight: 'NET WT X.XX lbs' },
      ],
      imageGuide:
        'Find your exact recipe by looking at the barcode on the food pack:',
      ingredients: (
        <>
          <p>
            Chicken, peas, sweet potatoes, potatoes, carrots, beef kidney, beef
            liver, spinach, blueberries, tricalcium phosphate, chia seeds,
            salmon oil, salt, zinc gluconate, rosemary, copper amino acid
            chelate, vitamin e supplement, ferrous sulfate, choline bitartrate,
            manganese amino acid chelate, thiamine hydrochloride, vitamin d3,
            pyridoxine hydrochloride (vitamin b6), riboflavin (vitamin b2),
            potassium iodide.
          </p>
          <br />
          <p>
            Our Ollie Chicken Dish with Sweet Potatoes Recipe for Dogs is
            formulated to meet the AAFCO Dog Food Nutrient Profiles for All Life
            Stages, including growth of large size dogs (70 lbs. or more as an
            adult).
          </p>
        </>
      ),
      barcodeImage: {
        src: barcodeImage,
        alt: 'Image of barcode',
        width: 219,
        height: 157,
      },
      packageImage: {
        src: freshChickenPackImage,
        alt: 'Fresh Chicken pack',
        width: 219,
        height: 157,
      },
      calorieContent: (
        <p>
          700 KCAL/LB ME
          <br />
          1540 KCAL/KG ME
          <br />
          376 KCAL/CUP ME
          <br />
        </p>
      ),
      packSizeTitle: 'Pack Size',
      ingredientsTitle: 'Ingredients',
      guaranteedAnalysis: [
        { name: 'Crude Protein', range: 'Min', percentage: '9%' },
        { name: 'Crude fat', range: 'Min', percentage: '7%' },
        { name: 'Crude fiber', range: 'Max', percentage: '2%' },
        { name: 'Moisture', range: 'Max', percentage: '70%' },
      ],
      calorieContentTitle: 'Calorie Content (Calculated)',
      guaranteedAnalysisTitle: 'Guaranteed Analysis',
    },
    {
      versionName: '3202034 and Higher',
      packSize: [
        { label: 'B149', size: 'Small', weight: 'NET WT X.XX lbs' },
        { label: 'B150', size: 'Medium', weight: 'NET WT X.XX lbs' },
        { label: 'B151', size: 'Large', weight: 'NET WT X.XX lbs' },
        { label: 'B152', size: 'X-Large', weight: 'NET WT X.XX lbs' },
      ],
      imageGuide:
        'Find your exact recipe by looking at the barcode on the food pack:',
      ingredients: (
        <>
          <p>
            Chicken, carrots, peas, rice, chicken liver, potatoes, spinach,
            dicalcium phosphate, whole dried eggs, fish oil, blueberries, chia
            seeds, calcium carbonate, iodized salt, cod liver oil, zinc
            gluconate, rosemary, copper gluconate, choline bitartrate, vitamin E
            supplement, manganese gluconate, potassium iodide, pyridoxine
            hydrochloride (vitamin B6), riboflavin (vitamin B2)
          </p>
          <br />
          <p>
            Our Ollie Chicken Dish with Sweet Potatoes Recipe for Dogs is
            formulated to meet the AAFCO Dog Food Nutrient Profiles for All Life
            Stages, including growth of large size dogs (70 lbs. or more as an
            adult).
          </p>
        </>
      ),
      barcodeImage: {
        src: barcodeImage,
        alt: 'Image of barcode',
        width: 219,
        height: 157,
      },
      packageImage: {
        src: freshChickenPackImage,
        alt: 'Fresh Chicken pack',
        width: 219,
        height: 157,
      },
      calorieContent: (
        <p>
          700 KCAL/LB ME
          <br />
          1540 KCAL/KG ME
          <br />
          376 KCAL/CUP ME
          <br />
        </p>
      ),
      packSizeTitle: 'Pack Size',
      ingredientsTitle: 'Ingredients',
      guaranteedAnalysis: [
        { name: 'Crude Protein', range: 'Min', percentage: '6%' },
        { name: 'Crude fat', range: 'Min', percentage: '12%' },
        { name: 'Crude fiber', range: 'Max', percentage: '4%' },
        { name: 'Moisture', range: 'Max', percentage: '65%' },
      ],
      calorieContentTitle: 'Calorie Content (Calculated)',
      guaranteedAnalysisTitle: 'Guaranteed Analysis',
    },
  ],
};

export const feedingGuidelines = {
  heading: 'Recipe nutrition guide',
  dropdown: {
    options: [
      {
        value: 'Fresh Beef',
        label: 'Fresh Beef',
      },
      {
        value: 'Fresh Chicken',
        label: 'Fresh Chicken',
      },
    ],
    noSelectionLabel: 'Select a recipe',
    label: 'Choose your recipe',
    name: 'Recipe Nutrition Guide',
  },
  feedingGuidelinesRecipes: [FGRecipeFreshBeef, FGRecipeFreshChicken],
};

export const testimonial = {
  badge: 'MOST WEIGHT LOST',
  badgeColorDropdown: 'kale',
  body: '“I have been able to see incredible results within 3 months in Scooby’s weight and overall energy!”',
  callout: 'CATEGORY OR OTHER TEXT',
  description: 'Boxer',
  name: 'Scooby',
  photo1: {
    src: 'https://a.storyblok.com/f/145952/512x512/2cf70765f5/before.png',
    alt: 'test alt',
    width: 512,
    height: 512,
  },
  photo2: {
    src: 'https://a.storyblok.com/f/145952/512x512/75c3a96d5f/after.png',
    alt: 'test alt',
    width: 512,
    height: 512,
  },
};

export const TESTIMONIALS_DATA = [
  {
    asset: {
      src: photo,
      alt: 'dog photo',
      width: 1200,
      height: 900,
      component: 'image',
    },
    rating: 5,
    body: (
      <Paragraph>
        "Ollie is an absolute game-changer. Our dogs are Frenchies and used to
        be very picky and wouldn’t eat anything except for human food. Now he
        cannot wait for dinner and eats every bit of his Ollie."
      </Paragraph>
    ),
    name: 'Olive',
    description: '2 month Old Frenchie',
  },
  {
    asset: {
      src: photo,
      alt: 'dog photo',
      width: 1200,
      height: 900,
      component: 'image',
    },
    rating: 5,
    body: (
      <Paragraph>
        "Martin has food sensitivities and needs to lose a few pounds. Ollie’s
        lamb and turkey recipes, calories counted and portions predetermined are
        just what we needed."
      </Paragraph>
    ),
    name: 'Law and Order',
    description: '2 month Old Frenchie',
  },
  {
    asset: {
      src: photo,
      alt: 'dog photo',
      width: 1200,
      height: 900,
      component: 'image',
    },
    rating: 5,
    body: (
      <Paragraph>
        "Quam sagittis tincidunt aliquet neque risus sapien ac lectus litora,
        platea tempor auctor mattis vehicula dignissim torquent luctus
        sollicitudin, integer ad quisque vestibulum pulvinar nibh rutrum arcu."
      </Paragraph>
    ),
    name: 'Bread and Circuses',
    description: '2 month Old Frenchie',
  },
  {
    asset: {
      src: photo,
      alt: 'dog photo',
      width: 1200,
      height: 900,
      component: 'image',
    },
    rating: 5,
    body: (
      <Paragraph>
        "Get Buckets Quam sagittis tincidunt aliquet neque risus sapien ac
        lectus litora, platea tempor auctor mattis vehicula dignissim torquent
        luctus sollicitudin, integer ad quisque vestibulum pulvinar nibh rutrum
        arcu."
      </Paragraph>
    ),
    name: 'Davon Ross',
    description: 'Doberman',
  },
];

export const BEFORE_AFTER_TESTIMONIALS_DATA = [
  {
    component: 'before_after_testimonial',
    badge: 'MOST WEIGHT LOST',
    badgeColorDropdown: 'kale',
    body: '“I have been able to see incredible results within 3 months in Scooby’s weight and overall energy!”',
    callout: 'CATEGORY OR OTHER TEXT',
    description: 'Boxer',
    name: 'Scooby',
    photo1: {
      src: photo1,
      alt: 'test alt',
      width: 512,
      height: 512,
    },
    photo2: {
      src: photo2,
      alt: 'test alt',
      width: 512,
      height: 512,
    },
  },
  {
    component: 'before_after_testimonial',
    badge: 'MOST WEIGHT LOST',
    badgeColorDropdown: 'egg',
    body: 'I have been able to see incredible results within 3 months in Scoobys weight and overall energy!',
    callout: 'CATEGORY OR OTHER TEXT',
    description: 'Boxer',
    name: 'Scooby',
    photo1: {
      src: photo1,
      alt: 'test alt',
      width: 512,
      height: 512,
    },
    photo2: {
      src: photo2,
      alt: 'test alt',
      width: 512,
      height: 512,
    },
  },
  {
    component: 'before_after_testimonial',
    badge: 'MOST WEIGHT LOST',
    badgeColorDropdown: 'chia',
    body: '“I have been able to see incredible results within 3 months in Scooby’s weight and overall energy!”',
    callout: 'CATEGORY OR OTHER TEXT',
    description: 'Boxer',
    name: 'Scooby',
    photo1: {
      src: photo1,
      alt: 'test alt',
      width: 512,
      height: 512,
    },
    photo2: {
      src: photo2,
      alt: 'test alt',
      width: 512,
      height: 512,
    },
  },
];

export const TIMELINE_DATA = [
  {
    body: 'Within the first week you will notice your dogs energy increase with Ollie’s all natural ingredients.',
    date: 'DAY 07',
    heading: 'Increased energy',
    markerColor: 'egg',
  },
  {
    body: 'Within the first week you will notice your dogs energy increase with Ollie’s all natural ingredients. You will notice less shedding and a glossier coat. ',
    date: 'DAY 14',
    heading: 'Improved digestions',
    markerColor: 'egg',
  },
  {
    body: 'Within the first week you will notice your dogs energy increase with Ollie’s all natural ingredients. You will notice less shedding and a glossier coat. ',
    date: 'DAY 30',
    heading: 'Glowing skin and coat',
    markerColor: 'egg',
  },
  {
    body: 'Within the first week you will notice your dogs energy increase with Ollie’s all natural ingredients. ',
    date: 'Day 60 Onward',
    heading: 'Your pups best self',
    markerColor: 'egg',
  },
];

export const INFORMATION_STATS_DATA1 = [
  {
    heading: '28,998',
    description: 'Dogs loving Ollie',
  },
  {
    heading: '75%',
    description: 'Less processed ingredients',
  },
  {
    heading: '2M+',
    description: 'Pounds of Fresh Food Served',
  },
  {
    heading: '2M+',
    description: 'Pounds of Fresh Food Served',
  },
  {
    heading: '28,998',
    description: 'Dogs loving Ollie',
  },
  {
    heading: '75%',
    description: 'Less processed ingredients',
  },
  {
    heading: '2M+',
    description: 'Pounds of Fresh Food Served',
  },
  {
    heading: '2M+',
    description: 'Pounds of Fresh Food Served',
  },
];

export const INFORMATION_STATS_DATA2 = [
  {
    heading: '28,998',
    description: 'Dogs loving Ollie',
  },
  {
    heading: '75%',
    description: 'Less processed ingredients',
  },
  {
    heading: '2M+',
    description: 'Pounds of Fresh Food Served',
  },
  {
    heading: '2M+',
    description: 'Pounds of Fresh Food Served',
  },
  {
    heading: '28,998',
    description: 'Dogs loving Ollie',
  },
  {
    heading: '75%',
    description: 'Less processed ingredients',
  },
  {
    heading: '2M+',
    description: 'Pounds of Fresh Food Served',
  },
];

export const IllustrationPillsData = [
  {
    component: 'illustration_pill',
    icon: {
      src: beef,
      alt: 'beef steak',
      width: 30,
      height: 20,
    },
    text: 'Beef',
    backgroundColor: 'background.white',
  },
  {
    component: 'illustration_pill',
    icon: {
      src: chicken,
      alt: 'chicken drumstick',
      width: 30,
      height: 20,
    },
    text: 'Chicken',
    backgroundColor: 'background.white',
  },
  {
    component: 'illustration_pill',
    icon: {
      src: turkey,
      alt: 'whole turkey',
      width: 30,
      height: 20,
    },
    text: 'Turkey',
    backgroundColor: 'background.white',
  },
  {
    component: 'illustration_pill',
    icon: {
      src: lamb,
      alt: 'lamb chop',
      width: 30,
      height: 20,
    },
    text: 'Lamb',
    backgroundColor: 'background.white',
  },
  {
    component: 'illustration_pill',
    icon: {
      src: pork,
      alt: 'pork chop',
      width: 30,
      height: 20,
    },
    text: 'Pork',
    backgroundColor: 'background.white',
  },
];

export const productValue = {
  callout: 'BEST FOR',
  benefit: 'Increased Energy',
  badge: 'Best Seller',
  description: (
    <div>
      <p>
        All natural and fresh dog food that will leave your pup feeling sprite!
      </p>
      <ul>
        <li>Delivered fresh to your door step</li>
        <li>Made with real meat, fruits, veggies, and grains</li>
        <li>Human-Grade balanced nutrition</li>
        <li>Leaves your dog feeling full</li>
        <li>Improves Digestions</li>
      </ul>
    </div>
  ),
  image: {
    src: 'https://via.placeholder.com/250x250',
    alt: 'chicken recipe',
    height: 250,
    width: 250,
  },
  link: {
    target: '#',
    text: 'Learn more',
  },
  buttonVariant: 'chia',
};
