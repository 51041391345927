'use client';
import { useSWRConfig } from 'swr';
import { useAuthenticatedSWR, ApiResult, useService } from '../api';
import { PaymentService } from './service';
import { PAYMENT_V3_URL, DEFAULT } from './constants';

export const usePayment = (customerId, paymentMethodId = DEFAULT) => {
  const service = useService(PaymentService);
  const { mutate: globalMutate } = useSWRConfig();
  const { data, error, mutate } = useAuthenticatedSWR(
    customerId ? `${PAYMENT_V3_URL(customerId)}${paymentMethodId}/` : null,
  );

  const update = async (paymentId, paymentMethod, token) => {
    paymentMethod = {
      ...paymentMethod,
      card_number: token.card.last4,
      brand: token.card.brand,
      card_token: token.id,
    };
    const response = await service.update(customerId, paymentId, paymentMethod);
    mutate(response.data, false);
    return response;
  };

  const create = async paymentMethod =>
    ApiResult.callAsync(async () => {
      const response = await service.create(customerId, paymentMethod);
      mutate(response.data, false);
      globalMutate(
        key => typeof key === 'string' && key.includes('reactivation'),
        undefined,
      );
      return response;
    });

  return {
    payment: data,
    isLoading: !data && !error,
    error,
    update,
    create,
  };
};
