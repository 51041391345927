import React from 'react';
import PropTypes from 'prop-types';
import { Icon as MDIcon } from '@mdi/react';
import { theme } from '@ui/theme';
import { ICON_PATHS } from './constants';

export const Icon = ({ iconName, alt, size, color = 'text.primary', id }) => (
  <MDIcon
    path={ICON_PATHS[iconName]}
    title={alt}
    size={size}
    color={theme.colors.get(color)}
    id={id}
  />
);

Icon.displayName = 'Icon';

Icon.propTypes = {
  iconName: PropTypes.oneOf(Object.keys(ICON_PATHS)),
  alt: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  color: PropTypes.string,
  id: PropTypes.string.isRequired,
};
