import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { TitleCTAContainer } from '../TitleCTAContainer';
import { ResponsiveImage } from '../ResponsiveImage';
import {
  StyledAddOnCardContainer,
  StyledAddOnCardDescription,
  StyledCardContent,
  StyledTitle,
  StyledImageContainer,
  MobileCardContent,
  MobileImageContainer,
  StyledContentButton,
} from './styles';

export const AddOnSummaryCard = ({
  title,
  image = null,
  ctaHref,
  ctaButtonText = 'See all',
  ctaButtonVariant = 'egg',
  description = '',
  onClick,
}) => {
  const button = {
    text: ctaButtonText,
    variant: ctaButtonVariant,
    href: ctaHref,
    onClick,
  };

  return (
    <StyledAddOnCardContainer>
      <StyledImageContainer>
        <ResponsiveImage {...image} />
      </StyledImageContainer>
      <StyledCardContent>
        <StyledTitle>{title}</StyledTitle>
        <StyledAddOnCardDescription>{description}</StyledAddOnCardDescription>
      </StyledCardContent>
      <StyledContentButton>
        <LinkButton
          href={ctaHref}
          variant={ctaButtonVariant}
          horizontalPadding={5}
          verticalPadding={1}
          minWidth={90}
          onClick={onClick}
          fullWidth
        >
          {ctaButtonText}
        </LinkButton>
      </StyledContentButton>
      <MobileCardContent>
        <TitleCTAContainer title={title} ctaButton={button}></TitleCTAContainer>
        <MobileImageContainer>
          <ResponsiveImage {...image} />
        </MobileImageContainer>
      </MobileCardContent>
    </StyledAddOnCardContainer>
  );
};

AddOnSummaryCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ctaHref: PropTypes.string.isRequired,
  ctaButtonText: PropTypes.string,
  ctaButtonVariant: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
};
