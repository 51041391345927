import {
  mdiMenu,
  mdiClose,
  mdiCheck,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronUp,
  mdiChevronDown,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiRadioboxBlank,
  mdiRadioboxMarked,
  mdiPlusCircleOutline,
  mdiPlusCircle,
  mdiPlusThick,
  mdiPlus,
  mdiMinusCircleOutline,
  mdiMinusCircle,
  mdiMinusThick,
  mdiMinus,
  mdiCheckCircleOutline,
  mdiCheckCircle,
  mdiStarOutline,
  mdiStarHalf,
  mdiStar,
  mdiInformation,
  mdiAlertCircle,
  mdiDelete,
  mdiHelpCircleOutline,
  mdiHelpCircle,
  mdiLock,
  mdiAlert,
  mdiCloseCircle,
  mdiArrowLeft,
  mdiArrowRight,
  mdiPencil,
  mdiCircle,
  mdiCheckBold,
  mdiCloseCircleOutline,
  mdiCheckboxBlankCircle,
  mdiCheckboxBlankCircleOutline,
  mdiChatOutline,
  mdiSlashForward,
} from '@mdi/js';

export const ICON_PATHS = {
  menu: mdiMenu,
  close: mdiClose,
  check: mdiCheck,
  'chevron-left': mdiChevronLeft,
  'chevron-right': mdiChevronRight,
  'chevron-up': mdiChevronUp,
  'chevron-down': mdiChevronDown,
  'checkbox-blank-outline': mdiCheckboxBlankOutline,
  'checkbox-marked': mdiCheckboxMarked,
  'checkbox-blank-circle': mdiCheckboxBlankCircle,
  'checkbox-blank-circle-outline': mdiCheckboxBlankCircleOutline,
  'radiobox-blank': mdiRadioboxBlank,
  'radiobox-marked': mdiRadioboxMarked,
  'plus-circle-outline': mdiPlusCircleOutline,
  'plus-circle': mdiPlusCircle,
  'plus-thick': mdiPlusThick,
  plus: mdiPlus,
  'minus-circle-outline': mdiMinusCircleOutline,
  'minus-circle': mdiMinusCircle,
  'minus-thick': mdiMinusThick,
  minus: mdiMinus,
  'check-circle-outline': mdiCheckCircleOutline,
  'check-circle': mdiCheckCircle,
  'star-outline': mdiStarOutline,
  'star-half': mdiStarHalf,
  star: mdiStar,
  information: mdiInformation,
  'alert-circle': mdiAlertCircle,
  delete: mdiDelete,
  'help-circle-outline': mdiHelpCircleOutline,
  'help-circle': mdiHelpCircle,
  lock: mdiLock,
  alert: mdiAlert,
  'close-circle': mdiCloseCircle,
  'close-circle-outline': mdiCloseCircleOutline,
  'arrow-left': mdiArrowLeft,
  'arrow-forward': mdiArrowRight,
  'slash-forward': mdiSlashForward,
  pencil: mdiPencil,
  bullet: mdiCircle,
  checkmark: mdiCheckBold,
  chat: mdiChatOutline,
};

export const VALID_ICONS = Object.keys(ICON_PATHS);
