import { useEffect, useRef } from 'react';
import { shared } from '../config';
import { useSessionCustomer } from '../customer';

export const DASHBOARD_ZONE = 'dashboard';
export const DESKTOP_MENU_ZONE = 'desktop_menu';
export const MOBILE_MENU_ZONE = 'mobile_menu';
export const CONFIRMATION_ZONE = 'confirmation';

export const useReferralRef = zoneName => {
  const ollie_env = shared.OLLIE_ENV;
  const extole_campaign_label = shared.EXTOLE_CAMPAIGN_LABEL;
  const referralRef = useRef();
  const {
    isLoading: isCustomerLoading,
    isAuthenticated,
    customer,
  } = useSessionCustomer();

  useEffect(() => {
    if (!isCustomerLoading && isAuthenticated) {
      window.extole.createZone({
        name: zoneName,
        element: referralRef.current,
        data: {
          partner_user_id: customer.id,
          email: customer.email,
          first_name: customer.first_name,
          last_name: customer.last_name,
          ...(ollie_env !== 'production' && { labels: extole_campaign_label }),
        },
      });
    }
  }, [
    zoneName,
    customer,
    isAuthenticated,
    isCustomerLoading,
    ollie_env,
    extole_campaign_label,
  ]);

  return referralRef;
};
