'use client';
import styled from 'styled-components';

export const StyledLoadingSpinner = styled.div`
  border: 4px solid #00000063;
  border-top: 4px solid #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
