'use client';
/* global analytics */
import * as Sentry from '@sentry/nextjs';
import { datadogRum } from '@datadog/browser-rum';
import { shared } from '../config';

/**
 * The Reporter class responsibility is reporting events in
 * the browser to the third-party systems that need them.
 * At initial development, that included:
 *
 * * Segment
 * * Sentry
 * * Console
 * * Datadog
 *
 * @typedef {{ type: string, data?: Object }} ReporterTag
 */
export class Reporter {
  /**
   * Whether Sentry is enabled.
   *
   * @private
   */
  static get _enabled() {
    return shared.OLLIE_ENV !== 'local';
  }

  /**
   * Whether we should append the test prefix to events.
   *
   * @private
   */
  static get _appendTest() {
    return shared.OLLIE_ENV !== 'production';
  }

  /**
   * Whether the session is mounted via the app though WebView.
   *
   * @private
   */

  static get _isAppSession() {
    return (
      typeof window !== 'undefined' &&
      JSON.parse(localStorage.getItem('isAppSession'))
    );
  }
  /**
   * Whether the session is a new customer who first registered via a health screening
   *
   * @private
   */

  static get _screeningSession() {
    return (
      typeof window !== 'undefined' &&
      JSON.parse(localStorage.getItem('screeningSession'))
    );
  }

  /**
   * Construct a new Reporter instance.
   *
   * @param {{ prefix?: string }} options
   */
  constructor({ prefix = '' } = {}) {
    this.prefix = prefix;
  }

  /**
   * Report the current user.
   *
   * @param {{ id: string; email: string; }} customer
   */
  user(customer) {
    analytics.identify(customer.id, {
      email: customer.email,
    });

    Sentry.setUser({ id: customer.id });
    datadogRum.setUser({ id: customer.id });
  }

  /**
   * Report the current page.
   *
   * @param {string} pageTitle
   */
  page(pageTitle) {
    const message = `Loaded page ${pageTitle}`;

    analytics.page(pageTitle);
    Sentry.addBreadcrumb({ message });
    datadogRum.addAction(message);
  }

  /**
   * Report a tagged event.
   *
   * @param {ReporterTag} event
   */
  tag({ type, data }) {
    return new Promise(res => {
      const prefixedType = this._prefixType(type);
      const message = `Fired tag ${prefixedType}`;
      analytics.track(
        prefixedType,
        {
          data_source: 'WEB',
          ...(Reporter._isAppSession && { flow: 'app_acquisition' }),
          ...(Reporter._screeningSession && {
            flow: Reporter._screeningSession.flow,
            result: Reporter._screeningSession.result,
          }),
          ...data,
        },
        {},
        res,
      );
      Sentry.addBreadcrumb({ message, ...data, data_source: 'WEB' });
      datadogRum.addAction(message, { data_source: 'WEB', ...data });

      // Promises only resolve once
      setTimeout(res, 300);
    });
  }

  /**
   * Report a breadcrumb event.
   *
   * @param {string} message
   */
  breadcrumb(message) {
    Sentry.addBreadcrumb({ message });
    datadogRum.addAction(message);
  }

  /**
   * Report a tagged event & feature flag to RUM
   *
   * @param {ReporterTag} event
   *
   * These special characters are not supported for Feature Flag Tracking -> (., :, +, -, =, &&, ||, >, <, !, (, ), {, }, [, ], ^, ", “, ”, ~, *, ?, \)
   */
  flag({ type, data }) {
    return new Promise(res => {
      const prefixedType = this._prefixType(type);
      const message = `Fired tag ${prefixedType}`;
      analytics.track(
        prefixedType,
        {
          data_source: 'WEB',
          ...(Reporter._isAppSession && { flow: 'app_acquisition' }),
          ...(Reporter._isScreeningSession && {
            flow: 'weight_web_acquisition',
          }),
          ...data,
        },
        {},
        res,
      );
      Sentry.addBreadcrumb({ message, ...data, data_source: 'WEB' });
      datadogRum.addFeatureFlagEvaluation(
        data['Test Name'],
        data['VariationID'],
      );

      // Promises only resolve once
      setTimeout(res, 300);
    });
  }

  /**
   * Report an error.
   *
   * @typedef {Record<string, Object>} ErrorContexts
   *
   * @param {Error} error
   * @param {{ contexts?: ErrorContexts }} [meta]
   */
  error(error, { contexts = {} } = {}) {
    if (!Reporter._enabled) {
      // Log the error if the reporter isn't enabled
      // eslint-disable-next-line no-console
      console.error(error);

      return;
    }

    Sentry.withScope(scope => {
      Object.keys(contexts).forEach(key => {
        scope.setExtra(key, contexts[key]);
      });

      Sentry.captureException(error);
    });
    datadogRum.addError(error, contexts);
  }

  /**
   * Create a new Reporter instance based on the current instance
   * with a new prefix applied.
   *
   * @param {string} prefix
   */
  withPrefix(prefix) {
    return new Reporter({ prefix });
  }

  /**
   * Create the event prefix string.
   *
   * @private
   */
  _prefixType(type) {
    return `${Reporter._appendTest ? '[TEST] - ' : ''}${
      this.prefix ? `[${this.prefix}] ` : ''
    }${type}`;
  }
}
