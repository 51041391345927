import useSWR from 'swr';
import { useCallback } from 'react';
import { ApiResult, useService } from '../api';
import { CustomerService } from './service';
import { ADDRESS_ENDPOINT } from './constants';

const EMPTY_ADDRESS = {
  firstName: '',
  lastName: '',
  address: '',
  apt: '',
  zipCode: '',
  city: '',
  state: '',
  phoneNumber: '',
  smsUpdates: false,
  residentialAddress: false,
};

export const useAddress = customerId => {
  const service = useService(CustomerService);
  let { data, error, mutate } = useSWR(
    customerId ? ADDRESS_ENDPOINT(customerId) : null,
  );

  const update = useCallback(
    address =>
      ApiResult.callAsync(async () => {
        const response = await service.updateAddress(customerId, address);
        mutate(response.data, false);
        return response;
      }),
    [customerId, mutate, service],
  );

  return {
    address: customerId && data ? data : EMPTY_ADDRESS,
    isLoading: !data && !error,
    error,
    update,
  };
};
