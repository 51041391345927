export const headerLinkClicked = (text, target, position) => ({
  type: 'Header Link Clicked',
  data: {
    text,
    target,
    position,
  },
});

export const headerButtonClicked = (text, position) => ({
  type: 'Header Button Clicked',
  data: {
    text,
    position,
  },
});

export const getStartedButtonClicked = () => ({
  type: '[Get Started] Fixed CTA',
});
