import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import {
  IngredientWrapper,
  IngredientBody,
  IngredientContent,
  IngredientHeading,
  IngredientIcon,
} from './styles';

export const RecipeIngredient = ({ image, name, description }) => {
  return (
    <IngredientWrapper>
      <IngredientIcon>
        <ResponsiveImage
          src={image.src}
          alt={image.alt}
          width={image.width}
          height={image.height}
        />
      </IngredientIcon>
      <IngredientContent>
        <IngredientHeading>{name}</IngredientHeading>
        <IngredientBody>{description}</IngredientBody>
      </IngredientContent>
    </IngredientWrapper>
  );
};

RecipeIngredient.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
