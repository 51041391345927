import { useState, useCallback } from 'react';
import { ApiResult, useService } from '../api';
import { PaymentMethodService } from './PaymentMethodService';
import { STRIPE_ACCOUNT_REDIRECT_URL } from './constants';

const emptyIntent = {
  id: '',
  transaction_id: '',
};

export const usePaymentMethod = () => {
  const service = useService(PaymentMethodService);
  const [setupIntent, setSetupIntent] = useState(emptyIntent);
  const initiateSetupIntent = useCallback(
    async customerId =>
      ApiResult.callAsync(async () => {
        const response = await service.create(customerId);
        setSetupIntent(response.data);
        return response.data;
      }),
    [setSetupIntent, service],
  );
  const confirmSetupIntent = async ({
    stripe,
    elements,
    customer,
    address,
    clientSecret = null,
  }) =>
    ApiResult.callAsync(async () => {
      if (!stripe) {
        throw new Error('stripe or elements has not yet loaded');
      }
      if (clientSecret)
        elements.update({ customerSessionClientSecret: clientSecret });
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        ...(clientSecret && { clientSecret }),
        confirmParams: {
          payment_method_data: {
            billing_details: {
              email: customer.email,
              phone: address.phone,
              address: { country: 'US' },
            },
          },
          return_url: STRIPE_ACCOUNT_REDIRECT_URL,
        },
        redirect: 'if_required',
      });
      if (error) throw error;
      return setupIntent;
    });

  const createPaymentMethod = async (customerId, internalTransactionId) =>
    ApiResult.callAsync(async () => {
      const response = await service.confirm(
        customerId,
        internalTransactionId || setupIntent.id,
      );

      return response.data;
    });

  const removeSetupIntent = useCallback(
    () => setSetupIntent(emptyIntent),
    [setSetupIntent],
  );

  return {
    setupIntent,
    removeSetupIntent,
    initiateSetupIntent,
    confirmSetupIntent,
    createPaymentMethod,
  };
};
