import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@ui/theme';

export const CheckmarkIconSimple = ({
  color = 'border.fur',
  size = '11',
  alt = '',
}) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      viewBox="0 0 11 9"
    >
      {alt && <title>{alt}</title>}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.74 1.702 4.573 8.707a.8.8 0 0 1-1.237 0L.258 5.182a1.093 1.093 0 0 1 0-1.41.8.8 0 0 1 1.236 0l2.473 2.82 5.55-6.3a.8.8 0 0 1 1.237 0c.33.39.33 1.02-.013 1.41z"
        fill={theme.colors.get(color)}
      />
    </svg>
  );
};
CheckmarkIconSimple.displayName = 'CheckmarkIconSimple';
CheckmarkIconSimple.propTypes = {
  alt: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
