import React from 'react';
import PropTypes from 'prop-types';
import { AddOnSummaryCard } from '../AddOnSummaryCard';
import {
  StyledAddOnSummaryCardsContainer,
  StyledTitle,
  StyledCardsContainer,
} from './styles';

export const AddOnSummaryCards = ({ title, cards, onClick }) => {
  return (
    <StyledAddOnSummaryCardsContainer>
      <StyledTitle>{title}</StyledTitle>
      <StyledCardsContainer>
        {cards.map(card => (
          <AddOnSummaryCard {...card} key={card.title} onClick={onClick} />
        ))}
      </StyledCardsContainer>
    </StyledAddOnSummaryCardsContainer>
  );
};

AddOnSummaryCards.propTypes = {
  title: PropTypes.string.isRequired,
  cards: PropTypes.array.isRequired,
  onClick: PropTypes.func,
};
