import { useEffect } from 'react';
import Cookies from 'js-cookie';

export const useSetAnonymousVisitorId = (visitorId, hasSession) => {
  useEffect(() => {
    if (!hasSession && visitorId && !Cookies.get('anonymousVisitorId')) {
      // Set the anonymous visitor ID in the cookie if there's no session
      Cookies.set('anonymousVisitorId', visitorId, {
        path: '/', // Make cookie available across the entire app
        sameSite: 'Lax',
      });
    }
  }, [visitorId, hasSession]);
};
