import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveImage } from '@web/molecules';
import doggo from '../../images/Footer/dog-with-food-hat.svg';
import { FooterColumn, SocialColumn } from './FooterColumn';
import { BottomLink } from './FooterLink';
import {
  StyledFooter,
  Paragraph,
  BottomLinks,
  StyledLink,
  FooterColumns,
  ConstrainedContainer,
  LeftAlignedLinks,
  MobileIllustration,
  LegalBlurb,
  BlurbSupportGrid,
  BlurbSupportContainer,
  InnerBlurb,
} from './styles.js';

export const Footer = ({
  message,
  copyright,
  columnLinks,
  bottomLinks,
  variant,
  isAccount = false,
}) => {
  return (
    <StyledFooter>
      <ConstrainedContainer>
        {variant === 'full' && (
          <FooterColumns>
            {columnLinks.map(
              ({
                title,
                links,
                content,
                businessHours,
                legalBlurb,
                hideIfAccount,
              }) =>
                hideIfAccount && isAccount ? null : (
                  <>
                    {title === 'Support' ? (
                      <BlurbSupportContainer>
                        <BlurbSupportGrid>
                          <FooterColumn
                            links={links}
                            businessHours={businessHours}
                            title={title}
                            content={content}
                            key={title}
                          />
                          <>
                            <SocialColumn />
                            <MobileIllustration>
                              <ResponsiveImage
                                src={doggo}
                                alt="illustrated dog with various foods stacked on his head"
                                height={80}
                                width={72}
                              />
                            </MobileIllustration>
                          </>

                          <InnerBlurb>
                            {legalBlurb && (
                              <LegalBlurb>{legalBlurb}</LegalBlurb>
                            )}
                          </InnerBlurb>
                        </BlurbSupportGrid>
                      </BlurbSupportContainer>
                    ) : (
                      <FooterColumn
                        links={links}
                        businessHours={businessHours}
                        title={title}
                        content={content}
                        key={title}
                      />
                    )}
                  </>
                ),
            )}
          </FooterColumns>
        )}
        <Paragraph>{message}</Paragraph>
      </ConstrainedContainer>
      <BottomLinks>
        <LeftAlignedLinks>
          {bottomLinks.map(({ path, text }, index) => (
            <BottomLink href={path} text={text} index={index} key={path} />
          ))}
        </LeftAlignedLinks>
        <StyledLink as="span">{copyright}</StyledLink>
      </BottomLinks>
    </StyledFooter>
  );
};

Footer.displayName = 'Footer';

Footer.propTypes = {
  copyright: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  columnLinks: PropTypes.arrayOf(PropTypes.object),
  bottomLinks: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.oneOf(['simplified', 'full']).isRequired,
  isAccount: PropTypes.bool,
};
