import styled, { css, keyframes } from 'styled-components';
import { generateContentIconStyle } from '@ui/icons';

const popUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(16px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
  
  `;
const dismiss = keyframes`
from {
    opacity: 1;
    transform: translateY(0);
  }
 to {
    opacity: 0;
    transform: translateY(16px);  
  }
`;

export const StyledToast = styled.div`
  position: relative;
  opacity: ${props => (props.showToast ? '1' : '0')};
  max-width: 568px;
  background-color: ${props => props.theme.colors.get(props.backgroundColor)};
  border: 1px solid ${props => props.theme.colors.get(props.borderColor)};
  border-radius: 8px;
  padding: ${props => props.theme.spacing(4)};
  ${props =>
    props.showToast
      ? css`
          animation: ${popUp} 0.25s;
        `
      : css`
          animation: ${dismiss} 0.25s;
        `}
`;
export const StatusMessage = styled.p``;

export const CloseButton = styled.button`
  ${generateContentIconStyle('close')};
  height: 20px;
  width: 20px;
  background-color: ${props => props.theme.colors.border.dark};
  @media ${props => props.theme.mobileL} {
    height: 30px;
    width: 30px;
  }
`;

export const IconWrapper = styled.div`
  height: 50px;
  width: 50px;
  @media ${props => props.theme.mobileL} {
    margin-right: ${props => props.theme.spacing(4)};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;

  @media ${props => props.theme.mobileL} {
    align-items: center;
  }
`;

export const Headline = styled.p`
  margin-top: -2px;
  ${props => props.theme.typography.heading6};
  display: block;
  text-transform: capitalize;
  color: ${props => props.theme.colors.get(props.$textColor)};
`;
export const TextWrapper = styled.div`
  ${props => props.theme.typography.body3};
  display: flex;
  flex-direction: column;
  margin-left: ${props => props.theme.spacing(3)};
`;

export const ToastWrapper = styled.div`
  position: fixed;
  width: calc(100% - 32px);
  left: ${props => props.theme.spacing(4)};
  bottom: ${props => props.theme.spacing(4)};
  z-index: 10001; /* .ModalOverlay +1 */
`;
