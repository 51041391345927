import { BaseService } from '../api';
import {
  PAYMENT_METHOD_INTENT_V3_URL,
  PAYMENT_METHOD_CONFIRM_V3_URL,
} from './constants';

export class PaymentMethodService extends BaseService {
  create = customerId =>
    customerId
      ? this.client.post(PAYMENT_METHOD_INTENT_V3_URL(customerId))
      : null;

  confirm = (customerId, intentId) =>
    customerId && intentId
      ? this.client.post(PAYMENT_METHOD_CONFIRM_V3_URL(customerId, intentId))
      : null;
}
