export const CONFLICT = 409;

export const ADDRESS_ENDPOINT_REGEX = /\/api\/\w*\W*\/customers\/\d*\/address/;
// This endpoint is from ollieweb, so no v3
export const AUTH_ENDPOINT_REGEX = /\/api\/auth\//;
export const CUSTOMER_ENDPOINT_REGEX = /\/api\/\w*\W*\/customers\/\d*\/$/;
export const PAYMENT_METHODS_REGEX =
  /\/api\/\w*\W*\/customers\/\d*\/payment_methods/;
export const CHECKOUT_ENDPOINT_REGEX =
  /\/api\/\w*\W*\/customers\/\d*\/checkout/;
export const DELIVERY_ENDPOINT_REGEX =
  /\/api\/\w*\/subscriptions\/\w*\W*\w*\W*\w*\W*\w*\W*\w*\/skip_next_delivery\//;

export const ADDRESS_ERROR_MAP = {
  first_name: 'firstName',
  last_name: 'lastName',
  address1: 'address',
  address2: 'apt',
  city: 'city',
  state: 'state',
  zip_code: 'zipCode',
  phone: 'phoneNumber',
};
