import React from 'react';

export const Pingdom = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: `
    var _prum = [['id', '5984cb8da1a835312727532d'],
                ['mark', 'firstbyte', (new Date()).getTime()]];

    (function() {
      var s = document.getElementsByTagName('script')[0]
        , p = document.createElement('script');
      p.async = 'async';
      p.src = '//rum-static.pingdom.net/prum.min.js';
      s.parentNode.insertBefore(p, s);
    })()`,
    }}
  />
);
