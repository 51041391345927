'use client';
import styled, { css } from 'styled-components';

// eslint-disable-next-line
export const SourceImage = styled.img`
  ${props =>
    props.objectFit === 'cover' &&
    css`
      height: 100%;
      object-fit: cover;
      width: 100%;
    `}
`;
