import styled from 'styled-components';

export const Header = styled.div`
  display: none;
  width: 100%;
  max-width: 420px;
  @media ${props => props.theme.tablet} {
    display: flex;
    padding-bottom: ${props => props.theme.spacing(3)};
    align-content: flex-end;
    justify-content: space-between;
  }
  @media ${props => props.theme.laptop} {
    margin: auto;
    max-width: 1600px;
  }
`;

export const MobileHeader = styled(Header)`
  display: flex;
  align-content: center;
  text-align: center;
  padding: ${props => props.theme.spacing(4, 20, 4, 0)};
  margin: ${props => props.theme.spacing(6, 0)};
  @media ${props => props.theme.tablet} {
    display: none;
  }
`;

export const Title = styled.h1`
  ${props => props.theme.typography.heading3}
  color: ${props => props.theme.colors.text.primary};
  padding-bottom: ${props => props.theme.spacing(1)};

  @media ${props => props.theme.mobileS} {
    flex: 1;
    text-align: center;
    align-self: center;
    margin: auto;
  }

  @media ${props => props.theme.tablet} {
    text-align: left;
  }
`;

export const BackButton = styled.div``;

export const SlideDownContainer = styled.div`
  min-height: max-content;
  width: 100%;
  margin: auto;
  max-width: fit-content;
`;

export const HeadingContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  min-height: 50px;
  width: 100vw;
  z-index: 999;
  background: ${props => props.theme.colors.background.base};

  @media ${props => props.theme.tablet} {
    position: static;
    top: auto;
  }
  @media ${({ theme }) => theme.laptopL} {
    text-align: center;
    left: 50;
  }
`;

export const CategoriesContainer = styled.div`
  display: flex;

  @media ${({ theme }) => theme.mobileS} {
    flex-direction: column;
    gap: ${props => props.theme.spacing(8)};
    padding-bottom: 30%;
  }

  @media ${({ theme }) => theme.tablet} {
    flex-wrap: wrap;
    flex-direction: unset;
    gap: ${props => props.theme.spacing(6)};
    padding-bottom: 24px;
  }

  @media ${({ theme }) => theme.laptop} {
    padding-bottom: 0px;
  }

  @media ${({ theme }) => theme.laptopL} {
    flex-wrap: nowrap;
    margin: auto;
    justify-content: space-around;
    max-width: 1600px;
  }
`;
