'use client';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { HeaderButton } from '../Header/HeaderButton';
import { ProductsSummarySlider } from '../ProductsSummarySlider';

export function SlideoutMenuLink({
  sections,
  menuHeader,
  linkText,
  onCloseMenu,
}) {
  const [active, setActive] = useState(false);
  const handleCloseMenu = () => {
    onCloseMenu?.();
    setActive(false);
  };
  const handleGoBack = () => setActive(false);

  return (
    <>
      <HeaderButton
        text={linkText}
        onClick={() => {
          setActive(true);
        }}
      />
      <ProductsSummarySlider
        menuHeader={menuHeader}
        sections={sections}
        onCloseMenu={handleCloseMenu}
        onGoBack={handleGoBack}
        active={active}
      />
    </>
  );
}

SlideoutMenuLink.propTypes = {
  sections: PropTypes.array.isRequired,
  menuHeader: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  onCloseMenu: PropTypes.func,
};
