'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const ProductSummariesWrapper = styled.div`
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spacing(6)};
  align-items: center;
  @media ${props => props.theme.tablet} {
    padding-bottom: 0;
  }
`;

export const Header = styled.div`
  display: none;
  margin-bottom: ${props => props.theme.spacing(3)};
  margin-top: ${props => props.theme.spacing(15)};
  width: 100%;
  max-width: 420px;
  padding: ${props => props.theme.spacing(0, 9, 0, 0)};

  @media ${props => props.theme.tablet} {
    display: flex;
    align-content: flex-end;
    justify-content: space-between;
    padding: ${props => props.theme.spacing(0, 0, 0, 6)};
  }
`;

export const MobileHeader = styled(Header)`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 0;

  @media ${props => props.theme.tablet} {
    display: none;
  }
`;

export const Title = styled.h1`
  ${props => props.theme.typography.heading2};
  color: ${props => props.theme.colors.text.primary};
  padding-bottom: ${props => props.theme.spacing(1)};
`;

export const CloseIcon = styled.button`
  background-color: ${props => props.theme.colors.neutral.bone};
  border-radius: ${props => props.theme.spacing(10)};
  border: 0;
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spacing(3)};
  height: 56px;
  width: 56px;
`;

export const BackLabel = styled.button`
  background-color: transparent;
  border: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 50%;
`;

export const ProductTitle = styled.div`
  display: flex;
  align-items: end;
  gap: ${props => props.theme.spacing(4)};
`;

export const ProductImageContainer = styled.div`
  display: none;
  max-width: 5rem;
  max-height: 2.2rem;

  @media ${props => props.theme.tablet} {
    display: inherit;
    margin-left: ${props => props.theme.spacing(1.5)};
  }
`;

export const ProductDescription = styled.div`
  display: none;
  margin: ${props => props.theme.spacing(2, 0)};
  text-align: left;

  @media ${props => props.theme.tablet} {
    display: grid;
  }
`;

export const ProductRecipes = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 16px;
  margin: ${props => props.theme.spacing(2, 0)};

  @media ${props => props.theme.tablet} {
    justify-content: flex-start;
  }
`;

export const Recipe = styled(Link)`
  display: flex;
  ${props => props.theme.fontFamily.condensed};
  flex-direction: column;
  margin: ${props => props.theme.spacing(2, 0)};
  color: ${({ theme }) => theme.colors.text.primary};
  text-align: center;
  align-items: center;
  text-transform: uppercase;
  ${props => props.theme.typography.body}
  width: calc(50% - 8px);
  min-width: 5rem;
  @media ${props => props.theme.tablet} {
    width: initial;
    min-width: unset;
  }
`;

export const RecipeImage = styled.div`
  height: 145px;
  width: 145px;
  transition: transform ease 0.5s;

  &:hover {
    transform: translateY(-10px);
  }

  @media ${props => props.theme.tablet} {
    height: 81px;
    width: 81px;
  }
`;
