import { shared } from '../../config';

export const BASE = appEnvVars => {
  if (appEnvVars) {
    return (
      (appEnvVars.API_BASE_URL.includes('local') ? 'http://' : 'https://') +
      appEnvVars.API_BASE_URL
    );
  } else {
    return (
      (shared.API_BASE_URL.includes('local') ? 'http://' : 'https://') +
      shared.API_BASE_URL
    );
  }
};

export const SESSION_KEY = '/api/auth/session/';
export const LOADING_STATUS = 'loading';
export const AUTHENTICATED_STATUS = 'authenticated';
export const UNAUTHENTICATED_STATUS = 'unauthenticated';
