import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { CloseButton } from '@web/atoms';
import { shared } from '../../config';
import { useReporter } from '../../reporter';
import { useSessionCustomer } from '../../customer';
import { ChatIcon } from './ChatIcon';
import {
  ButtonContainer,
  Button,
  UnreadMessageDot,
  ProactiveMessage,
  CloseButtonContainer,
} from './styles';
import { OVERLAPING_PATH } from './constants';
import { userClickGladyChatButton } from './events';

export const ChatButton = ({
  gladlySettings: { displayRules = { rules: [] } } = {},
}) => {
  const reporter = useReporter();
  const [isGladlyInitiated, setIsGladlyInitiated] = useState(false);
  const [showUnreadMessageDot, setShowUnreadMessageDot] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [showProactiveMessage, setShowProactiveMessage] = useState(false);
  const {
    isLoading: isCustomerLoading,
    isAuthenticated,
    customer,
  } = useSessionCustomer();
  const router = useRouter();

  const findOverlapingPath = pathname => {
    if (pathname === undefined) {
      return false;
    }
    const result = OVERLAPING_PATH.find(path => pathname.indexOf(path) !== -1);
    return result !== undefined;
  };

  const isOverlaping = findOverlapingPath(router.asPath);

  //Checks if the current url is in the excluded list of Gladly settings page
  const isExcludedPath = displayRules?.rules.some(item =>
    router.asPath.includes(item.content.value),
  );

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const routeChangeHandler = () => Gladly.navigate();

    // eslint-disable-next-line no-undef
    Gladly.init({
      autoShowButton: false,
      appId: shared.GLADLY_PUBLISHABLE_KEY,
    })
      .then(function () {
        // eslint-disable-next-line no-undef
        Gladly.on('message:received', () => {
          setShowUnreadMessageDot(true);
        });
        // eslint-disable-next-line no-undef
        Gladly.on('campaign:triggered', e => {
          setShowUnreadMessageDot(true);
          setCampaignData(e[0]);
          setShowProactiveMessage(true);
        });
        // eslint-disable-next-line no-undef
        Gladly.on('sidekick:opened', e => {
          setShowUnreadMessageDot(false);
        });

        setIsGladlyInitiated(true);
        router.events.on('routeChangeComplete', routeChangeHandler);
      })
      .catch(() => {});

    return () => router.events.off('routeChangeComplete', routeChangeHandler);
  }, [router.events]);

  useEffect(() => {
    const { Gladly } = window;

    const isGladlyAvailable = Gladly;
    if (
      !isCustomerLoading &&
      customer &&
      isAuthenticated &&
      isGladlyAvailable &&
      isGladlyInitiated
    ) {
      const { first_name, email } = customer;
      if (Gladly.clearUser && Gladly.setUser && first_name && email) {
        Gladly.clearUser();
        Gladly.setUser({
          name: first_name,
          email,
        });
      }
    }
  }, [customer, isAuthenticated, isCustomerLoading, isGladlyInitiated]);

  const onChatButtonHandler = () => {
    reporter.tag(userClickGladyChatButton({ path: router.asPath }));
    const { Gladly } = window;
    if (Gladly.show) {
      if (campaignData) {
        Gladly.applyCampaign(campaignData.id);
        setCampaignData(null);
      } else Gladly.show();
    }
  };

  const onCloseButtonClick = e => {
    e.stopPropagation();
    setShowProactiveMessage(false);
  };

  if (isExcludedPath || !isGladlyInitiated) {
    return <></>;
  }

  return (
    <ButtonContainer shown={true} isOverlaping={isOverlaping}>
      {showProactiveMessage && campaignData && (
        <>
          <ProactiveMessage
            className="campaign-message"
            onClick={onChatButtonHandler}
          >
            {campaignData.actions[0].content.message}
            <CloseButtonContainer onClick={onCloseButtonClick}>
              <CloseButton color="border.dark" />
            </CloseButtonContainer>
          </ProactiveMessage>
        </>
      )}
      <Button
        id={'custom-gladly-chat-button'}
        aria-label={
          'Click here to chat with a customer service representative.'
        }
        showUnreadMessageDot={showUnreadMessageDot}
        onClick={onChatButtonHandler}
      >
        <ChatIcon />
      </Button>

      {showUnreadMessageDot && <UnreadMessageDot />}
    </ButtonContainer>
  );
};

ChatButton.displayName = 'ChatButton';

ChatButton.propTypes = {
  gladlySettings: PropTypes.object,
};
