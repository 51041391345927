import { BaseService } from '../api';
import { PAYMENT_V3_URL, DEFAULT } from './constants';

export class PaymentService extends BaseService {
  get = (customerId, id) => this.client.get(PAYMENT_V3_URL(customerId) + id);

  getDefault = customerId =>
    this.client.get(PAYMENT_V3_URL(customerId) + DEFAULT);

  create = (customerId, paymentMethod) =>
    this.client.post(PAYMENT_V3_URL(customerId), paymentMethod);

  update = (customerId, paymentId, paymentMethod) =>
    this.client.patch(
      `${PAYMENT_V3_URL(customerId)}${paymentId}/`,
      paymentMethod,
    );
}
