import { BaseService } from '../api';
import { PAYMENT_INTENT_V3_URL } from './constants';

export const PAYMENT_INTENT_INITIATE_URL = () => `/api/v3/payments/initiate/`;
export const PAYMENT_INTENT_UPDATE_URL = transactionId =>
  `/api/v3/payments/${transactionId}/update/`;

export class PaymentIntentService extends BaseService {
  create = (cartId, paymentMethodId) =>
    this.client.post(`${PAYMENT_INTENT_V3_URL}initiate/`, {
      cart_id: cartId,
      payment_method_id: paymentMethodId,
    });
  update = (cartId, internalId, paymentMethodId) =>
    this.client.put(`${PAYMENT_INTENT_V3_URL}${internalId}/update/`, {
      cart_id: cartId,
      payment_method_id: paymentMethodId,
    });
  confirm = (id, paymentMethodId) =>
    this.client.post(`${PAYMENT_INTENT_V3_URL}${id}/confirm/`, {
      payment_method_id: paymentMethodId,
    });
}
