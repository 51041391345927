import useSWR, { unstable_serialize, useSWRConfig } from 'swr';

const invalidKeys = [undefined, NaN, null, ''];

// @TODO(James) replace w/ internal implementation
// https://github.com/vercel/swr/pull/2047
export const useSWRList = keys => {
  const { mutate, fetcher: rootFetcher } = useSWRConfig();
  const key = keys
    ?.filter(key => !invalidKeys.includes(key))
    .map(key => unstable_serialize(key))
    .join(',');
  const fetcher = () => {
    return keys.length === 1
      ? // If we only have one item, we can't call `mutate` or it infinite loops.
        // This is because the `useSWRList` key + the individual item key is the same.
        rootFetcher(keys[0])
      : Promise.all(keys.map(key => mutate(key, rootFetcher(key))));
  };

  const { data, error } = useSWR(key, fetcher);

  // We need to normalize to an array outside of the fetcher,
  // This relates to the same issues as above: the key for the list + individual item is the same.
  // The cached value has to be the same; the plain object, rather than a list.
  // So we should expect an object here when `keys.length === 1`, and handle it appropriately.
  return { data: data && !Array.isArray(data) ? [data] : data, error };
};
