import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { LoadingSpinner } from '@ui/loading-spinner';
import { Icon } from '@ui/icons';
import { variants } from './variants';
import {
  StyledButton,
  IconContainer,
  ButtonLoaderContainer,
} from './styles.js';

export const Button = forwardRef(
  (
    {
      'aria-label': ariaLabel,
      children,
      horizontalPadding = 8,
      verticalPadding = 4,
      percentWidthMobile,
      disabled,
      icon,
      onClick,
      variant = 'primary',
      fullWidth,
      id = null,
      isLoading = false,
      className,
    },
    ref,
  ) => {
    return (
      <StyledButton
        id={id}
        aria-label={ariaLabel}
        disabled={disabled}
        onClick={onClick}
        $horizontalPadding={horizontalPadding}
        $verticalPadding={verticalPadding}
        $percentWidthMobile={percentWidthMobile}
        $variant={variant}
        $fullWidth={fullWidth}
        ref={ref}
        className={className}
      >
        {icon && icon.position === 'before' && (
          <IconContainer aria-hidden="true" position={icon.position}>
            <Icon {...icon} id={`button_before_${id}`} />
          </IconContainer>
        )}
        {children}
        {icon && icon.position === 'after' && (
          <IconContainer aria-hidden="true" position={icon.position}>
            <Icon {...icon} id={`button_after_${id}`} />
          </IconContainer>
        )}
        {isLoading && (
          <ButtonLoaderContainer>
            <LoadingSpinner />
          </ButtonLoaderContainer>
        )}
      </StyledButton>
    );
  },
);

Button.displayName = 'Button';

Button.propTypes = {
  'aria-label': PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  fullWidth: PropTypes.bool,
  horizontalPadding: PropTypes.number,
  verticalPadding: PropTypes.number,
  horizontalPaddingMobile: PropTypes.number,
  verticallPaddingMobile: PropTypes.number,
  icon: PropTypes.shape({
    position: PropTypes.oneOf(['before', 'after']),
  }),
  percentWidthMobile: PropTypes.number,
  onClick: PropTypes.func,
  buttonRef: PropTypes.any,
  variant: PropTypes.oneOf(Object.keys(variants)),
  id: PropTypes.string,
  className: PropTypes.string,
};
