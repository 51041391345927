'use client';
import styled from 'styled-components';

export const StyledTextCallout = styled.span`
  background: ${props => props.theme.colors.neutral.bone};
  border-radius: 30px;
  display: inline-block;
  line-height: 0;
  padding: ${props => props.theme.spacing(1)};
  position: relative;
  vertical-align: middle;
`;

export const CalloutText = styled.span`
  ${props => props.theme.typography.subheading2};
  display: inline-block;
  ${props => props.theme.fontFamily.condensed}
  line-height: 1.2;
  padding: ${props => props.theme.spacing(0, 1)};
  text-transform: uppercase;
  vertical-align: top;
`;

export const CalloutParagraph = styled.div`
  ${props => props.theme.typography.label};
  text-align: left;
`;
