import { BaseService } from '../api';
import {
  ADDRESS_ENDPOINT,
  CHECKOUT_URL,
  CREATE_PASSWORD_ENDPOINT,
  CUSTOMER_ENDPOINT,
  UPDATE_PASSWORD_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  VALIDATE_REQUEST_PASSWORD_TOKEN_ENDPOINT,
  RECOVER_FORGOTTEN_PASSWORD_ENDPOINT,
} from './constants';

export class CustomerService extends BaseService {
  updateCustomer = async (id, customer) =>
    await this.client.put(`${CUSTOMER_ENDPOINT}${id}/`, customer);

  getCustomer = async id => await this.client.get(`${CUSTOMER_ENDPOINT}${id}/`);

  updatePassword = async password =>
    await this.client.put(UPDATE_PASSWORD_ENDPOINT, password);

  createPassword = async password =>
    await this.client.put(CREATE_PASSWORD_ENDPOINT, password);

  forgotPassword = async (email, token) => {
    return await this.client.post(FORGOT_PASSWORD_ENDPOINT, {
      email,
      'g-recaptcha-response': token,
    });
  };

  validateNewPasswordRequestToken = async (customer_id, token) => {
    return await this.client.post(
      VALIDATE_REQUEST_PASSWORD_TOKEN_ENDPOINT(customer_id),
      {
        token,
      },
    );
  };

  recoverForgottenPassword = async (customer_id, data) => {
    return await this.client.post(
      RECOVER_FORGOTTEN_PASSWORD_ENDPOINT(customer_id),
      data,
    );
  };

  updateAddress = (customerId, address) =>
    this.client.post(ADDRESS_ENDPOINT(customerId), address);

  performCheckout = (customerId, transactionIds, campaignId) =>
    this.client.post(CHECKOUT_URL(customerId, campaignId), {
      transaction_ids: transactionIds,
    });
}
