'use client';
import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { usePathname, useRouter } from 'next/navigation';
import { LinkButton } from '@ui/button';
import { Icon } from '@ui/icons';
import { ButtonLink } from '@web/atoms';
import { AddPupButton } from '../AddPupButton';
import { SlidingContainer } from '../SlidingContainer';
import { ResponsiveImage } from '../ResponsiveImage';
import { CtaButton } from '../CtaButton';
import { SlideoutMenuLink } from '../SlideoutMenuLink';
import { SlideDownMenuLink } from '../SlideDownMenuLink';
import tagIcon from '../../images/tag.svg';
import { useSessionCustomer } from '../../customer';
import { useSession } from '../../api';
import { useReporter } from '../../reporter';
import { useToast, TOAST_CLIENT_ERROR_MESSAGE } from '../../Toast';
import { getStartedButtonClicked } from './events';
import { ReferAFriendLink, HeaderLink } from './HeaderLink';
import {
  DesktopNavigationMenu,
  MobileNavigationMenu,
  BurgerMenu,
  SlideOutMenu,
  GetStartedButton,
  MobileCtaButtonContainer,
  MobileButtonWrapper,
  NavigationMenuLink,
  SlideOutMenuTopSection,
  SlideOutMenuCloseButton,
  LogoImgContainer,
} from './NavigationBar/styles.js';
import { SmallNavLink } from './styles';
import OllieLogo from './NavigationBar/ollie-logo.svg';

export const LoginMenuLinks = () => {
  const reporter = useReporter();
  const onClickAction = () => {
    reporter.tag(getStartedButtonClicked());
  };
  return (
    <>
      <GetStartedButton>
        <LinkButton
          variant="egg"
          verticalPadding={4}
          fullWidth={true}
          onClick={onClickAction}
          href="/onboarding/"
        >
          Get started
        </LinkButton>
      </GetStartedButton>
      <SmallNavLink showLink={false} href="/auth/login/">
        Login
      </SmallNavLink>
    </>
  );
};

export const LoggedInMenuLink = () => {
  const { customer } = useSessionCustomer();

  return (
    <>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img src={tagIcon.src} alt="dog tag" />
      <SmallNavLink showLink={true} href="/account/">
        Hi, {customer?.first_name ?? 'friend'}
      </SmallNavLink>
    </>
  );
};

export const UserMenuLinks = () => {
  const { isActive } = useSessionCustomer();
  return isActive ? <LoggedInMenuLink /> : <LoginMenuLinks />;
};

export const LeftMenuLinks = ({ links }) => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [slidingContainerOffset, setSlidingContainerOffset] = useState(0);
  const { isActive } = useSessionCustomer();

  const toggleSideMenu = () => {
    setShowSideMenu(!showSideMenu);
  };

  useLayoutEffect(() => {
    const nav = document.querySelectorAll('[role="navigation"]')[0];
    // In ABTasty, sometimes the nav isn't there as the editor is loading
    if (nav) {
      const { top, height } = nav.getBoundingClientRect();
      setSlidingContainerOffset(top + height);
    }
  }, []);

  const { logout } = useSession();
  const pathname = usePathname();
  const router = useRouter();
  const { showToast } = useToast();

  const onLogoutClick = async () => {
    const { Gladly } = window;
    if (Gladly && Gladly.clearUser) Gladly.clearUser();
    try {
      toggleSideMenu();
      await logout();
      if (pathname.includes('/account')) {
        router.push('/auth/login/');
      }
    } catch {
      showToast({
        status: 'error',
        headline: 'Error',
        message: TOAST_CLIENT_ERROR_MESSAGE,
      });
    }
  };

  return (
    <>
      <MobileNavigationMenu>
        <BurgerMenu onClick={toggleSideMenu}>
          {showSideMenu ? (
            <Icon iconName="close" alt="burger menu" size={1} id="close_menu" />
          ) : (
            <Icon iconName="menu" alt="burger menu" size={1} id="show_menu" />
          )}
        </BurgerMenu>
        <SlidingContainer
          active={showSideMenu}
          onExit={toggleSideMenu}
          $bgColor="background.base"
          alignment="left"
          offsetTop={slidingContainerOffset}
          maxWidth={765}
        >
          <>
            <SlideOutMenu>
              <SlideOutMenuTopSection>
                <SlideOutMenuCloseButton onClick={toggleSideMenu}>
                  <Icon
                    iconName="close"
                    alt="burger menu"
                    size={1}
                    id="close_menu_slide"
                  />
                </SlideOutMenuCloseButton>

                <LogoImgContainer>
                  <ResponsiveImage
                    src={OllieLogo.src}
                    alt="Ollie logo"
                    width={OllieLogo.width}
                    height={OllieLogo.height}
                  />
                </LogoImgContainer>

                {!isActive && (
                  <div>
                    <SmallNavLink showLink={true} href="/auth/login/">
                      Login
                    </SmallNavLink>
                  </div>
                )}
              </SlideOutMenuTopSection>
              {links.map((link, index) => {
                const { component } = link;
                if (component === 'slideout_menu_link')
                  return (
                    <NavigationMenuLink key={link.component + index}>
                      <SlideoutMenuLink
                        {...link}
                        key={index}
                        onCloseMenu={toggleSideMenu}
                      />
                    </NavigationMenuLink>
                  );
                if (component === 'slide_down_menu_link')
                  return (
                    <NavigationMenuLink key={link.component + index}>
                      <SlideDownMenuLink
                        {...link}
                        key={index}
                        onCloseMenu={toggleSideMenu}
                      />
                    </NavigationMenuLink>
                  );
                if (component === 'refer_a_friend_button')
                  return (
                    <NavigationMenuLink key={link.component + index}>
                      <ReferAFriendLink
                        key={index}
                        position={index}
                        {...link}
                        type={'mobile'}
                      />
                    </NavigationMenuLink>
                  );
                return (
                  <NavigationMenuLink key={link.component + index}>
                    <HeaderLink
                      target={link.target}
                      position={index}
                      text={link.text}
                      type="desktop"
                      key={index}
                      onClick={toggleSideMenu}
                    />
                  </NavigationMenuLink>
                );
              })}
              {isActive && (
                <>
                  <MobileButtonWrapper verticalMargin={7}>
                    <AddPupButton
                      href="/account/add-pup/"
                      fullWidth={true}
                      variant="blueberry"
                      verticalPadding={3}
                      horizontalPadding={3}
                      text="Add Pup"
                    />
                  </MobileButtonWrapper>
                  <MobileButtonWrapper verticalMargin={2}>
                    <ButtonLink
                      onClick={onLogoutClick}
                      variant="hero.cranberry"
                    >
                      Logout
                    </ButtonLink>
                  </MobileButtonWrapper>
                </>
              )}
            </SlideOutMenu>
            {!isActive && (
              <MobileCtaButtonContainer>
                <CtaButton
                  href="/onboarding/"
                  variant="egg"
                  fullWidth={true}
                  onClick={toggleSideMenu}
                >
                  Get started
                </CtaButton>
              </MobileCtaButtonContainer>
            )}
          </>
        </SlidingContainer>
      </MobileNavigationMenu>
    </>
  );
};

LeftMenuLinks.displayName = 'MarketingMenuLinks';

LeftMenuLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string,
      text: PropTypes.string,
      component: PropTypes.string,
    }),
  ),
};

export const CenterMenuLinks = ({ links }) => {
  return (
    <>
      <DesktopNavigationMenu>
        {links.map((link, index) => {
          const { component } = link;
          if (component === 'slideout_menu_link')
            return (
              <NavigationMenuLink key={index}>
                <SlideoutMenuLink {...link} />
              </NavigationMenuLink>
            );
          if (component === 'slide_down_menu_link')
            return (
              <NavigationMenuLink key={index}>
                <SlideDownMenuLink {...link} />
              </NavigationMenuLink>
            );
          if (component === 'refer_a_friend_button')
            return (
              <NavigationMenuLink key={index}>
                <ReferAFriendLink position={index} {...link} type={'desktop'} />
              </NavigationMenuLink>
            );
          return (
            <NavigationMenuLink key={index}>
              <HeaderLink {...link} position={index} type="desktop" />
            </NavigationMenuLink>
          );
        })}
      </DesktopNavigationMenu>
    </>
  );
};

CenterMenuLinks.displayName = 'CenterMenuLinks';

CenterMenuLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      target: PropTypes.string,
      text: PropTypes.string,
      component: PropTypes.string,
    }),
  ),
};
