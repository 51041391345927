import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { variants } from './variants';
import { StyledButton } from './styles.js';

export const IconButton = forwardRef(
  (
    {
      horizontalPadding = 4,
      verticalPadding = 4,
      disabled,
      icon,
      onClick,
      type,
      variant = 'primary',
      id = null,
      ...props
    },
    ref,
  ) => {
    return (
      <StyledButton
        aria-label={props['aria-label']}
        aria-pressed={props['aria-pressed']}
        disabled={disabled}
        onClick={onClick}
        type={type}
        ref={ref}
        id={id}
        $horizontalPadding={horizontalPadding}
        $verticalPadding={verticalPadding}
        $variant={variant}
      >
        <Icon aria-hidden="true" {...icon} id={`icon_button_${id}`} />
      </StyledButton>
    );
  },
);

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  'aria-pressed': PropTypes.bool,
  buttonRef: PropTypes.any,
  disabled: PropTypes.bool,
  horizontalPadding: PropTypes.number,
  icon: PropTypes.shape(Icon.propTypes).isRequired,
  onClick: PropTypes.func,
  type: PropTypes.string,
  variant: PropTypes.oneOf(Object.keys(variants)),
  verticalPadding: PropTypes.number,
  id: PropTypes.string,
};
