'use client';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import useSWR, { useSWRConfig } from 'swr';
import { useCart, useSessionCustomer } from '../customer';
import { shared } from '../config';
import { ApiResult, useClient } from '../api';
import { OFFER_URL, MARKETING_CODE_TYPE } from './constants';
import { getFormattedOfferAmountByType } from './helpers';

export const useQueryOfferDetails = () => {
  const router = useRouter();
  const client = useClient();
  const [queryOfferCode, setQueryOfferCode] = useState(null);
  const { mutate: globalMutate } = useSWRConfig();

  useEffect(() => {
    if (router.isReady && router.query.offer_code) {
      setQueryOfferCode(router.query.offer_code);
    }
  }, [router.isReady, router.query.offer_code]);

  const { data, error } = useSWR(
    queryOfferCode ? `${OFFER_URL}${queryOfferCode}/` : null,
  );

  const [invalidQueryCodes, setInvalidQueryCodes] = useState([]);

  useEffect(() => {
    if (error) {
      setInvalidQueryCodes([...invalidQueryCodes, queryOfferCode]);
      setQueryOfferCode(null);
    }
  }, [error, invalidQueryCodes, queryOfferCode]);

  const validateOfferCode = useCallback(
    offerCode =>
      ApiResult.callAsync(async () => {
        const response = await client.get(`${OFFER_URL}${offerCode}/`);
        await globalMutate(`${OFFER_URL}${offerCode}/`, response.data, false);
        return response;
      }),
    [client, globalMutate],
  );

  return {
    offerDetails: data,
    error,
    invalidQueryCodes,
    isLoading: queryOfferCode ? !error && !data : false,
    validateOfferCode,
    setQueryOfferCode,
  };
};

const useCartOfferCode = () => {
  const { cart, updateOfferCode, isLoading } = useCart();
  const cartOfferCode = cart?.coupon_code;

  return {
    cartOfferCode,
    updateOfferCode,
    isLoading,
  };
};

export const OfferContext = createContext(null);

export const useOffer = () => useContext(OfferContext);

export const OfferProvider = ({ children }) => {
  const { isAuthenticated, isActive } = useSessionCustomer();
  const {
    offerDetails: queryOfferDetails,
    isLoading: isQueryLoading,
    validateOfferCode,
    setQueryOfferCode,
  } = useQueryOfferDetails();
  const {
    cartOfferCode,
    updateOfferCode,
    isLoading: isCartLoading,
  } = useCartOfferCode();
  const [invalidCodes, setInvalidCodes] = useState([]);

  const offerCode = useMemo(() => {
    // If we're still loading, we don't have a coupon yet.
    if (isQueryLoading || isCartLoading) {
      return null;
    }

    if (queryOfferDetails && !invalidCodes.includes(queryOfferDetails.code)) {
      return queryOfferDetails.code;
    }

    if (cartOfferCode && !invalidCodes.includes(cartOfferCode)) {
      return cartOfferCode;
    }

    if (!invalidCodes.includes(shared.DEFAULT_OFFER_CODE)) {
      return shared.DEFAULT_OFFER_CODE;
    }
    return null;
  }, [
    cartOfferCode,
    invalidCodes,
    isCartLoading,
    isQueryLoading,
    queryOfferDetails,
  ]);

  const { data: offerDetails } = useSWR(
    offerCode ? `${OFFER_URL}${offerCode}/` : null,
    {
      revalidateIfStale: false,
    },
  );

  const isMarketingCode = offerDetails?.category === MARKETING_CODE_TYPE;

  useEffect(() => {
    if (
      isAuthenticated &&
      offerCode &&
      offerCode !== cartOfferCode &&
      isMarketingCode &&
      !isActive &&
      !invalidCodes.includes(offerCode)
    ) {
      updateOfferCode(offerCode).then(result => {
        setQueryOfferCode(null);
        ApiResult.ifError(result, () => {
          setInvalidCodes(codes => [...codes, offerCode]);
        });
      });
    }
  }, [
    cartOfferCode,
    invalidCodes,
    isActive,
    isAuthenticated,
    isMarketingCode,
    offerCode,
    updateOfferCode,
    setQueryOfferCode,
  ]);

  let offer = {
    updateOfferCode,
    validateOfferCode,
  };

  if (offerDetails) {
    const formattedAmount = getFormattedOfferAmountByType(offerDetails);
    offer = {
      ...offer,
      formattedAmount,
      ...offerDetails,
      defaultOfferCode: shared.DEFAULT_OFFER_CODE,
    };
  }

  return (
    <OfferContext.Provider value={offer}>{children}</OfferContext.Provider>
  );
};

OfferProvider.displayName = 'OfferProvider';

OfferProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
