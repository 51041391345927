import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '@ui/theme';

export const CheckmarkCircle = ({
  color = 'background.lightest',
  size = '24',
  alt = '',
}) => {
  return (
    <svg
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      viewBox="0 0 24 24"
    >
      {alt && <title>{alt}</title>}
      <circle cx="12" cy="12" r="12" fill="#86A386" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.7406 9.7025L11.5717 16.7075C11.2282 17.0975 10.6787 17.0975 10.3352 16.7075L7.25761 13.1825C6.91413 12.7925 6.91413 12.1625 7.25761 11.7725C7.60109 11.3825 8.15066 11.3825 8.49414 11.7725L10.9672 14.5925L16.5179 8.2925C16.8613 7.9025 17.4109 7.9025 17.7544 8.2925C18.0841 8.6825 18.0841 9.3125 17.7406 9.7025Z"
        fill={theme.colors.get(color)}
      />
    </svg>
  );
};
CheckmarkCircle.displayName = 'CheckmarkCircle';
CheckmarkCircle.propTypes = {
  alt: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
