'use client';
import { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@ui/icons';
import { VisuallyHidden } from '@web/atoms';
import {
  CloseButton,
  StyledToast,
  StatusMessage,
  Content,
  TextWrapper,
  IconWrapper,
  Headline,
} from './styles.js';

const statusInformation = {
  success: {
    icon: 'check-circle',
    bgColor: 'status.successLight',
    borderColor: 'status.successPrimary',
  },
  error: {
    icon: 'close-circle',
    bgColor: 'status.errorLight',
    borderColor: 'status.errorPrimary',
  },
  warning: {
    icon: 'alert-circle',
    bgColor: 'status.warningLight',
    borderColor: 'status.warningPrimary',
  },
  info: {
    icon: 'information',
    bgColor: 'background.lightest',
    borderColor: 'hero.blueberry',
  },
};

export const Toast = ({
  shown = false,
  status,
  statusHeadline,
  statusMessage,
  removeToast,
}) => {
  const toastRef = useRef(null);
  const timerRef = useRef();
  const [showToast, setShowToast] = useState(shown);

  const hideToast = useCallback(() => {
    setShowToast(false);
    const $toast = toastRef.current;
    $toast?.addEventListener('animationend', removeToast);
  }, [removeToast]);

  const onCloseToast = () => {
    hideToast();
    clearTimeout(timerRef.current);
  };

  useEffect(() => {
    if (shown) {
      setShowToast(true);
      const timerId = setTimeout(() => hideToast(), 8000);
      timerRef.current = timerId;
    }
  }, [hideToast, removeToast, shown]);

  return (
    <div ref={toastRef}>
      {shown && (
        <StyledToast
          showToast={showToast}
          shown={shown}
          status={status}
          backgroundColor={statusInformation[status].bgColor}
          borderColor={statusInformation[status].borderColor}
        >
          <Content>
            <Content>
              <IconWrapper>
                <Icon
                  iconName={statusInformation[status].icon}
                  alt=""
                  color={statusInformation[status].borderColor}
                  id={status}
                />
              </IconWrapper>
              <TextWrapper>
                {statusHeadline != null && (
                  <Headline $textColor={statusInformation[status].borderColor}>
                    {statusHeadline}
                  </Headline>
                )}
                <StatusMessage role="alert">{statusMessage}</StatusMessage>
              </TextWrapper>
            </Content>
            <CloseButton onClick={onCloseToast}>
              <VisuallyHidden>Close Toast</VisuallyHidden>
            </CloseButton>
          </Content>
        </StyledToast>
      )}
    </div>
  );
};

Toast.displayName = 'Toast';

Toast.propTypes = {
  status: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
  shown: PropTypes.bool.isRequired,
  statusHeadline: PropTypes.string,
  statusMessage: PropTypes.string.isRequired,
  removeToast: PropTypes.func.isRequired,
};

export { useToast } from './ToastContext';
export {
  TOAST_CLIENT_ERROR_MESSAGE,
  TOAST_INCOMPLETE_INFO_ERROR_MESSAGE,
  TOAST_CLIENT_CHANGES_SAVED_SUCCESS,
  TOAST_FORGOT_PASSWORD_TITLE,
  TOAST_FORGOT_PASSWORD_MESSAGE,
  TOAST_SERVER_ERROR_MESSAGE,
  TOAST_WEIGHT_SCREENING_SUBMITTED_MESSAGE,
} from './constants';
