'use client';
import styled from 'styled-components';

export const XButton = styled.button`
  background: none;
  border: 0;
  padding: 2px 2px 0 0;
  &:hover,
  &:focus {
    padding: 0;
    display: flex;
    justify-content: space-around;
    border: 2px solid ${props => props.theme.colors.background.dark};
  }
`;
