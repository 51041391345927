import { useEffect, useState } from 'react';
import differenceInDays from 'date-fns/differenceInDays';
import intervalToDuration from 'date-fns/intervalToDuration';
import isBefore from 'date-fns/isBefore';

const getDateTimeDifference = targetDate => {
  const now = new Date();
  return Math.max(differenceInDays(targetDate, now));
};

export const useCountdown = targetDate => {
  const [countdown, setCountdown] = useState(null);
  let days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0;

  useEffect(() => {
    if (!isBefore(new Date(), targetDate)) {
      return setCountdown({ days, hours, minutes, seconds });
    }
    const interval = setInterval(() => {
      const now = new Date();
      let { hours, minutes, seconds } = intervalToDuration({
        start: now,
        end: targetDate,
      });
      if (isBefore(now, targetDate)) {
        let days = getDateTimeDifference(targetDate);
        setCountdown({ days, hours, minutes, seconds });
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate, days, hours, minutes, seconds]);

  return countdown;
};
