'use client';
import useSWR from 'swr';
import { useSession } from './session';

export const CUSTOMER_EXISTS = 'CustomerExists';
export const INVALID_EMAIL = 'InvalidEmail';
export const TOO_MANY_FAILED_LOGIN_ATTEMPTS = 'TooManyFailedLoginAttempts';

export const useAuthenticatedSWR = (key, fetcher, options) => {
  const session = useSession();

  return useSWR(session?.user?.id ? key : null, fetcher, options);
};
