'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const IconImgContainer = styled.div`
  width: ${props => props.theme.spacing(4)};
  cursor: pointer;
  vertical-align: middle;
`;

export const StyledLink = styled(Link)`
  background: none;
  border: none;
  padding: 0;
  margin-left: ${props => props.theme.spacing(4)};
  width: ${props => props.theme.spacing(4)};
  @media ${props => props.theme.laptop} {
    margin-left: ${props => props.theme.spacing(9)};
  }
`;
