'use client';
import Link from 'next/link';
import styled from 'styled-components';

export const StyledFooter = styled.footer`
  background-color: ${props => props.theme.colors.background.dark};
  width: 100%;
`;

export const ConstrainedContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: ${props => props.theme.spacing(5, 5, 4)};
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(5, 10, 4)};
  }
  @media ${props => props.theme.laptopL} {
    padding: ${props => props.theme.spacing(5, 20, 4)};
  }
`;

export const Paragraph = styled.p`
  color: ${props => props.theme.colors.text.lightest};
  font-size: 0.7rem;
  line-height: 2.18;
`;

export const Content = styled.li`
  list-style: none;
  color: ${props => props.theme.colors.text.lightest};
  font-size: 0.7rem;
  line-height: 2.18;
  margin-bottom: 16px;

  &:empty {
    margin: 0;
  }
`;

export const StyledLink = styled(Link)`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.text.lightest};
  &:focus {
    text-decoration: none;
    border: 1px solid ${props => props.theme.colors.background.base};
    border-radius: 2px;
    margin: -1px;
  }
`;

export const BottomLinks = styled.div`
  padding: ${props => props.theme.spacing(2, 5)};
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  background-color: ${props => props.theme.colors.text.primary};
  ${StyledLink} {
    ${props => props.theme.typography.label}
  }
  @media ${props => props.theme.tablet} {
    padding: ${props => props.theme.spacing(2, 10)};
  }
  @media ${props => props.theme.laptopL} {
    padding: ${props => props.theme.spacing(2, 20)};
  }
`;

export const MobileIllustration = styled.div`
  margin: auto;
  @media ${props => props.theme.tablet} {
    display: none;
  }
`;

export const LeftAlignedLinks = styled.div`
  display: flex;
  grid-column-gap: ${props => props.theme.spacing(6)};
`;

export const FooterColumns = styled.div`
  display: flex;
  flex-flow: column;
  margin-bottom: ${props => props.theme.spacing(8)};
  @media ${props => props.theme.tablet} {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
`;

export const BlurbSupportContainer = styled.div`
  @media ${props => props.theme.tablet} {
    width: 50%;
  }
`;

export const BlurbSupportGrid = styled.div`
  display: grid;
`;

export const InnerBlurb = styled.div`
  align-items: baseline;
  margin-top: ${props => props.theme.spacing(6)};
  @media ${props => props.theme.mobileS} {
    grid-column: 1;
    grid-row: 2;
    margin-bottom: ${props => props.theme.spacing(12)};
  }
  @media ${props => props.theme.tablet} {
    grid-column: 1 / span 2;
    margin-bottom: ${props => props.theme.spacing(0)};
  }
`;

export const ColumnTitle = styled.h5`
  ${props => props.theme.typography.subheading1}
  color: ${props => props.theme.colors.text.lightest};
  margin-bottom: 16px;
  text-transform: uppercase;
  @media ${props => props.theme.tablet} {
    text-align: left;
  }
`;

export const StyledFooterColumn = styled.ul`
  margin-bottom: 32px;
  padding-left: 0;
  text-align: left;
  align-self: flex-start;

  ${ColumnTitle} {
    &:nth-of-type(2) {
      margin-top: 2rem;
    }
  }

  @media ${props => props.theme.tablet} {
    margin-bottom: 0;
    text-align: left;
  }
`;

export const FooterColumnLink = styled.li`
  display: ${props => (props.icon ? 'grid' : 'flex')};
  grid-template-columns: ${props => (props.icon ? '25px 1fr' : null)};
  grid-column-gap: ${props => (props.icon ? '4px' : null)};
  align-items: end;
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

export const BusinessHours = styled.li`
  display: inline-block;
  list-style: none;
  font-size: 1rem;
  color: ${props => props.theme.colors.text.lightest};
  margin-bottom: ${props => props.theme.spacing(4)};
  p {
    margin-bottom: ${props => props.theme.spacing(2)};
  }
  &:empty {
    display: none;
  }
`;

export const LegalBlurb = styled.span`
  ${props => props.theme.typography.label};
  display: inline-block;
  list-style: none;
  color: ${props => props.theme.colors.text.lighter};
  &:empty {
    display: none;
  }
  text-align: left;
`;

export const LinkIconContainer = styled.div`
  margin-right: ${props => props.theme.spacing(1)};
`;

export const SocialIcon = styled.a`
  display: inline-block;
  margin-top: ${props => props.theme.spacing(2)};
  width: 24px;
  &:not(:last-child) {
    margin-right: ${props => props.theme.spacing(5)};
  }
  @media ${props => props.theme.laptop} {
    width: 20px;
  }
  &:focus {
    outline: 1px solid ${props => props.theme.colors.background.base};
  }
`;

export const OllieAppButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  a {
    &:first-of-type {
      margin-bottom: 1rem;
    }
  }
`;

export const StyledBlurb = styled.p`
  ${props => props.theme.typography.body}
  color: ${props => props.theme.colors.text.lightest};
  text-align: left;
  white-space: nowrap;
  padding-left: ${props => props.theme.spacing(7.5)};
`;
