'use client';
import styled from 'styled-components';

export const StyledErrorPage = styled.main`
  background: ${props => props.theme.colors.hero.blueberry};
  color: ${props => props.theme.colors.text.lightest};
`;

export const ErrorWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column-reverse;
  margin: 0 auto;
  height: max-content;
  @media ${props => props.theme.laptop} {
    flex-direction: row;
  }
`;
export const ErrorMessage = styled.div`
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spacing(7, 6)};
  width: 100%;
  @media ${props => props.theme.laptopL} {
    padding: ${props => props.theme.spacing(10, 20)};
    width: 50%;
  }
`;
export const ErrorCode = styled.p`
  ${props => props.theme.typography.heading3}
`;

export const Paragraph = styled.p`
  ${props => props.theme.typography.button}
  max-width: 336px;
  margin: ${props => props.theme.spacing(3, 0, 6)};
  @media ${props => props.theme.laptop} {
    margin: ${props => props.theme.spacing(5, 0, 8)};
    max-width: 512px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  @media ${props => props.theme.laptop} {
    max-width: 50%;
  }
`;
