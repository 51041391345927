import styled from 'styled-components';

export const StyledCard = styled.div`
  text-align: left;
  display: flex;
  justify-content: start;
  flex-direction: column;
  max-width: 350px;
  flex: 100%;

  @media ${({ theme }) => theme.tablet} {
    max-width: 220px;
  }

  @media ${({ theme }) => theme.laptop} {
    max-width: 280px;
  }
`;

export const CardImageContainer = styled.div`
  width: 100%;

  img {
    border-radius: ${props => props.theme.spacing(8)};
    object-fit: cover;
    width: 100%;
  }
`;

export const CardTitle = styled.h4`
  ${({ theme }) => theme.typography.heading4}
  ${({ theme }) => theme.colors.text.primary}
`;

export const CardContent = styled.div`
  width: 100%;

  ${CardTitle} {
    padding-bottom: ${props => props.theme.spacing(2)};
  }

  @media ${({ theme }) => theme.mobileS} {
    padding: ${props => props.theme.spacing(4, 0)};
  }
`;

export const CardBody = styled.div`
  ${({ theme }) => theme.typography.body}
  ${({ theme }) => theme.colors.text.secondary}
`;

export const CardButtonContainer = styled.div`
  margin-top: auto;
`;
