'use client';
import React, { createContext, useContext } from 'react';

const initialContext = null;

const EnvContext = createContext(initialContext);

export const useEnvVars = () => useContext(EnvContext);

export const EnvProvider = ({ envVars = {}, children }) => {
  return <EnvContext.Provider value={envVars}>{children}</EnvContext.Provider>;
};
