export const TOAST_CLIENT_ERROR_MESSAGE =
  'Hmm. Something went wrong. Please refresh the page and try again.';
export const TOAST_INCOMPLETE_INFO_ERROR_MESSAGE =
  'Some of your information is incomplete. Please review all fields before trying again.';
export const TOAST_CLIENT_CHANGES_SAVED_SUCCESS = 'Changes saved';
export const TOAST_FORGOT_PASSWORD_MESSAGE =
  'Instructions will be delivered if the email address is tied to an Ollie account.';
export const TOAST_FORGOT_PASSWORD_TITLE = 'Password reset email sent';
export const TOAST_SERVER_ERROR_MESSAGE =
  'Hmm. Something went wrong. Please try submitting again.';
export const TOAST_WEIGHT_SCREENING_SUBMITTED_MESSAGE =
  'Weight Screening successfully submitted!';
