// TODO(James) these should go in `testing/constants` and only be used in testing environments
// TODO(James) we should enforce w/ eslint (import plugin might help here) that only tests/stories import from `testing/constants`
import freshBeefBowlImage from '../../images/product-photos/bowls/Fresh-Beef-bowl-rounded.png';
import freshChickenBowlImage from '../../images/product-photos/bowls/Fresh-Chicken-bowl-rounded.png';
import freshTurkeyBowlImage from '../../images/product-photos/bowls/Fresh-Turkey-bowl-rounded.png';
import freshLambBowlImage from '../../images/product-photos/bowls/Fresh-Lamb-bowl-rounded.png';
import bakedBeefBowlImage from '../../images/product-photos/bowls/Baked-Beef-bowl-rounded.png';
import bakedChickenBowlImage from '../../images/product-photos/bowls/Baked-Chicken-bowl-rounded.png';
import treatsProductImage from '../../images/addons/treats-mobile-nav-transparent.png';
import supplementsProductImage from '../../images/addons/supplements-mobile-nav-transparent.png';

const freshRecipeLinks = [
  {
    href: '/fresh-dog-food/beef/',
    img: {
      alt: 'Fresh beef bowl',
      height: 170,
      src: freshBeefBowlImage,
      width: 170,
    },
    text: 'Beef',
  },
  {
    href: '/fresh-dog-food/chicken/',
    img: {
      alt: 'Fresh chicken bowl',
      height: 170,
      src: freshChickenBowlImage,
      width: 170,
    },
    text: 'Chicken',
  },
  {
    href: '/fresh-dog-food/turkey/',
    img: {
      alt: 'Fresh turkey bowl',
      height: 170,
      src: freshTurkeyBowlImage,
      width: 170,
    },
    text: 'Turkey',
  },
  {
    href: '/fresh-dog-food/lamb/',
    img: {
      alt: 'Fresh lamb bowl',
      height: 170,
      src: freshLambBowlImage,
      width: 170,
    },
    text: 'Lamb',
  },
];

const bakedRecipeLinks = [
  {
    href: '/baked-dog-food/beef/',
    img: {
      alt: 'Baked beef bowl',
      height: 255,
      src: bakedBeefBowlImage,
      width: 255,
    },
    text: 'Beef',
  },
  {
    href: '/baked-dog-food/chicken/',
    img: {
      alt: 'Baked chicken bowl',
      height: 255,
      src: bakedChickenBowlImage,
      width: 255,
    },
    text: 'Chicken',
  },
];

const addonCards = [
  {
    ctaButtonText: 'View',
    ctaButtonVariant: 'egg',
    ctaHref: '/dog-treats/',
    description:
      'Reward your dog while keeping them healthy with our clean and simple treats.',
    image: {
      alt: 'Ollie now offers dog treats as a membership extra',
      height: 282,
      src: treatsProductImage,
      width: 405,
    },
    title: 'Treats',
  },
  {
    ctaButtonText: 'View',
    ctaButtonVariant: 'egg',
    ctaHref: '/dog-supplements/',
    description:
      'Tackle common pup problems and provide added support where your dog needs it most.',
    image: {
      alt: 'Ollie now offers dog supplements as membership extras',
      height: 248,
      src: supplementsProductImage,
      width: 400,
    },
    title: 'Supplements',
  },
];

const slideOutMenu = {
  component: 'slideout_menu_link',
  linkText: 'Our Products',
  menuHeader: 'Our Products',
  sections: [
    {
      component: 'products_summary_card',
      ctaButtonText: 'See all',
      ctaButtonVariant: 'egg',
      ctaHref: '/fresh-dog-food/',
      description:
        'Whole food ingredients, slow-cooked for nutrition and flavor and frozen for freshness.',
      recipeLinks: freshRecipeLinks,
      sectionHeader: 'Fresh Recipes',
      sectionHeaderIcon: null,
    },
    {
      component: 'products_summary_card',
      ctaButtonText: 'See all',
      ctaButtonVariant: 'egg',
      ctaHref: '/baked-dog-food/',
      description:
        'Real meat and veggies, gently baked in small batches for crunch and convenience.',
      recipeLinks: bakedRecipeLinks,
      sectionHeader: 'Baked Recipes',
      sectionHeaderIcon: null,
    },
    {
      component: 'add_on_summary_cards',
      title: 'Membership Extras',
      cards: addonCards,
    },
  ],
};

export const LOGGEDIN_LINKS = [
  { target: '/account', text: 'My plan' },
  { target: '/account/my-info/', text: 'My info' },
  { component: 'refer_a_friend_button', text: 'Refer a friend' },
  { target: '/faqs/', text: 'Support & FAQ' },
];

export const LOGGEDOUT_LINKS = [
  { target: '/how-it-works/', text: 'How It Works' },
  { target: '/meal-plans/', text: 'Meal Plans' },
  slideOutMenu,
  { target: '/faqs/', text: 'FAQ' },
  { target: '/reviews/', text: 'Reviews' },
  { target: '/about/', text: 'About' },
  { target: 'https://blog.myollie.com/', text: 'Blog' },
];

export const LOGO_URL = '/';

export const LOGO_SR_TEXT = 'Ollie logo';
